import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { useKeycloak } from "@react-keycloak/web";
import { useAxios } from '../../utils/hooks.ts';
import { Image } from 'primereact/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons'
import { AutoComplete } from 'primereact/autocomplete';
import { Toast } from 'primereact/toast';

const TitleBar = ({ setMenuVisible }) => {
    const { keycloak } = useKeycloak();
    const [ suggestions, setSuggestions ] = React.useState([]);
    const [ selectedTicket, setSelectedTicket ] = React.useState();
    const axiosInstance = useAxios();
    const toast = React.useRef(null);
    const navigate = useNavigate();

    const selectTicket = (event) => {
        if (event.ticketId) {
            navigate("/tickets/" + event.ticketId);
            setSelectedTicket(null);
        }
    }

    const search = (event) => {
        axiosInstance.current.get("/tickets/search/" + event.query.trim()).then((response) => {
            if (response.data) {
                setSuggestions(response.data);
            }
        })
        .catch((err) => {
            toast.current.show({severity: 'error', summary: 'Not Found', detail: 'No matching ticket found'});
        })
    }

    const itemTemplate = (item) => {
        return (
            <div className="flex-col">
                <div className="secondary-title">{item.name}</div>
                <div className="tertiary-title">{item.description}</div>
                <div className="audit-details">{item.ticketId}</div>
            </div>
        )
    }

    return (
        <div className="flex-row between align-center p10" style={{ backgroundColor: '#ecf0f1' }}>
            <Toast ref={toast} />
            <div className="flex-row center align-center">
                <Image src="logo.png" alt='Apogee' width='75' />
                <FontAwesomeIcon icon={faBars} style={{ fontSize: 42, margin: 10, cursor: 'pointer' }} onClick={() => setMenuVisible(true)}/>
                <div className="hide-on-mobile">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <AutoComplete placeholder="Search by ticket" value={selectedTicket} suggestions={suggestions} completeMethod={search} field="ticketId" onChange={(e) => {setSelectedTicket(e.value); selectTicket(e.value);}} itemTemplate={itemTemplate} />
                </span >
                </div>
            </div>
            <div>
                <span className="secondary-title hide-on-mobile">{keycloak.tokenParsed?.email}</span>
                <Button style={{ marginLeft: 10 }} className="p-button-warning" label="Logout" icon='pi pi-sign-out' onClick={() => keycloak.logout()} />
            </div>
        </div>
    )
}

export default TitleBar;