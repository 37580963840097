import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useAxios } from '../../utils/hooks.ts';

function Invite({ visible, hide, success }) {
    // eslint-disable-next-line
	const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
	const axiosInstance = useAxios();

	const formik = useFormik({
        initialValues: {
            email: '',
        },
        validate: (data) => {
            let errors = {};

            if (!data.email) {
                errors.email= 'Email is required.';
            }

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            axiosInstance.current.post("/companies/invite", data).then(() => {
                formik.resetForm();
            })
            .catch((err) => {
                console.error(err);
                success(false);
            })
            .finally(() => {
                setSaving(false);
                success(true);
				hide();
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

	return (
		<Dialog header="Invite Engineer" visible={visible} onHide={hide}>
			<div className="flex justify-content-center">
                <div className="card">
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} autoComplete="off" autoFocus className={classNames({ 'p-invalid': isFormFieldValid('email') })} />
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>Email *</label>
                            </span>
                            {getFormErrorMessage('email')}
                        </div>

                        <div className="flex-row justify-content-center mt20">
                            <Button style={{ margin: 10 }} type="submit" label="Send Invite" icon="pi pi-send" loading={saving} />
                        </div>
                    </form>
                </div>
            </div>
		</Dialog>
	)
}

export default Invite;