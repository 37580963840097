import * as React from 'react';
import { useAxios } from '../../utils/hooks.ts';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dropdown } from 'primereact/dropdown';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { Column } from 'primereact/column';
import { parseISO, format } from 'date-fns';
import ProgressWrapper from '../../components/ProgressWrapper';
import DeviceCreateEdit from '../../components/devices/DeviceCreateEdit';
import CustomersCreate from '../../components/customers/CustomerCreateEdit';
import EditSetting from '../../components/settings/EditSetting';
import { SettingsContext } from '../../../settings-context';
import './customers.css';
import { Editor } from 'primereact/editor';
import { Toast } from 'primereact/toast';

const classificationList = ["None", "VIP", "Blocked"];

function CustomerDetails() {
    const [ customer, setCustomer ] = React.useState();
    const [ activeIndex, setActiveIndex ] = React.useState();
    const [ editVisible, setEditVisible ] = React.useState(false);
    const [ tickets, setTickets ] = React.useState([]);
    const [ selectedTicket, setSelectedTicket ] = React.useState();
    const [ loadingTickets, setLoadingTickets ] = React.useState(true);
    const [ devices, setDevices ] = React.useState([]);
    const [ selectedDevice, setSelectedDevice ] = React.useState();
    const [ deviceCreateVisible, setDeviceCreateVisible ] = React.useState(false);
    const [ loadingDevices, setLoadingDevices ] = React.useState(true);
    const [ invoices, setInvoices ] = React.useState([]);
    // eslint-disable-next-line
    const [ selectedInvoice, setSelectedInvoice ] = React.useState();
    const [ loadingInvoices, setLoadingInvoices ] = React.useState(true);
    const [ loading, setLoading ] = React.useState(true);
    const [ fetchFlag, setFetchFlag ] = React.useState(false);
    const [ selectedSetting, setSelectedSetting ] = React.useState();
    const [ editSettingVisible, setEditSettingVisible ] = React.useState(false);
    const { settings } = React.useContext(SettingsContext);
    let { id } = useParams();
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const op = React.useRef(null);
    const toast = React.useRef(null);

    const flipFetchFlag = () => {
        setFetchFlag(!fetchFlag);
    }

    const toggleEditVisible = () => {
		setEditVisible(!editVisible);
        flipFetchFlag();
	}

    const selectTicket = (e) => {
        setSelectedTicket(e.value);
        navigate("/tickets/" + e.value.ticketId);
    }

    const selectInvoice = (e) => {
        navigate("/invoices/" + e.value.invoiceId);
    }

    const toggleCreateDeviceVisible = () => {
        setDeviceCreateVisible(!deviceCreateVisible);
        setSelectedDevice(null);
        flipFetchFlag();
    }

    const toggleEditSettingVisible = () => {
        setEditSettingVisible(!editSettingVisible);
        flipFetchFlag();
    }

    const editDeviceBody = (rowData) => {
        return <Button className="p-button-text" label="View / Edit" icon='pi pi-pencil' onClick={() => {
            setSelectedDevice(rowData);
            setDeviceCreateVisible(true);
        }} />
    }

    const deleteDeviceBody = (rowData) => {
        return <Button className="p-button-outlined p-button-danger" icon='pi pi-trash' label="Remove Device" onClick={(e) => confirmDeleteDevice(rowData, e)} />
    }

    const approveInvoiceBody = (rowData) => {
        if (rowData.tickets.length === 0) return;
        if (rowData.paid) {
            return <div>Paid</div>
        } else if (rowData.sent) {
            return <div>Approved</div>
        } else {
            return <Button className='p-button-outlined' icon='pi pi-send' label="Approve" onClick={() => handleApprove(rowData.invoiceId)} />
        }
    }

    const ticketStatusBodyTemplate = (rowData) => {
        return (<div className={rowData.statusType}>{rowData.status}</div>)
    }

    const valueBodyTemplate = (rowData) => {
        if (rowData.value !== '') {
            return (<div>rowData.value</div>)
        } else {
            return (<div>{rowData.enabled ? 'Enabled' : 'Disabled'}</div>)
        }
    }

    const editSettingBody = (rowData) => {
        return <Button className="p-button-text" label="View / Edit" icon="pi pi-pencil" onClick={() => {
            setSelectedSetting(rowData);
            toggleEditSettingVisible();
        }}/>
    }

    const nameBody = (rowData) => {
        if (rowData.estimate) {
            return (<div style={{ color: "var(--bs-green)" }}>{rowData.name + " (Estimate)"}</div>);
        } else {
            return (<div>{rowData.name}</div>);
        }
    }

    const invoiceNameBody = (rowData) => {
        const ticket = rowData.tickets ? rowData.tickets[0] : null;
        if (ticket?.estimate) {
            return (<div style={{ color: "var(--bs-green)" }}>{ticket.name + " (Estimate)"}</div>);
        } else {
            return (<div>{ticket?.name}</div>);
        }
    }
    
    const handleApprove = (invoiceId) => {
        setLoading(true);
        axiosInstance.current.put("/invoices/" + invoiceId + "/approve").catch((err) => {
            console.error(err);
        })
        .finally(() => {
            flipFetchFlag();
            setLoading(false);
        });
    }

    const deleteCustomer = () => {
        axiosInstance.current.delete("/customers/" + customer.customerId).then(() => {
            navigate("/customers");
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const reject = () => {
    };

	const confirmDeleteCustomer = (e) => {
		confirmPopup({
			target: e.currentTarget,
			message: "Are you sure you want to delete this customer?",
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: deleteCustomer,
			reject
		})
	}

    const deleteDevice = (deviceId) => {
        axiosInstance.current.delete("/devices/" + deviceId).then(() => {
            flipFetchFlag();
        })
        .catch((err) => {
            console.log(err);
        })
    }

    const confirmDeleteDevice = (device, e) => {
		confirmPopup({
			target: e.currentTarget,
			message: "Are you sure you want to delete this device?",
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: () => deleteDevice(device.deviceId),
			reject
		})
	}

    const handleNoteSave = () => {
        setLoading(true);
        axiosInstance.current.post("/customers", customer).then((response) => {
            flipFetchFlag();
            toast.current.show({ severity: 'success', summary: 'Success!', detail: 'Customer saved successfully!'});
        })
        .catch((err) => {
            console.error(err);
            toast.current.show({ severity: 'error', summary: 'Error!', detail: 'There was an error while saving. Please try again or contact support.'});
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const handleClassificationChange = (e) => {
        setLoading(true);
        customer.classification = e.value;
        axiosInstance.current.post("/customers", customer).then((response) => {
            flipFetchFlag();
            toast.current.show({ severity: 'success', summary: 'Success!', detail: 'Customer saved successfully!'});
        })
        .catch(err => {
            console.error(err);
            toast.current.show({ severity: 'error', summary: 'Error!', detail: 'There was an error while saving. Please try again or contact support.'});
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const classificationOptionTemplate = (classification) => {
		return (<div className={classification}>{classification}</div>)
	}

	const selectedClassificationTemplate = (classification) => {
		return (<div className={classification}>{classification}</div>)
	}

    React.useEffect(() => {
        setLoadingTickets(true);
        setLoadingDevices(true);
        setLoadingInvoices(true);
        axiosInstance.current.get("/tickets/customers/" + id).then((response) => {
            if (response.data) {
                response.data.forEach(t => {
                    t.displayCreateTime = format(parseISO(t.createTime), "MMMM dd, yyyy h:mm a")
                    t.assignedFullname = t.assigned ? t.assignedFullname : 'Unassigned';
                })
                setTickets(response.data);
            }
        })
        .finally(() => {
            setLoadingTickets(false);
        })
        axiosInstance.current.get("/devices/customers/" + id).then((response) => {
            if (response.data) {
                setDevices(response.data);
            }
        })
        .finally(() => {
            setLoadingDevices(false);
        })
        // get invoices
        axiosInstance.current.get("/invoices/customers/" + id).then((response) => {
            if (response.data) {
				response.data.forEach(i => {
					i.displayName = i.invoiceId.substring(0, 8);
					i.createTimeDisplay = format(parseISO(i.createTime), "MMMM dd, yyyy h:mm a")
					let subtotal = 0;
					i.tickets.forEach(t => {
						t.lineItems.forEach(line => {
							subtotal += line.charge.price * line.quantity * (line.discount?.percentage ? 1 - (line.discount?.percentage / 100) : 1) - (line.discount?.amount ? line.discount?.amount : 0);
						})
					})
					i.subtotal = subtotal.toFixed(2);
				});
				setInvoices(response.data);
			}
        })
        .finally(() => {
            setLoadingInvoices(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer])

    React.useEffect(() => {
        setLoading(true);
        axiosInstance.current.get("/customers/" + id).then((response) => {
            if (response.data) {
                setCustomer(response.data);
            }
        })
        .catch((err) => {
            console.error(err);
        })
        .finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFlag]);

    return (
        <>
            <Toast ref={toast} />
            {loading ?
                <ProgressWrapper />
            :
                <div>
                    <ConfirmPopup />
                    <CustomersCreate customer={customer} visible={editVisible} hide={toggleEditVisible} flipFetchFlag={flipFetchFlag} />
                    <DeviceCreateEdit customerId={id} device={selectedDevice} visible={deviceCreateVisible} hide={toggleCreateDeviceVisible}/>
                    <EditSetting setting={selectedSetting} visible={editSettingVisible} hide={toggleEditSettingVisible} />
                    <nav className="m20">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
                            <li className="breadcrumb-item" onClick={() => navigate("/customers")}>Customers</li>
                            <li className="breadcrumb-item active">{customer.firstName + ' ' + customer.lastName}</li>
                        </ol>
                    </nav>
                    <div className="title">
                        {customer.firstName + ' ' + customer.lastName}
                        <span className="ml10" onClick={(e) => op.current.toggle(e)}><FontAwesomeIcon icon={faEllipsisH} /></span>
                    </div>
						<OverlayPanel ref={op} id="options-overlay" className="options-overlaypane">
							<div className="flex-col start">
                                <Button label="Edit" className="p-button-text p-button-plain" onClick={toggleEditVisible}/>
								<Button label="Delete" className="p-button-text p-button-plain" onClick={confirmDeleteCustomer} />
                            </div>
						</OverlayPanel>
                        <div className="secondary-title text-primary">ID: {customer.customerId.substring(0,8)}</div>
                        <Dropdown options={classificationList} value={customer.classification} onChange={handleClassificationChange} itemTemplate={classificationOptionTemplate} valueTemplate={selectedClassificationTemplate} />
                    <div className="flex-row wrap">
                        <div className="flex-col half-width">
                            <div className="secondary-title">Email:</div>
                            <div className="tertiary-title">{customer.email}</div>
                            <div className="secondary-title">Phone:</div>
                            <div className="tertiary=title">{customer.phone}</div>
                            {customer.phoneAlt ?
                                <>
                                    <div className="secondary-title">Alternate Phone:</div>
                                    <div className="tertiary-title">{customer.phoneAlt}</div>
                                </>
                            :
                                <></>
                            }
                            {customer.fax ?
                                <>
                                    <div className="secondary-title">Fax:</div>
                                    <div className="tertiary-title">{customer.fax}</div>
                                </>
                            :
                                <></>
                            }
                        </div>
                        <div className="flex-col half-width">
                            <div className="secondary-title">Address:</div>
                            <div className="tertiary-title">{customer.addressOne}</div>
                            <div className="tertiary-title">{customer.addressTwo}</div>
                            <div className="tertiary-title">{customer.city}, {customer.state} {customer.zip}</div>
                        </div>
                    </div>

                    <div className="mt30">
                        <TabView className="tabview-header-icon" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                            <TabPanel header="Notes" leftIcon="pi pi-fw pi-comment">
                                <Editor value={customer.note} onTextChange={(e) => customer.note = e.htmlValue} />
                                <br />
                                <Button className="p-button-outlined" label="Save" onClick={handleNoteSave} />
                            </TabPanel>
                            <TabPanel header="Tickets" leftIcon="pi pi-fw pi-file">
                                {loadingTickets ?
                                    <ProgressWrapper />
                                :
                                    <>
                                        <Button style={{ marginBottom: 10 }} className="p-button-outlined p-button-primary" label="Create New Ticket" onClick={() => navigate("/tickets/create")} disabled={customer.classification === 'Blocked'}/>
                                        <DataTable
                                            className="full-width"
                                            paginator
                                            rows={10}
                                            value={tickets}
                                            responsiveLayout="stack"
                                            size="small"
                                            selectionMode="single"
                                            selection={selectedTicket}
                                            onSelectionChange={(e) => selectTicket(e)}
                                            dataKey="ticketId"
                                        >
                                            <Column field="name" header="Name" body={nameBody}></Column>
                                            <Column field="customerName" header="Customer"></Column>
                                            <Column field="status" header="Status" body={ticketStatusBodyTemplate}></Column>
                                            <Column field="assignedFullname" header="Assigned"></Column>
                                            <Column field="displayCreateTime" header="Create Time"></Column>
                                        </DataTable>
                                    </>
                                }
                            </TabPanel>
                            <TabPanel header="Devices" leftIcon="pi pi-fw pi-server">
                                {loadingDevices ?
                                    <ProgressWrapper />
                                :
                                    <>
                                        <Button style={{ marginBottom: 10 }} className="p-button-outlined p-button-primary" label="Create New Device" onClick={() => setDeviceCreateVisible(true)} />
                                        <DataTable
                                            className="full-width"
                                            paginator
                                            rows={10}
                                            value={devices}
                                            responsiveLayout="stack"
                                            size="small"
                                            dataKey="deviceId"
                                        >
                                            <Column field="name" header="Name"></Column>
                                            <Column field="type" header="Type"></Column>
                                            <Column field="brand" header="Brand"></Column>
                                            <Column field="model" header="Model"></Column>
                                            <Column field="serialNumber" header="Serial Number"></Column>
                                            <Column body={editDeviceBody}></Column>
                                            <Column body={deleteDeviceBody}></Column>
                                        </DataTable>
                                    </>
                                }
                            </TabPanel>
                            <TabPanel header="Invoices" leftIcon="pi pi-fw pi-money-bill">
                                {loadingInvoices ?
                                    <ProgressWrapper />
                                :
                                    <>
                                        {/* <Button style={{ marginBottom: 10 }} className="p-button-outlined p-button-primary" label="Create New Invoice"/> */}
                                        <DataTable
                                            className="full-width"
                                            paginator
                                            rows={10}
                                            value={invoices}
                                            responsiveLayout="stack"
                                            size="small"
                                            selectionMode="single"
                                            selection={selectedInvoice}
                                            onSelectionChange={(e) => selectInvoice(e)}
                                            dataKey="invoiceId"
                                        >
                                            <Column field="displayName" header="ID" sortable></Column>
                                            <Column header="Name" body={invoiceNameBody} sortable></Column>
                                            <Column field="subtotal" header={`Subtotal (${settings.find(s => s.key === 'Currency')?.value})`} sortable></Column>
                                            <Column field="arrears" header=">30 due?" sortable></Column>
                                            <Column body={approveInvoiceBody} header="Status" sortable></Column>
                                        </DataTable>
                                    </>
                                }
                            </TabPanel>
                            <TabPanel header="Settings" leftIcon="pi pi-cog">
                                <DataTable
                                    className="full-width"
                                    paginator
                                    rows={10}
                                    value={customer.settings}
                                    responsiveLayout="stack"
                                    size="small"
                                    dataKey="key"
                                >
                                    <Column field="description" header="Setting" sortable></Column>
                                    <Column body={valueBodyTemplate} header="Value" sortable></Column>
                                    <Column body={editSettingBody}></Column>
                                </DataTable>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            } 
        </>
        
    )
}

export default CustomerDetails;