import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../utils/hooks.ts';
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { parseISO, format } from 'date-fns';
import './tickets.css';
import ProgressWrapper from '../../components/ProgressWrapper';
import { FilterMatchMode } from 'primereact/api';
import { statusTexts } from '../../utils/statuses.js';

export default function Tickets() {
    const [ loading, setLoading ] = React.useState(true);
    const [ tickets, setTickets ] = React.useState([]);
    const [ selectedTicket, setSelectedTicket ] = React.useState();
    // eslint-disable-next-line
    const [ filters, setFilters ] = React.useState({
        'customerName': { value: null, matchMode: FilterMatchMode.CONTAINS},
        'name': { value: null, matchMode: FilterMatchMode.CONTAINS},
        'shortId': { value: null, matchMode: FilterMatchMode.CONTAINS},
        'assignedFullname': { value: null, matchMode: FilterMatchMode.CONTAINS},
        'status': { value: null, matchMode: FilterMatchMode.EQUALS}

    })
    const axiosInstance = useAxios();
    const navigate = useNavigate();

    const selectTicket = (e) => {
        setSelectedTicket(e.value);
        navigate("/tickets/" + e.value.ticketId);
    }

    const ticketStatusBodyTemplate = (rowData) => {
        return (<div className={rowData.statusType}>{rowData.status}</div>)
    }

    const statusFilterElement = (options) => {
        return <Dropdown style={{ margin: 10 }} options={statusTexts} value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} showClear/>
    }

    const statusItemTemplate = (option) => {
        return (<div className={option}>{option}</div>)
    }

    const nameBody = (rowData) => {
        if (rowData.estimate) {
            return (<div style={{ color: "var(--bs-green)" }}>{rowData.name + " (Estimate)"}</div>);
        } else {
            return (<div>{rowData.name}</div>);
        }
    }

    React.useEffect(() => {
        setLoading(true);
        axiosInstance.current.get("/tickets/office").then((response) => {
            if (response.data) {
                response.data.forEach(t => {
                    t.displayCreateTime = format(parseISO(t.createTime), "MMMM dd, yyyy h:mm a")
                    t.assignedFullname = t.assigned ? t.assignedFullname : 'Unassigned';
                    t.shortId = t.ticketId.substring(0, 8);
                })
                setTickets(response.data);
            }
        })
        .catch((err) => {
            if (err.response.status === 401) {
                navigate("/setup");
            }
        })
        .finally(() => {
            setLoading(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <nav className="m20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item active">Tickets</li>
				</ol>
			</nav>
            <div className="title mt10">Tickets</div>
            <div className="mt10">
                <Button label="Create Ticket" className="p-button-outlined p-button-primary" icon='pi pi-plus' onClick={() => navigate("/tickets/create")} />
            </div>
            {loading ?
                <ProgressWrapper />
            :
                <>
                    <DataTable
                        className="full-width"
                        paginator
                        rows={10}
                        value={tickets}
                        responsiveLayout="stack"
                        size="small"
                        selectionMode="single"
                        selection={selectedTicket}
                        onSelectionChange={(e) => selectTicket(e)}
                        dataKey="ticketId"
                        filters={filters}
                        filterDisplay='row'
                    >
                        <Column field="shortId" header="ID" filter filterPlaceholder="Search by ID" sortable></Column>
                        <Column body={nameBody} header="Name" filter filterPlaceholder="Search by ticket name" sortable></Column>
                        <Column field="customerName" header="Customer" filter filterPlaceholder="Search by customer name" sortable></Column>
                        <Column field="status" header="Status" body={ticketStatusBodyTemplate} showFilterMenu={false} filterElement={statusFilterElement} sortable></Column>
                        <Column field="assignedFullname" header="Assigned" filter filterPlaceholder="Search by assigned" sortable></Column>
                        <Column field="displayCreateTime" header="Create Time" sortable></Column>
                    </DataTable>
                </>
            }
        </div>
    )
}