import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useAxios } from '../../utils/hooks.ts';

export default function OrderReceive({ order, visible, hide }) {
    // eslint-disable-next-line
	const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
	const axiosInstance = useAxios();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            receivedCount: order ? order.orderCount : 0,
        },
        validate: (data) => {
            let errors = {};

            if (!data.receivedCount) {
                errors.receivedCount = 'Received count is required.';
            }

            return errors;
        },
        onSubmit: (data) => {
            data.inventoryOrderId = order?.inventoryOrderId;
            setFormData(data);
            axiosInstance.current.put("/inventory/order/receive", data).then(() => {
                formik.resetForm();
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setSaving(false);
				hide();
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <Dialog header="Receive Order" visible={visible} onHide={hide}>
            <div className="flex justify-content-center">
                <div className="card">
                    <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
                        <div className="field m20">Order Count: {order?.orderCount}</div>
                        <div className="field m20">Order Price: {order?.orderPrice}</div>
                        <div className="field m20">Order Link: {order?.orderLink}</div>
						<div className="field m20">
                            <span className="p-float-label">
                                <InputNumber min={0} id="receivedCount" name="receivedCount" value={formik.values.receivedCount} onValueChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('receivedCount') })} />
                                <label htmlFor="receivedCount" className={classNames({ 'p-error': isFormFieldValid('receivedCount') })}>Received Count *</label>
                            </span>
							{getFormErrorMessage('receivedCount')}
                        </div>
                        <div className="flex-row justify-content-center mt20">
                            <Button style={{ margin: 10 }} type="submit" label="Receive Order" icon="pi pi-save" loading={saving} />
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    )
}