import * as React from 'react';
import { useAxios } from '../../utils/hooks.ts';
import ProgressWrapper from '../ProgressWrapper';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { subMonths, addDays, format } from 'date-fns';
import { SettingsContext } from '../../../settings-context.js';
import { getInvoiceSubtotal } from '../../services/InvoiceService.js';
import { Divider } from 'primereact/divider';

export default function IncomeTab({ toast }) {
    const [ loading, setLoading ] = React.useState(false);
    const [ revenueTotal, setRevenueTotal ] = React.useState();
    const [ expenseTotal, setExpenseTotal ] = React.useState();
    const [ fromDate, setFromDate ] = React.useState();
    const [ toDate, setToDate ] = React.useState();
    const { settings } = React.useContext(SettingsContext);
    const axiosInstance = useAxios();

    const getReport = () => {
        setLoading(true);
        axiosInstance.current.get("/inventory/order", { params: { fromDate: format(fromDate, 'yyyy-MM-dd'), toDate: format(toDate, 'yyyy-MM-dd')}}).then((response) => {
            let orderTotal = 0.00;
            response.data.forEach(o => {
                orderTotal += o.orderPrice;
            })
            axiosInstance.current.get("/invoices/range", { params: { fromDate: format(fromDate, 'yyyy-MM-dd'), toDate: format(toDate, 'yyyy-MM-dd')}}).then((response) => {
                let total = 0.00;
                response.data.forEach(i => {
                    total += getInvoiceSubtotal(i);
                })
                setRevenueTotal(total.toFixed(2));
                let expSubtotal = 0.00;
                console.log('settings', settings.find(s => s.key === 'Margin')?.value);
                if (settings.find(s => s.key === 'Margin')?.value != null) {
                    response.data.forEach(i => {
                        i.tickets.forEach(t => {
                            t.lineItems.forEach(line => {
                                if (line.charge.inventory == null) {
                                    expSubtotal = line.quantity * line.charge.price * (1 - (settings.find(s => s.key === 'Margin').value / 100.0));
                                }
                            })
                        })
                    })
                }
                setExpenseTotal(orderTotal + expSubtotal);
            })
            .catch(() => {
                toast.current.show({ severity: 'error', summary: 'Error!', detail: 'Unable to get invoices at this time. Please try again or contact support.'});
            })
            .finally(() => {
                setLoading(false);
            });
        })
        .catch(() => {
            toast.current.show({ severity: 'error', summary: 'Error!', detail: 'Unable to get orders at this time. Please try again or contact support.'});
        })
        .finally(() => {
            setLoading(false);
        });
        
    }

    React.useEffect(() => {
        setFromDate(subMonths(new Date(), 1));
        setToDate(addDays(new Date(), 1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            
            <div className="flex-row start">
                <div className="m20">
                    <div className="secondary-title">From</div>
                    <Calendar id="fromDate" name="fromDate" value={fromDate} onChange={(e) => setFromDate(e.value)}/>
                </div>
                <div className="m20">
                    <div className="secondary-title">To</div>
                    <Calendar id="toDate" name="toDate" value={toDate} onChange={(e) => setToDate(e.value)}/>
                </div>
            </div>
            <div className="flex-row start">
                <div className="m20">
                    <Button label="Get Report" className="p-button-outlined" onClick={getReport} />
                </div>
            </div>
            {loading ?
                <ProgressWrapper />
            :
            <>
                <div className="grid-container">
                    {revenueTotal ?
                    <div>
                        <div className="secondary-title" style={{ gridColumn: "1 / span 2"}}>Revenue</div>
                        <div style={{ gridColumn: 2, textAlign: 'right'}}>{settings.find(s => s.key === 'Currency').value}{revenueTotal}</div>
                        <div className="secondary-title" style={{ gridColumn: "1 / span 2"}}>Cost of Goods Sold</div>
                        <div style={{ gridColumn: 2, textAlign: 'right'}}>{settings.find(s => s.key === 'Currency').value}{expenseTotal}</div>
                        <Divider />
                        <div className="secondary-title" style={{ gridColumn: "1 / span 2"}}>Net Income</div>
                        <div className="secondary-title" style={{ gridColumn: 2, textAlign: 'right'}}>{settings.find(s => s.key === 'Currency').value}{revenueTotal - expenseTotal}</div>
                    </div>
                    :
                    <></>
                    }
                </div>
            </>
            }
            <div className="audit-details half-width">Note: Revenue is calculated from all line items from invoices in given range. 
            Cost of goods sold is calculated from inventory orders in given range and margin on non-inventoried items.</div>
        </div>
    )
}