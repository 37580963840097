import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../utils/hooks.ts';
import ProgressWrapper from '../../components/ProgressWrapper';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabView } from 'primereact/tabview';
import { TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import ChargeCreateEdit from '../../components/charges/ChargeCreateEdit';
import DiscountCreateEdit from '../../components/discounts/DiscountCreateEdit';
import { Toast } from 'primereact/toast';
import Invite from '../../components/settings/Invite';
import { SettingsContext } from '../../../settings-context';
import { CompanyContext } from '../../../company-context';
import OfficeCreateEdit from '../../components/offices/OfficeCreateEdit';
import { Image } from 'primereact/image';
import EditEngineer from '../../components/engineers/EditEngineer';
import { FilterMatchMode } from 'primereact/api';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { InputSwitch } from 'primereact/inputswitch';

function Company() {
	const [ loading, setLoading ] = React.useState(true);
	const [ company, setCompany ] = React.useState();
	const [ activeIndex, setActiveIndex ] = React.useState();
	const [ fetchFlag, setFetchFlag ] = React.useState(false);
	const [ charges, setCharges ] = React.useState([]);
	const [ selectedCharge, setSelectedCharge ] = React.useState();
	const [ createEditChargeVisible, setCreateEditChargeVisible ] = React.useState(false);
	const [ discounts, setDiscounts ] = React.useState([]);
	const [ selectedDiscount, setSelectedDiscount ] = React.useState();
	const [ createEditDiscountVisible, setCreateEditDiscountVisible ] = React.useState(false);
	const [ inviteVisible, setInviteVisible ] = React.useState();
	const [ selectedOffice, setSelectedOffice ] = React.useState();
	const [ createEditOfficeVisible, setCreateEditOfficeVisible ] = React.useState(false);
	const [ selectedEngineer, setSelectedEngineer ] = React.useState();
	const [ editEngineerVisible, setEditEngineerVisible ] = React.useState(false);
    // eslint-disable-next-line
	const [ chargeFilters, setChargeFilters ] = React.useState({
		'sku': {value: null, matchMode: FilterMatchMode.CONTAINS},
		'upc': {value: null, matchMode: FilterMatchMode.CONTAINS},
		'price': {value: null, matchMode: FilterMatchMode.CONTAINS},
		'description': {value: null, matchMode: FilterMatchMode.CONTAINS}
	});
    // eslint-disable-next-line
	const [ discountFilters, setDiscountFilters ] = React.useState({
		'code': {value: null, matchMode: FilterMatchMode.CONTAINS},
		'description': {value: null, matchMode: FilterMatchMode.CONTAINS}
	});
    // eslint-disable-next-line
	const [ officeFilters, setOfficeFilters ] = React.useState({
		'name': {value: null, matchMode: FilterMatchMode.CONTAINS},
		'email': {value: null, matchMode: FilterMatchMode.CONTAINS},
	})
    // eslint-disable-next-line
	const [ engineerFilters, setEngineerFilters ] = React.useState({
		'fullName': {value: null, matchMode: FilterMatchMode.CONTAINS},
		'email': {value: null, matchMode: FilterMatchMode.CONTAINS},
		'office': {value: null, matchMode: FilterMatchMode.CONTAINS},
		
	});
    // eslint-disable-next-line
    const { settings, setSettings } = React.useContext(SettingsContext);
    // eslint-disable-next-line
	const { companyDetails, setCompanyDetails } = React.useContext(CompanyContext);
	const navigate = useNavigate();
	const axiosInstance = useAxios();
	const toast = React.useRef(null);

	const flipFetchFlag = () => {
		setFetchFlag(!fetchFlag);
	}

	const toggleCreateEditChargeVisible = () => {
		flipFetchFlag();
		setCreateEditChargeVisible(!createEditChargeVisible);
		setSelectedCharge(null);
	}

	const toggleCreateEditDiscountVisible = () => {
		flipFetchFlag();
		setCreateEditDiscountVisible(!createEditDiscountVisible);
		setSelectedDiscount(null);
	}

	const toggleInviteVisible = () => {
		setInviteVisible(!inviteVisible);
	}

	const toggleCreateEditOfficeVisible = () => {
		setSelectedOffice(null);
		setCreateEditOfficeVisible(!createEditOfficeVisible);
	}

	const toggleEditEngineerVisible = () => {
		setEditEngineerVisible(!editEngineerVisible);
		flipFetchFlag();
	}

	const selectOffice = (e) => {
		setSelectedOffice(e);
		setCreateEditOfficeVisible(true);
	}

	const selectEngineer = (e) => {
		setSelectedEngineer(e);
		setEditEngineerVisible(true);
	}

	const inviteSuccess = (success) => {
		if (success) { 
			toast.current.show({severity: 'success', summary: 'Invited!', detail: 'Invite sent!'});
		} else {
			toast.current.show({severity: 'error', summary: 'Error!', detail: 'Error while sending invite. Please try again or contact support.'});
		}
	}

	const editOfficeBody = (rowData) => {
		return <Button className="p-button-text" label="View / Edit" icon="pi pi-pencil" onClick={() => selectOffice(rowData)} />
	}

	const editEngineerBody = (rowData) => {
		return <Button className="p-button-text" label="View / Edit" icon="pi pi-pencil" onClick={() => selectEngineer(rowData)} />
	}

	const assignedOfficeBody = (rowData) => {
		const office = company.offices.find(o => o.officeId === rowData.officeId);
		return <div>{office?.name}</div>
	}

	const deleteOfficeBody = (rowData) => {
		return <Button className="p-button-outlined p-button-danger" label="Remove" icon='pi pi-trash' onClick={(e) => confirmDeleteOffice(rowData, e)} />
	}

	const chargeExemptBody = (rowData) => {
		return <div className="audit-details">{rowData.exempt ? 'Yes' : 'No'}</div>
	}

	const chargeLaborBody = (rowData) => {
		return <div className="audit-details">{rowData.labor ? 'Yes' : 'No'}</div>
	}

	const onHandBody = (rowData) => {
		if (rowData.inventory != null) {
			return <div className="audit-details">{rowData.inventory.onHandCount}</div>
		} else {
			return <div className="audit-details">N/A</div>
		}
	}

	const editChargeBody = (rowData) => {
		return <Button className="p-button-text" label="View/Edit" icon="pi pi-pencil" onClick={() => {setSelectedCharge(rowData);setCreateEditChargeVisible(true);}} />
	}

	const removeChargeBody = (rowData) => {
		return <Button className="p-button-outlined p-button-danger" icon="pi pi-trash" onClick={(e) => deleteCharge(rowData)} />
	}

	const toggleChargeBody = (rowData) => {
		return <InputSwitch checked={rowData.active} onChange={() => toggleCharge(rowData)} />
	}

	const editDiscountBody = (rowData) => {
		return <Button className="p-button-text" label="View/Edit" icon="pi pi-pencil" onClick={() => {setSelectedDiscount(rowData);setCreateEditDiscountVisible(true);}} />
	}

	const removeDiscountBody = (rowData) => {
		return <Button className="p-button-outlined p-button-danger" icon="pi pi-trash" onClick={(e) => deleteDiscount(rowData)} />
	}

	const toggleDiscountBody = (rowData) => {
		return <InputSwitch checked={rowData.active} onChange={() => toggleDiscount(rowData)} />
	}

	const deleteOffice = (office) => {
		axiosInstance.current.delete("/offices/" + office.officeId).then((response) => {
			toast.current.show({severity: 'success', summary: 'Removed!', detail: 'Office removed successfully!'});
			flipFetchFlag();
		})
		.catch((err) => {
			toast.current.show({severity: 'error', summary: 'Error!', detail: err.response.data});
		})
	}
	
	const toggleDiscount = (discount) => {
		discount.active = !discount.active;
		axiosInstance.current.post("/discounts", discount).catch((err) => {
			toast.current.show({ severity: 'error', summary: "Error!", detail: err.response.data});
		})
		.finally(() => {
			flipFetchFlag();
		});
	}

	const deleteDiscount = (discount) => {
		axiosInstance.current.delete("/discounts/" + discount.discountId).then((response) => {
			toast.current.show({severity: 'success', summary: 'Removed!', detail: 'Discount deleted!'});
			flipFetchFlag();
		})
		.catch((err) => {
			toast.current.show({severity: 'error', summary: 'Error!', detail: err.response.data});
		});
	}

	const toggleCharge = (charge) => {
		charge.active = !charge.active;
		axiosInstance.current.put("/charges", charge).catch((err) => {
			toast.current.show({ severity: 'error', summary: "Error!", detail: err.response.data});
		})
		.finally(() => {
			flipFetchFlag();
		})
	}

	const deleteCharge = (charge) => {
		axiosInstance.current.delete("/charges/" + charge.chargeId).then((response) => {
			toast.current.show({severity: 'success', summary: 'Removed!', detail: 'Charge deleted!'});
			flipFetchFlag();
		})
		.catch((err) => {
			toast.current.show({severity: 'error', summary: 'Error!', detail: err.response.data});
		});
	}

	const reject = () => {
    };

	const confirmDeleteOffice = (office, e) => {
		confirmPopup({
			target: e.currentTarget,
			message: "Are you sure you want to delete this office?",
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: () => deleteOffice(office),
			reject
		})
	}

	const uploadLogo = async (event) => {
		setLoading(true);
		const file = event.files[0];
		axiosInstance.current.post("/companies/upload_logo", file, { headers: { 'content-type': file.type}}).then(() => {
			toast.current.show({ severity: 'success', summary: 'Success!', detail: 'Logo uploaded successfully!'});
		})
		.catch(() => {
			toast.current.show({ severity: 'error', summary: 'Error!', detail: 'Unable to upload logo. Please try again or contact support.'});
		})
		.finally(() => {
			setLoading(false);
		})
	}

	const invoiceTemplateUploader = async (event) => {
		setLoading(true);
		const file = event.files[0];
		axiosInstance.current.post("/companies/upload_invoice_template", file, { headers: {'content-type': "text/plain"}}).then(() => {
			toast.current.show({ severity: 'success', summary: 'Success!', detail: 'Template uploaded successfully!'});
		})
		.catch(() => {
			toast.current.show({ severity: 'error', summary: 'Error!', detail: 'Unable to upload invoice template. Please try again or contact support.'});
		})
		.finally(() => {
			setLoading(false);
		})
	}

	const estimateTemplateUploader = async (event) => {
		setLoading(true);
		const file = event.files[0];
		axiosInstance.current.post("/companies/upload_estimate_template", file, { headers: {'content-type': "text/plain"}}).then(() => {
			toast.current.show({ severity: 'success', summary: 'Success!', detail: 'Template uploaded successfully!'});
		})
		.catch(() => {
			toast.current.show({ severity: 'error', summary: 'Error!', detail: 'Unable to upload estimate template. Please try again or contact support.'});
		})
		.finally(() => {
			setLoading(false);
		})
	}

	const onUpload = () => {
        toast.current.show({severity: 'success', summary: 'Success', detail: 'File Uploaded successfully!'});
    }

	React.useEffect(() => {
		setLoading(true);
		axiosInstance.current.get("/companies").then((response) => {
			if (response.data) {
				response.data.charges.forEach(c => c.price = c.price.toFixed(2));
				response.data.engineers.forEach(e => e.activeDisplay = e.active ? 'Active' : 'Inactive');
				response.data.discounts.forEach(d => d.activeDisplay = d.active ? "Active" : "Inactive");
				setCharges(response.data.charges);
				setCompany(response.data);
				setCompanyDetails({subscription: response.data.subscription});
				setDiscounts(response.data.discounts);
			}
		})
		.catch((err) => {
			if (err.response.status === 401) {
				navigate("/setup");
			}
		})
		.finally(() => {
			setLoading(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchFlag])

	return (
		<>
		<Toast ref={toast} />
		{loading ?
		<ProgressWrapper />
		:
		<div>
			<ConfirmPopup />
			<ChargeCreateEdit charge={selectedCharge} visible={createEditChargeVisible} hide={toggleCreateEditChargeVisible} />
			<DiscountCreateEdit discount={selectedDiscount} visible={createEditDiscountVisible} hide={toggleCreateEditDiscountVisible} />
			<Invite visible={inviteVisible} hide={toggleInviteVisible} success={inviteSuccess}/>
			<OfficeCreateEdit office={selectedOffice} visible={createEditOfficeVisible} hide={toggleCreateEditOfficeVisible} flipFetchFlag={flipFetchFlag} />
			<EditEngineer engineer={selectedEngineer} visible={editEngineerVisible} hide={toggleEditEngineerVisible} />
            <nav className="m20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item active">Company Details</li>
				</ol>
			</nav>
            <div className="title mt10">{company.name}</div>
			{company.logo ?
				<Image src={company?.logo} alt="Logo Text" width="250" height='250'/>
			:
				<>No logo uploaded yet!</>
			}
			<div className="mt20">
				<FileUpload mode="basic" name="logo[]" accept="image/*" customUpload uploadHandler={uploadLogo} onUpload={onUpload} chooseLabel="Upload Logo"/>
			</div>
			<div className="mt20">
				<FileUpload mode="basic" name="file" customUpload uploadHandler={invoiceTemplateUploader} onUpload={onUpload} chooseLabel="Upload Invoice Template"/>
			</div>
			<div className="mt20">
				<FileUpload mode="basic" name="file" customUpload uploadHandler={estimateTemplateUploader} onUpload={onUpload} chooseLabel="Upload Estimate Template"/>
			</div>
			<div className="mt20">
				<FileUpload style={{ marginBottom: 30 }} mode="basic" name="customers" url={`${window.API_URL}/api/import/${company.companyId}`} accept=".xls,.xlsx" onUpload={onUpload} chooseLabel="Import Customers"/>
			</div>
			<div className="flex-row between mt20">
				<div className="tertiary-title">
					Subscription level: {company.subscription}
					<Button disabled style={{ marginLeft: 30 }} className="p-button-outlined" label="Upgrade" icon="pi pi-angle-double-up" onClick={() => navigate("/upgrade")} />
				</div>
			</div>
			{company.subscription !== 'Basic' ?
				<div className="flex-row between mt20">
					<div className="tertiary-title">
						Portal: <a target="_blank" rel="noreferrer" href={`${window.PORTAL_URL}/${company.portalId.substring(0,8)}`}>{`${window.PORTAL_URL}/${company.portalId.substring(0,8)}`}</a>
					</div>
				</div>
			:
				<></>
			}
			{company.stripeCheckoutId ?
			<div className="flex-row between mt20">
				<div className="tertiary-title">
					<a target="_blank" rel="noreferrer" href={`${window.API_URL}/api/upgrade/portal/${company.stripeCheckoutId}`}>Stripe Customer Portal</a>
				</div>
			</div>
			:
			<></>
			}
			{loading ?
                <ProgressWrapper />
            :
                <>
					<TabView className="tabview-header-icon" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
						<TabPanel header="Charges" leftIcon="pi pi-fw pi-tags">
							<Button style={{ marginBottom: 10 }} label="Create New Charge" className="p-button-outlined" icon='pi pi-plus' onClick={() => setCreateEditChargeVisible(true)}/>
							<DataTable
								className="full-width"
								paginator
								rows={10}
								value={charges}
								responsiveLayout="stack"
								size="small"
								dataKey="chargeId"
								filters={chargeFilters}
								filterDisplay='row'
							>
								<Column field="sku" header="SKU" filter filterPlaceholder="Search by SKU" sortable></Column>
								<Column field="upc" header="UPC" filter filterPlaceholder="Search by UPC" sortable></Column>
								<Column field="price" header={`Price (${settings?.find(s => s.key === 'Currency')?.value})`} filter filterPlaceholder="Search by price" sortable></Column>
								<Column field="description" header="Description" filter filterPlaceholder="Search by description" sortable></Column>
								<Column body={onHandBody} header="On Hand"></Column>
								<Column header="Exempt" body={chargeExemptBody}></Column>
								<Column header="Labor" body={chargeLaborBody}></Column>
								<Column header="Enabled" body={toggleChargeBody}></Column>
								<Column body={editChargeBody}></Column>
								<Column body={removeChargeBody}></Column>
							</DataTable>
						</TabPanel>
						<TabPanel header="Discounts" leftIcon="pi pi-fw pi-tags">
							<Button style={{ marginBottom: 10 }} label="Create New Discount" className="p-button-outlined" icon='pi pi-plus' onClick={() => setCreateEditDiscountVisible(true)}/>
							<DataTable
								className="full-width"
								paginator
								rows={10}
								value={discounts}
								responsiveLayout="stack"
								size="small"
								dataKey="discountId"
								filters={discountFilters}
								filterDisplay='row'
							>
								<Column field="code" header="Code" filter filterPlaceholder="Search by code" sortable></Column>
								<Column field="description" header="Description" filter filterPlaceholder="Search by description" sortable></Column>
								<Column field="percentage" header="Percentage (%)" sortable></Column>
								<Column field="amount" header={`Amount (${settings?.find(s => s.key === 'Currency')?.value})`} sortable></Column>
								<Column field="activeDisplay" header="Active?" sortable></Column>
								<Column header="Enabled" body={toggleDiscountBody}></Column>
								<Column body={editDiscountBody}></Column>
								<Column body={removeDiscountBody}></Column>
							</DataTable>
						</TabPanel>
						<TabPanel header="Offices" leftIcon="pi pi-fw pi-tags">
							{company.subscription === 'Enterprise' ?
								<Button style={{ marginBottom: 10 }} label="Create New Office" className="p-button-outlined" icon='pi pi-plus' onClick={() => setCreateEditOfficeVisible(true)}/>
							:
								<></>
							}
							<DataTable
								className="full-width"
								paginator
								rows={10}
								value={company.offices}
								responsiveLayout="stack"
								size="small"
								dataKey="officeId"
								filters={officeFilters}
								filterDisplay='row'
							>
								<Column field="name" header="Name" filter filterPlaceholder="Search by name" sortable></Column>
								<Column field="email" header="Email" filter filterPlaceholder="Search by email" sortable></Column>
								<Column field="phone" header="Phone" sortable></Column>
								<Column body={editOfficeBody}></Column>
								<Column body={deleteOfficeBody}></Column>
							</DataTable>
						</TabPanel>
						<TabPanel header="Engineers" leftIcon="pi pi-fw pi-tags">
							{company.subscription === 'Enterprise' ?
								<Button style={{ marginBottom: 10 }} label="Add Engineer" className="p-button-outlined" icon='pi pi-plus' onClick={toggleInviteVisible}/>
							:
								<></>
							}
							<DataTable
								className="full-width"
								paginator
								rows={10}
								value={company.engineers}
								responsiveLayout="stack"
								size="small"
								dataKey="engineerId"
								filters={engineerFilters}
								filterDisplay='row'
							>
								<Column field="fullName" header="Name" filter filterPlaceholder="Search by name" sortable></Column>
								<Column field="email" header="Email" filter filterPlaceholder="Search by email" sortable></Column>
								<Column body={assignedOfficeBody} header="Office" sortable></Column>
								<Column field="phone" header="Phone" sortable></Column>
								<Column field="activeDisplay" header="Active?" sortable></Column>
								<Column body={editEngineerBody}></Column>
							</DataTable>
						</TabPanel>
					</TabView>
                </>
            }
		</div>
		}
		</>
	)
}

export default Company;