import { useState } from 'react';
import { Card } from 'primereact/card';
import { format, parseISO } from 'date-fns';
import { Button } from 'primereact/button';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { useAxios } from '../../utils/hooks.ts';
import { Editor } from 'primereact/editor';

function Note({ note, flipFetchFlag }) {
    const [ text, setText ] = useState(note.text);
    const [ saving, setSaving ] = useState(false);
	const axiosInstance = useAxios();

	const accept = () => {
        axiosInstance.current.delete("/tickets/notes/" + note.noteId)
            .finally(() => {
                flipFetchFlag();
            })
    };

	const reject = () => {
    };

    const confirm = (e) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete this note?",
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        })
    }

    const save = () => {
        setSaving(true);
        note.text = text;
        axiosInstance.current.post("/tickets/notes", note).then(() => {
            console.log('saved');
        })
        .catch((err) => {
            console.err('err');
        })
        .finally(() => {
            setSaving(false);
        })
    }

	return (
		<div className="mt20">
            <ConfirmPopup />
			<Card>
                <div className="flex-row between">
                    <div>
                        <Editor value={text} onTextChange={(e) => setText(e.htmlValue)}/>
                        <div className="audit-details mt10">Created by: {note.author ? note.author : 'Customer'} on {format(parseISO(note.createTime), "MMMM dd, yyyy h:mm a")}</div>
                        {note.internal ?
                            <div className="audit-details mt10 text-info">Internal</div>
                        :
                            <></>
                        }
                    </div>
                    <div>
				        <Button icon='pi pi-trash' className="p-button-text p-button-danger" onClick={confirm} />
				        <Button icon='pi pi-save' className="p-button-text p-button-primary" onClick={save} loading={saving} />
                    </div>
                </div>
				
			</Card>
		</div>
	)

}

export default Note;