export const statuses = [
	{status: "NOT_STARTED", text: "Not Started"},
	{status: "DIAGNOSIS", text: "Diagnosis"},
	{status: "IN_PROGRESS", text: "In Progress"},
	{status: "WAITING_ON_CUSTOMER", text: "Waiting on Customer"},
	{status: "WAITING_ON_DEVICE_DELIVERY", text: "Waiting on Device"},
	{status: "WAITING_ON_PARTS", text: "Waiting on Parts"},
	{status: "WAITING_ON_PICKUP", text: "Waiting on Pickup"},
	{status: "CLOSED_WITHOUT_REPAIR", text: "Closed Without Repair"},
	{status: "INVOICED", text: "Invoiced"},
	{status: "PENDING_PAYMENT", text: "Pending Payment"},
	{status: "COMPLETED", text: "Completed"}
]

export const statusTexts = [
	"Not Started",
	"Diagnosis",
	"In Progress",
	"Waiting on Customer",
	"Waiting on Device",
	"Waiting on Parts",
	"Waiting on Pickup",
	"Closed without Repair",
	"Invoiced",
	"Pending Payment",
	"Completed"
]