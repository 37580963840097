import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import { useAxios } from '../../utils/hooks.ts';

function DeviceCreateEdit({ customerId, device, visible, hide }) {
    // eslint-disable-next-line
	const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
	const axiosInstance = useAxios();

	const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: (device ? device.name : ''),
			type: (device ? device.type : ''),
			brand: (device ? device.brand : ''),
			model: (device ? device.model : ''),
			serialNumber: (device ? device.serialNumber : ''),
			username: (device ? device.username: ''),
			password: (device ? device.password: '')
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'Name is required.';
            }

			if (!data.type) {
				errors.type = 'Type is required.'
			}

			if (!data.brand) {
				errors.brand = 'Brand is required.'
			}
			
			if (!data.serialNumber) {
				errors.serialNumber = 'Serial number is required.';
			}

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            data.deviceId = device?.deviceId;
			data.customerId = customerId;
            axiosInstance.current.post("/devices", data).then(() => {
                formik.resetForm();
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setSaving(false);
				hide();
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

	return (
		<Dialog header={device ? 'View / Edit Device' : 'Create Device'} visible={visible} onHide={hide}>
			<div className="flex justify-content-center dialog">
                <div className="card">
                    <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputText id="name" name="name" value={formik.values.name} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>Name *</label>
                            </span>
                            {getFormErrorMessage('name')}
                        </div>
						<div className="field m20">
                            <span className="p-float-label">
                                <InputText id="type" name="type" value={formik.values.type} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('type') })} />
                                <label htmlFor="type" className={classNames({ 'p-error': isFormFieldValid('type') })}>Type *</label>
                            </span>
                            {getFormErrorMessage('type')}
                        </div>
						<div className="field m20">
                            <span className="p-float-label">
                                <InputText id="brand" name="brand" value={formik.values.brand} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('brand') })} />
                                <label htmlFor="brand" className={classNames({ 'p-error': isFormFieldValid('brand') })}>Brand *</label>
                            </span>
							{getFormErrorMessage('brand')}
                        </div>
						<div className="field m20">
                            <span className="p-float-label">
                                <InputText id="model" name="model" value={formik.values.model} onChange={formik.handleChange}/>
                                <label htmlFor="model">Model</label>
                            </span>
                        </div>
						<div className="field m20">
                            <span className="p-float-label">
                                <InputText id="serialNumber" name="serialNumber" value={formik.values.serialNumber} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('serialNumber') })} />
                                <label htmlFor="serialNumber" className={classNames({ 'p-error': isFormFieldValid('serialNumber') })}>Serial Number *</label>
                            </span>
                            {getFormErrorMessage('serialNumber')}
                        </div>
						<div className="field m20">
                            <span className="p-float-label">
                                <InputText id="username" name="username" value={formik.values.username} onChange={formik.handleChange}/>
                                <label htmlFor="username">Username</label>
                            </span>
                        </div>
						<div className="field m20">
                            <span className="p-float-label">
                                <Password id="password" name="password" value={formik.values.password} toggleMask feedback={false} onChange={formik.handleChange}/>
                                <label htmlFor="pasword">Password</label>
                            </span>
                        </div>

                        <div className="flex-row justify-content-center mt20">
                            {device ?
                                <Button style={{ margin: 5 }} type="submit" label="Save Device" loading={saving} icon='pi pi-save'/>
                            :
                                <Button style={{ margin: 5 }} type="submit" label="Create Device" loading={saving} icon='pi pi-save'/>
                            }
                        </div>
                    </form>
                </div>
            </div>
		</Dialog>
	)
}

export default DeviceCreateEdit;