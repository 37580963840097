import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { useAxios } from '../../utils/hooks.ts';
import { Editor } from 'primereact/editor';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import { Chips } from 'primereact/chips';
import { parseISO } from 'date-fns';
import { InputText } from 'primereact/inputtext';

export default function AppointmentCreateEdit({ appointment, visible, hide, ticket, flipFetchFlag }) {
    // eslint-disable-next-line
	const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
    const [ text, setText ] = React.useState();
    const [ currentTime, setCurrentTime ] = React.useState();
    const [ attendees, setAttendees ] = React.useState();
	const axiosInstance = useAxios();

	const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: appointment ? appointment.title : '',
            duration: appointment ? appointment.duration : 15,
            ticketId: ticket.ticketId,
            scheduledTime: appointment ? parseISO(appointment.scheduledTime) : currentTime,
        },
        validate: (data) => {
            let errors = {};

            if (!data.title) {
                errors.title = 'Title is required.';
            }
            
            if (!data.duration) {
                errors.duration = 'Duration is required.';
            } else if (data.duration < 0) {
                errors.duration = 'Duration must be positive number.';
            }

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
			data.appointmentId = appointment?.appointmentId;
            data.notes = text;
            data.attendees = attendees || [];
            axiosInstance.current.post("/appointments", data).then(() => {
                formik.resetForm();
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setSaving(false);
                hide();
                flipFetchFlag();
            })
        }
    })

    React.useEffect(() => {
        setText(appointment?.notes);
        setAttendees(appointment ? appointment.attendees : [ticket.customerEmail]);
        setCurrentTime(new Date());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointment, visible])

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <Dialog header={appointment ? "Edit Appointment" : "Create Appointment"} visible={visible} onHide={hide}>
			<div className="flex justify-content-center" style={{ width: 750}}>
                <div className="card">
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputText id="title" name="title" maxLength={300} value={formik.values.title} onChange={formik.handleChange} className={classNames({ 'p-error': isFormFieldValid('title')})} />
                                <label htmlFor="title" className={classNames({ 'p-error': isFormFieldValid('title')})}>Title*</label>
                            </span>
                            {getFormErrorMessage('title')}
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <Calendar id="scheduledTime" name="scheduledTime" value={formik.values.scheduledTime} onChange={formik.handleChange} showTime hourFormat='12' />
                            </span>
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputNumber id="duration" name="duration" value={formik.values.duration} onValueChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('duration') })}/>
                                <label htmlFor="duration" className={classNames({ 'p-error': isFormFieldValid('duration') })}>Duration (minutes)*</label>
                            </span>
                            {getFormErrorMessage('duration')}
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <Chips id="attendees" name="attendees" separator=',' addOnBlur='true' value={attendees} onChange={(e) => setAttendees(e.value)} />
                                <label htmlFor="attendees">Attendees Emails (Comma separated)</label>
                            </span>
                        </div>
                        <span className="p-float-label" style={{ marginBottom: 40, marginLeft: 10 }}>
                            <label htmlFor="note">Appointment Notes</label>
                        </span>
                        <div className="field m20">
                            <span className="p-float-label">
                                <Editor name="note" id="note" style={{ height: '320px'}} value={text} onTextChange={(e) => setText(e.htmlValue)} />
                            </span>
                        </div>
                        <div className="flex-row justify-content-center mt20">
                            <Button style={{ width: '50%' }} type="submit" label={appointment ? 'Save' : 'Create Appointment'} icon='pi pi-save' loading={saving} />
                        </div>
                    </form>
                </div>
            </div>
		</Dialog>
    )
}