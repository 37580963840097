import * as React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import { useFormik } from 'formik';
import { useAxios } from '../../utils/hooks.ts';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';

export default function LineItemCreate({ ticket, visible, hide, displayToast }) {
    // eslint-disable-next-line
    const [ formData, setFormData ] = React.useState({});
    const [ charges, setCharges ] = React.useState([]);
    const [ discounts, setDiscounts ] = React.useState([]);
    const [ saving, setSaving ] = React.useState(false);
	const axiosInstance = useAxios();

    const setCustomPrice = (e) => {
        formik.values.charge.price = e.value;
        formik.values.charge.oneTime = true;
        formik.values.charge.chargeId = null;
    }

	const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            charge: '',
            upc: '',
            price: 0.00,
			quantity: 0,
            oneTime: false,
            description: '',
            exempt: false
        },
        validate: (data) => {
            let errors = {};

            if ((!data.upc) && !data.charge) {
                errors.name = 'Charge is required.';
            }

			if (!data.quantity) {
				errors.quantity = 'Quantity is required.';
			}

            if (!data.charge && !data.upc) {
                errors.upc = 'UPC is required.';
            }

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            data.price *= 1;
            setSaving(true);
            if (!data.charge) {
                data.charge = {
                    sku: data.upc,
                    upc: data.upc,
                    price: data.price,
                    description: data.description,
                    oneTime: data.oneTime,
                    exempt: data.exempt
                }
            }
            data.chargeId = data.charge.chargeId;
            data.ticketId = ticket.ticketId;
            axiosInstance.current.post("/lineitems", data).then((response) => {
            })
            .catch((err) => {
                displayToast({ severity: 'error', summary: 'Unable to create line item', detail: err.response ? err.response.data : 'Please try again or contact support.'});
                console.error(err);
            })
            .finally(() => {
                setSaving(false);
                onHide();
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const onHide = () => {
        formik.resetForm();
        hide();
    }

    React.useEffect(() => {
        axiosInstance.current.get("/charges").then((response) => {
            if (response.data) {
                setCharges(response.data);
            }
        });
        axiosInstance.current.get("/discounts").then((response) => {
            if (response.data) {
                setDiscounts(response.data.filter(d => d.active));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Dialog header="Add Line Item" visible={visible} onHide={onHide}>        
            <div className="form">
                <div className="flex justify-content-center">
                    <div className="card">
                        <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
                            <div className="field">
                                <span className="p-float-label">
                                    <Dropdown id="charge" name="charge" value={formik.values.charge} onChange={formik.handleChange} options={charges} optionLabel="description" filter showClear filterBy="description" className={classNames({ 'p-invalid': isFormFieldValid('charge') })} />
                                    <label htmlFor="charge" className={classNames({ 'p-error': isFormFieldValid('charge') })}>Charge</label>
                                </span>
                                {getFormErrorMessage('charge')}
                            </div>
                            {formik.values.charge ?
                                <>
                                    <div className="field">SKU: {formik.values.charge.sku}</div>
                                    <div className="field">UPC: {formik.values.charge.upc}</div>
                                    <div className="field">
                                        <span className="p-float-label">
                                            <InputNumber mode="decimal" maxFractionDigits={2} id="price" name="price" value={formik.values.charge.price} onValueChange={setCustomPrice} className={classNames({ 'p-invalid': isFormFieldValid('price') })} />
                                            <label htmlFor="price" className={classNames({ 'p-error': isFormFieldValid('price') })}>Price *</label>
                                        </span>
                                        {getFormErrorMessage('price')}
                                    </div>
                                </>
                            :
                                <>
                                    <div className="field">
                                        <span className="p-float-label">
                                            <InputText id="upc" name="upc" value={formik.values.upc} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('upc') })} />
                                            <label htmlFor="upc" className={classNames({ 'p-error': isFormFieldValid('upc') })}>UPC *</label>
                                        </span>
                                        {getFormErrorMessage('upc')}
                                    </div>
                                    <div className="field">
                                        <span className="p-float-label">
                                            <InputNumber mode="decimal" maxFractionDigits={2} id="price" name="price" value={formik.values.price} onValueChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('price') })} />
                                            <label htmlFor="price" className={classNames({ 'p-error': isFormFieldValid('price') })}>Price *</label>
                                        </span>
                                        {getFormErrorMessage('price')}
                                    </div>
                                </>
                            }
                            <div className="field">
                                <span className="p-float-label">
                                    <InputNumber model="decimal" maxFractionDigits={2} id="quantity" name="quantity" value={formik.values.quantity} onValueChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('quantity') })} />
                                    <label htmlFor="quantity" className={classNames({ 'p-error': isFormFieldValid('quantity') })}>Quantity *</label>
                                </span>
                                {getFormErrorMessage('quantity')}
                            </div>
                            <div className="field">
                                <span className="p-float-label">
                                    <Dropdown id="discount" name="discount" value={formik.values.discount} onChange={formik.handleChange} options={discounts} optionLabel="code"  />
                                    <label htmlFor="discount" >Discount</label>
                                </span>
                            </div>
                            {formik.values.quantity ? 
                                <div className="field">Total: ${
                                    (formik.values.quantity * (formik.values.charge ? formik.values.charge.price : formik.values.price)) * 
                                    (formik.values.discount ? formik.values.discount.percentage ? 1 - (formik.values.discount.percentage / 100) : 1 : 1) - 
                                    (formik.values.discount ? formik.values.discount.amount ? formik.values.discount.amount : 0 : 0).toFixed(2)}</div>
                            :
                                <></>
                            }
                            <div className="field">
                                <Checkbox inputId="oneTime" name="oneTime" checked={formik.values.oneTime} onChange={formik.handleChange} tooltip="This will not create a SKU/UPC for this charge" tooltipOptions={{ position: 'top'}} />
                                <label style={{ marginLeft: 10 }} htmlFor="oneTime">Skip Charge Creation</label>
                            </div>
                            {formik.values.oneTime ?
                                <>
                                <div className="field">
                                    <Checkbox inputId="exempt" name="exempt" checked={formik.values.exempt} onChange={formik.handleChange} />
                                    <label style={{ marginLeft: 10 }} htmlFor="exempt">Tax Exempt?</label>
                                </div>
                                <div className="field">
                                    <span className="p-float-label">
                                        <InputText maxLength={200} id="description" name="description" value={formik.values.description} onChange={formik.handleChange} />
                                        <label htmlFor="description">Description</label>
                                    </span>
                                </div>
                                </>
                            :
                                <></>
                            }
                            <div className="flex-row justify-content-center mt20">
                                <Button style={{ width: '50%' }} type="submit" label="Add Line Item" loading={saving} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}