import * as React from 'react';
import { useAxios } from '../../utils/hooks.ts';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';

function CustomersCreateEdit({ customer, visible, hide, flipFetchFlag, displayError }) {
    // eslint-disable-next-line
    const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
    const axiosInstance = useAxios();

    const formik = useFormik({
        initialValues: {
            firstName: customer?.firstName || '',
            lastName: customer?.lastName || '',
            addressOne: customer?.addressOne || '',
            addressTwo: customer?.addressTwo || '',
            city: customer?.city || '',
            state: customer?.state || '',
            zip: customer?.zip || '',
            email: customer?.email || '',
            phone: customer?.phone || '',
            phoneAlt: customer?.phoneAlt || '',
            fax: customer?.fax || ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.firstName) {
                errors.firstName = 'First name is required.';
            }

            if (!data.lastName) {
                errors.lastName = 'Last name is required.';
            }

            // if (!data.email) {
            //     errors.email = 'Email is required.';
            // }
            else if (data.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Invalid email address. E.g. example@email.com';
            }

            // if (!data.phone) {
            //     errors.phone = 'Phone is required.'
            // } else if (!/[(]{1}[0-9]{3}[)]{1}-[0-9]{3}-[0-9]{4}/.test(data.phone)) {
            //     errors.phone = 'Must be in format (000)-000-0000';
            // }

            if (data.phoneAlt && !/[(]{1}[0-9]{3}[)]{1}-[0-9]{3}-[0-9]{4}/.test(data.phoneAlt)) {
                errors.phoneAlt = 'Must be in format (000)-000-0000';
            }

            if (data.fax && !/[(]{1}[0-9]{3}[)]{1}-[0-9]{3}-[0-9]{4}/.test(data.fax)) {
                errors.fax = 'Must be in format (000)-000-0000';
            }

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            data.customerId = customer?.customerId
            axiosInstance.current.post("/customers", data).then(() => {
                formik.resetForm();
            })
            .catch((err) => {
                console.error(err);
                displayError();
            })
            .finally(() => {
                flipFetchFlag();
                hide();
                setSaving(false);
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <Dialog header={customer ? 'Edit Customer' : 'Create Customer'} visible={visible} onHide={hide}>
            <div className="dialog">
            <div className="card">
                <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputText id="firstName" name="firstName" maxLength={45} value={formik.values.firstName} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('firstName') })} />
                            <label htmlFor="firstName" className={classNames({ 'p-error': isFormFieldValid('firstName') })}>First Name *</label>
                        </span>
                        {getFormErrorMessage('firstName')}
                    </div>
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputText id="lastName" name="lastName" maxLength={45} value={formik.values.lastName} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('lastName') })} />
                            <label htmlFor="lastName" className={classNames({ 'p-error': isFormFieldValid('lastName') })}>Last Name *</label>
                        </span>
                        {getFormErrorMessage('lastName')}
                    </div>
                    <div className="field m20">
                        <span className="p-float-label p-input-icon-right">
                            <i className="pi pi-envelope" />
                            <InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email') })} />
                            <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>Email</label>
                        </span>
                        {getFormErrorMessage('email')}
                    </div>
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputText id="addressOne" name="addressOne" maxLength={45} value={formik.values.addressOne} onChange={formik.handleChange} />
                            <label htmlFor="addressOne">Address Line 1</label>
                        </span>
                    </div>
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputText id="addressTwo" name="addressTwo" maxLength={45} value={formik.values.addressTwo} onChange={formik.handleChange} />
                            <label htmlFor="lastName">Address Line 2</label>
                        </span>
                    </div>
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputText id="city" name="city" maxLength={45} value={formik.values.city} onChange={formik.handleChange} />
                            <label htmlFor="city">City</label>
                        </span>
                    </div>
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputText id="state" name="state" maxLength={30} value={formik.values.state} onChange={formik.handleChange} />
                            <label htmlFor="state">State/Province</label>
                        </span>
                    </div>
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputText id="zip" name="zip" maxLength={10} value={formik.values.zip} onChange={formik.handleChange} />
                            <label htmlFor="zip">Zip</label>
                        </span>
                    </div>
                    <div className="field m20">
                        <span className="p-float-label p-input-icon-right">
                            <i className="pi pi-phone" />
                            <InputMask mask="(999)-999-9999" id="phone" name="phone" value={formik.values.phone} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('phone') })} />
                            <label htmlFor="phone" className={classNames({ 'p-error': isFormFieldValid('phone') })}>Primary Phone</label>
                        </span>
                        {getFormErrorMessage('phone')}
                    </div>
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputMask mask="(999)-999-9999" id="phoneAlt" name="phoneAlt" value={formik.values.phoneAlt} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('phoneAlt') })} />
                            <label htmlFor="phoneAlt" className={classNames({ 'p-error': isFormFieldValid('phoneAlt') })}>Alternate Phone</label>
                        </span>
                        {getFormErrorMessage('phoneAlt')}
                    </div>
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputMask mask="(999)-999-9999" id="fax" name="fax" value={formik.values.fax} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('fax') })} />
                            <label htmlFor="fax"  className={classNames({ 'p-error': isFormFieldValid('fax') })}>Fax</label>
                        </span>
                        {getFormErrorMessage('fax')}
                    </div>

                    <div className="flex-row center mt20">
                        {customer ?
                            <Button style={{ margin: 5, width: '50%' }} type="submit" label="Save Customer" loading={saving} icon="pi pi-save" />
                        :
                            <Button style={{ margin: 5, width: '50%' }} type="submit" label="Create Customer" loading={saving} icon="pi pi-save" />
                        }
                    </div>
                </form>
            </div>
            </div>
        </Dialog>
    )
}

export default CustomersCreateEdit;