import * as React from 'react';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { useFormik } from 'formik';
import { useAxios } from '../../utils/hooks.ts';
import { SettingsContext } from '../../../settings-context';
import { InputNumber } from 'primereact/inputnumber';

function LineItemEdit({ lineItem, visible, hide, flipFetchFlag }) {
    // eslint-disable-next-line
    const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
    const { settings } = React.useContext(SettingsContext);
	const axiosInstance = useAxios();

	const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
			quantity: lineItem ? lineItem.quantity : '',
            price: lineItem ? lineItem.charge.price : 0.00
        },
        validate: (data) => {
            let errors = {};

			if (!data.quantity) {
				errors.type = 'Quantity is required.';
			}

            if (!data.price) {
                errors.type = 'Price is required.';
            }
            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            setSaving(true);
            lineItem.quantity = data.quantity;
            lineItem.charge.price = data.price;
            axiosInstance.current.post("/lineitems", lineItem).then((response) => {
                formik.resetForm();
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setSaving(false);
                flipFetchFlag();
                hide();
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <Dialog header={lineItem?.charge.oneTime ? "Edit Line Item (One Time Charge)" : "Edit Line Item"} visible={visible} onHide={hide}>
            <div className="card">
                <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
                    <div className="field m20">SKU: {lineItem?.charge.sku}</div>
                    <div className="field m20">UPC: {lineItem?.charge.upc}</div>
                    {lineItem?.charge.oneTime ?
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputNumber mode="decimal" maxFractionDigits={2} id="price" name="price" value={formik.values.price} onValueChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('price') })} />
                            <label htmlFor="price" className={classNames({ 'p-error': isFormFieldValid('price') })}>Price *</label>
                        </span>
                        {getFormErrorMessage('price')}
                    </div>
                    :
                    <div className="field m20">Price: {lineItem?.charge.price}</div>
                    }
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputNumber mode="decimal" maxFractionDigits={2} id="quantity" name="quantity" value={formik.values.quantity} onValueChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('quantity') })} />
                            <label htmlFor="quantity" className={classNames({ 'p-error': isFormFieldValid('quantity') })}>Quantity *</label>
                        </span>
                        {getFormErrorMessage('quantity')}
                    </div>
                    <Divider />
                    <div className="field m20">Total: {settings.find(s => s.key === 'Currency')?.value}{formik.values.quantity * formik.values.price}</div>
                    <div className="flex-row end m20">
                        <Button style={{ margin: 5 }} type="submit" label="Save" loading={saving} />
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default LineItemEdit;