import { format, parseISO } from "date-fns";
import { Card } from "primereact/card";
import { Editor } from "primereact/editor";
import { Chips } from 'primereact/chips';
import { Button } from "primereact/button";
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';

const header = (
    <span className="ql-formats"></span>
)

export default function AppointmentSummary({ appointment, edit, deleteAppointment }) {
    const reject = () => {
    };

	const confirmDeleteAppointment = (e) => {
		confirmPopup({
			target: e.currentTarget,
			message: "Are you sure you want to delete this appointment?",
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: () => deleteAppointment(appointment),
			reject
		})
	}

    return (
        <>
        <ConfirmPopup />
        <Card title={appointment.title} subTitle={format(parseISO(appointment.scheduledTime), 'dd MMM, yyyy HH:mm aa')} style={{ marginRight: 20, marginTop: 20, width: 500 }}>
            <div>
                <Chips readOnly={true} value={appointment.attendees} separator=',' />
            </div>
            <div className="mt20">
                <Editor readOnly={true} value={appointment.notes} headerTemplate={header} />
            </div>
            <div className="flex-row between">
                <Button className="p-button-outlined" label="Edit" icon="pi pi-save" onClick={() => edit(appointment)}/>
                <Button className="p-button-outlined p-button-danger" label="Delete" icon="pi pi-trash" onClick={confirmDeleteAppointment} />
            </div>
        </Card>
        </>
    )
}