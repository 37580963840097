import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAxios } from '../../utils/hooks.ts';
import { useKeycloak } from "@react-keycloak/web";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEllipsisH, faArrowCircleRight, faExclamation } from '@fortawesome/pro-light-svg-icons';
import { parseISO, format } from 'date-fns';
import { Button } from 'primereact/button';
import { TabView } from 'primereact/tabview';
import { TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import Note from '../../components/tickets/Note';
import NoteCreate from '../../components/tickets/NoteCreate';
import ProgressWrapper from '../../components/ProgressWrapper';
import SelectDevice from '../../components/devices/SelectDevice';
import LineItemCreate from '../../components/lineitems/LineItemCreate';
import TicketEdit from '../../components/tickets/TicketEdit';
import { SettingsContext } from '../../../settings-context';
import './tickets.css';
import LineItemEdit from '../../components/lineitems/LineItemEdit';
import { statuses } from '../../utils/statuses.js';
import { FilterMatchMode } from 'primereact/api';
import ReactToPrint from 'react-to-print';
import { Barcode } from '../../components/tickets/Barcode';
import { sortLineItemFunction } from '../../utils/functions.js';
import AppointmentCreateEdit from '../../components/tickets/AppointmentCreateEdit.js';
import AppointmentSummary from '../../components/tickets/AppointmentSummary.js';

function TicketDetails() {
	const [ ticket, setTicket ] = React.useState();
	const [ activeIndex, setActiveIndex ] = React.useState();
	const [ engineers, setEngineers ] = React.useState([]);
	const [ selectedEngineer, setSelectedEngineer ] = React.useState();
	const [ editVisible, setEditVisible ] = React.useState(false);
	const [ fetchFlag, setFetchFlag ] = React.useState(false);
	const [ loading, setLoading ] = React.useState(true);
	const [ selectedLineItem, setSelectedLineItem ] = React.useState();
	const [ editLineItemVisible, setEditLineItemVisible ] = React.useState(false);
	const [ createNoteVisible, setCreateNoteVisible ] = React.useState(false);
	const [ selectDeviceVisible, setSelectDeviceVisible ] = React.useState(false);
	const [ lineItemCreateVisible, setLineItemCreateVisible ] = React.useState(false);
	const [ createAppointmentVisible, setCreateAppointmentVisible ] = React.useState(false);
	const [ selectedAppointment, setSelectedAppointment ] = React.useState(null);
	const [ selectedStatus, setSelectedStatus ] = React.useState();
	const { settings } = React.useContext(SettingsContext);
    // eslint-disable-next-line
	const [ lineItemFilters, setLineItemFilters ] = React.useState({
		'charge.sku': {value: null, matchMode: FilterMatchMode.CONTAINS},
		'charge.upc': {value: null, matchMode: FilterMatchMode.CONTAINS},
		'charge.description': {value: null, matchMode: FilterMatchMode.CONTAINS}
	})
	const { id } = useParams();
	const axiosInstance = useAxios();
	const navigate = useNavigate();
	const { keycloak } = useKeycloak();
	const op = React.useRef(null);
	const toast = React.useRef(null);
	const barcodeRef = React.useRef(null);

	const flipFetchFlag = () => {
		setFetchFlag(!fetchFlag);
	}

	const toggleEditVisible = () => {
		setEditVisible(!editVisible);
		flipFetchFlag();
	}

	const toggleCreateNoteVisible = () => {
		flipFetchFlag();
		setCreateNoteVisible(!createNoteVisible);
	}

	const toggleSelectDeviceVisible = () => {
		flipFetchFlag();
		setSelectDeviceVisible(!selectDeviceVisible);
	}

	const toggleLineItemCreateVisible = () => {
		flipFetchFlag();
		setLineItemCreateVisible(!lineItemCreateVisible);
	}

	const toggleEditLineItemVisible = () => {
		setEditLineItemVisible(!editLineItemVisible);
		flipFetchFlag();
	}

	const selectLineItem = (rowData) => {
		setSelectedLineItem(rowData);
		setEditLineItemVisible(true);
	}

	const toggleCreateAppointmentVisible = () => {
		setCreateAppointmentVisible(!createAppointmentVisible);
		setSelectedAppointment(null);
	}

	const editAppointment = (appointment) => {
		setSelectedAppointment(appointment);
		setCreateAppointmentVisible(true);
	}

	const deleteAppointment = (appointment) => {
		axiosInstance.current.delete("/appointments/" + appointment.appointmentId).then(() => {
			displayToast({ severity: 'success', summary: 'Deleted', detail: 'Appointment deleted succesfully.'});
		})
		.catch(() => {
			displayToast({ severity: 'error', summary: 'Error', detail: 'Unable to delete appointment. Please try again or contact support.'});
		})
		.finally(() => {
			flipFetchFlag();
		})
	}
	const changeStatus = (e) => {
		setLoading(true);
		setSelectedStatus(e.value);
		const change = {
			oldStatusDisplay: ticket.status,
			newStatusDisplay: e.value.text,
			ticketId: ticket.ticketId
		}
		axiosInstance.current.put("/tickets/update", change).then((response) => {
		})
		.catch((err) => {
			console.error(err);
		})
		.finally(() => {
			flipFetchFlag();
			setLoading(false);
		})
	}

	const handleAssign = () => {
		setLoading(true);
		ticket.assigned = keycloak.tokenParsed?.sub;
		axiosInstance.current.put("/tickets", ticket)
		.catch((err) => {
			displayToast({ severity: 'error', summary: 'Error', detail: 'Error while assigning new engineer. Please try again or contact support.'});
			console.error(err);
		})
		.finally(() => {
			flipFetchFlag();
			setLoading(false);
		})
	}

	const reject = () => {
    };

	const confirmDeleteTicket = (e) => {
		confirmPopup({
			target: e.currentTarget,
			message: "Are you sure you want to delete this ticket?",
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: deleteTicket,
			reject
		})
	}

	const emailEstimate = () => {
		axiosInstance.current.put("/tickets/estimate/" + ticket.ticketId).then(() => {
			displayToast({ severity: 'success', summary: 'Sent!', detail: 'Estimate sent to customer!'});
		})
		.catch(() => {
			displayToast({ severity: 'error', summary: 'Error!', detail: 'Unable to send estimate at this time. Please try again or contact support.'});
		})
		.finally(() => {
			flipFetchFlag();
		})
	}

	const confirmEmailEstimate = (e) => {
		confirmPopup({
			target: e.currentTarget,
			message: "Are you sure you want to email the customer this estimate?",
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-success',
			accept: () => emailEstimate(),
			reject
		})
	}

	const acceptDevice = (device) => {
		const data = {
			ticketDeviceId: null,
			ticketId: ticket.ticketId,
			deviceId: device.deviceId
		}
        axiosInstance.current.put("/devices/tickets/remove", data)
            .finally(() => {
                flipFetchFlag();
            })
    };

    const confirmDevice = (device, e) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to remove this device?",
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => acceptDevice(device),
            reject
        })
    }

	const deleteTicket = () => {
		axiosInstance.current.delete("/tickets/" + ticket.ticketId).then((response) => {
			navigate("/tickets");
		})
		.catch((err) => {
			displayToast({
				severity: 'error',
				summary: 'Unable to delete ticket! Please try again or contact support.',
				detail: err.response ? err.response.data : ''
			});
		})
	}

	const removeDeviceBody = (rowData) => {
        return <Button className="p-button-outlined p-button-danger" icon='pi pi-trash' label="Remove Device" onClick={(e) => confirmDevice(rowData, e)} />
    }

	const changeEngineer = (e) => {
		setLoading(true);
		ticket.assigned = e.value.engineerId;
		axiosInstance.current.put("/tickets", ticket).catch((err) => {
			displayToast({ severity: 'error', summary: 'Error', detail: err.response ? err.response.data : 'Please try again or contact support.'});
		})
		.finally(() => {
			setLoading(false);
			flipFetchFlag();
		})
	}

	const deleteChange = (id) => {
		axiosInstance.current.delete("/tickets/changes/" + id).finally(() => flipFetchFlag());
	}

	const statusOptionTemplate = (option) => {
		return (<div className={option.status}>{option.text}</div>)
	}

	const selectedStatusTemplate = (status) => {
		return (<div className={status.status}>{status.text}</div>)
	}
	
	const lineItemEditBody = (rowData) => {
		return <Button disabled={ticket.statusType === 'INVOICED'} className="p-button-outlined" icon='pi pi-pencil' onClick={() => selectLineItem(rowData)} />
	}

	const lineItemRemoveBody = (rowData) => {
		return <Button disabled={ticket.statusType === 'INVOICED'} className="p-button-outlined p-button-danger" icon='pi pi-trash' onClick={(e) => confirmDeleteLineItem(rowData, e)} />
	}

	const accept = (lineItem) => {
		axiosInstance.current.delete("/lineitems/" + lineItem.lineItemId)
		.catch((err) => {
			displayToast({
				severity: 'error',
				summary: 'Unable to delete line item!',
				detail: err.response ? err.response.data : 'Please try again or contact support.'
			})
		})
		.finally(() => {
			flipFetchFlag();
		})
    };

    const confirmDeleteLineItem = (lineItem, e) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete this line item?",
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => accept(lineItem),
            reject
        })
    }

	const viewReport = () => {
        window.open(window.API_URL + "/api/pdf/ticket/" + id + ".pdf");
    }

	const createInvoice = () => {
		axiosInstance.current.put("/tickets/" + id + "/invoice").then((response) => {
			displayToast({
				severity: 'success',
				summary: 'Invoice created!'
			});
		}).catch((err) => {
			displayToast({
				severity: 'error',
				summary: "Unable to create invoice!",
				detail: err.response ? err.response.data : 'Please try again or contact support.'
			});
		}).finally(() => {flipFetchFlag();})
	}

	const viewEstimate = () => {
		const templateEnabled = settings.find(s => s.key === 'Estimate_Template')?.enabled;
		if (templateEnabled) {
			axiosInstance.current.get("/tickets/" + id + "/web").then((response) => {
				window.open(response.data);
			})
		} else {
        	window.open(window.API_URL + "/api/pdf/estimate/" + id + ".pdf");
		}
    }

	const displayToast = (msg) => {
		toast.current.show(msg);
	}

	React.useEffect(() => {
		axiosInstance.current.get("/engineers/office").then((response) => {
			if (response.data) {
				let tempList = [];
				const unassigned = {
					fullName: 'Unassigned',
					engineerId: null
				}
				tempList.push(unassigned);
				response.data.forEach(e => {
					const tempEngineer = {
						fullName: e.fullName,
						engineerId: e.engineerId
					}
					tempList.push(tempEngineer);
				})
				setEngineers(tempList);
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	React.useEffect(() => {
		setLoading(true);
		axiosInstance.current.get("/tickets/" + id).then((response) => {
			if (response.data) {
				let t = response.data;
				t.lineItems = t.lineItems.sort(sortLineItemFunction);
				t.displayCreateTime = format(parseISO(t.createTime), "MMMM dd, yyyy h:mm a")
                t.assignedFullname = t.assigned ? t.assignedFullname : 'Unassigned';
				t.subtotal = 0;
				t.tax = 0;
				t.lineItems.forEach(i => {
					let amount = 0.00;
					if (i.discount) {
						amount = i.charge.price * i.quantity * (i.discount.percentage ? 1 - (i.discount.percentage / 100) : 1) - (i.discount.amount ? i.discount.amount : 0);
					} else {
						amount = i.charge.price * i.quantity;
					}
					i.subtotal = amount;
					if (!i.charge.exempt) {
						t.tax += Math.round((amount * (settings.find(s => s.key === 'Tax')?.value / 100) * 100) + Number.EPSILON) / 100 * 1.0;
					}
					t.subtotal += i.subtotal;
				})
				t.total = t.subtotal + t.tax;
				setTicket(t);
				setSelectedEngineer({ fullName: t.assignedFullname, engineerId: t.assigned });
				setSelectedStatus(statuses.find(s => s.text === t.status));
			}
		})
		.finally(() => {
			setLoading(false);
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchFlag]);

	return (
		<>
			<Toast ref={toast} />
            {loading ?
                <ProgressWrapper />
            :
                <div>
					<ConfirmPopup />
					<TicketEdit visible={editVisible} hide={toggleEditVisible} ticket={ticket} flipFetchFlag={flipFetchFlag} />
					<NoteCreate visible={createNoteVisible} hide={toggleCreateNoteVisible} ticket={ticket} />
					<LineItemCreate visible={lineItemCreateVisible} hide={toggleLineItemCreateVisible} ticket={ticket} displayToast={displayToast} />
					<LineItemEdit lineItem={selectedLineItem} visible={editLineItemVisible} hide={toggleEditLineItemVisible} flipFetchFlag={flipFetchFlag}/>
					<SelectDevice visible={selectDeviceVisible} hide={toggleSelectDeviceVisible} ticket={ticket} flipFetchFlag={flipFetchFlag}/>
					<AppointmentCreateEdit appointment={selectedAppointment} visible={createAppointmentVisible} hide={toggleCreateAppointmentVisible} ticket={ticket} flipFetchFlag={flipFetchFlag} currentTime={new Date()} />
                    <nav className="m20">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
                            <li className="breadcrumb-item" onClick={() => navigate("/tickets")}>Tickets</li>
                            <li className="breadcrumb-item active">{ticket.name}</li>
                        </ol>
                    </nav>
					<div className="flex-row between">
						{ticket.pos ?
						<div className="title">{ticket.name}</div>
						:
						<div className="title">{ticket.name}<span className="ml10" onClick={(e) => op.current.toggle(e)}><FontAwesomeIcon icon={faEllipsisH} /></span></div>
						}
						<OverlayPanel ref={op} id="options-overlay" className="options-overlaypane">
							<div className="flex-col start">
                                <Button label="Edit" className="p-button-text p-button-plain" onClick={toggleEditVisible}/>
								<Button label="View Report" className="p-button-text p-button-plain" onClick={viewReport} />
								{ticket.invoiceId === null && <Button label="Create Invoice" className="p-button-text p-button-plain" onClick={createInvoice} />}
								{ticket.invoiceId !== null && <Button label="View Invoice" className="p-button-text p-button-plain" onClick={() => navigate("/invoices/" + ticket.invoiceId)} />}
								{ticket.estimate && <Button label="View Estimate" className="p-button-text p-button-plain" onClick={viewEstimate} />}
								{ticket.estimate && <Button label="Email Estimate" className="p-button-text p-button-plain" onClick={confirmEmailEstimate} />}
								{ticket.status !== 'Invoiced' ?
									<Button label="Delete" className="p-button-text p-button-plain" onClick={confirmDeleteTicket} />
								:
									<></>
								}
                            </div>
						</OverlayPanel>
					</div>
					{ticket.pos ?
						<>
							<div className="secondary-title pointer">Invoiced: {ticket.invoiceId.substring(0, 8)}<span className="ml10" onClick={() => navigate("/invoices/" + ticket.invoiceId)}><FontAwesomeIcon icon={faArrowCircleRight} /></span></div>
						</>
					:
					<>
					{ticket.estimate && <div className="secondary-title pt20" style={{ color: 'var(--bs-green)', fontWeight: 'bold'}} ><FontAwesomeIcon icon={faExclamation} /> ESTIMATE <FontAwesomeIcon icon={faExclamation} /></div>}
					<div className="secondary-title text-primary">ID: {ticket.ticketId.substring(0,8)}</div>
					<div className="flex-row between wrap">
						<div className="secondary-title pointer" onClick={() => navigate("/customers/" + ticket.customerId)}>{ticket.customerName} <FontAwesomeIcon icon={faArrowCircleRight} /></div>
						<Dropdown style={{ margin: 10 }} options={statuses} optionLabel="text" value={selectedStatus} onChange={(e) => changeStatus(e)} itemTemplate={statusOptionTemplate} valueTemplate={selectedStatusTemplate}/>
					</div>
					<div className="flex-row between wrap">
						<div>
						{ticket.status === 'Invoiced' ?
							<div className="secondary-title pointer">Invoiced: {ticket.invoiceId.substring(0, 8)}<span className="ml10" onClick={() => navigate("/invoices/" + ticket.invoiceId)}><FontAwesomeIcon icon={faArrowCircleRight} /></span></div>
						:
							<></>
						}
						</div>
						<div>
							<Dropdown style={{ margin: 10 }} options={engineers} optionLabel="fullName" value={selectedEngineer} onChange={(e) => changeEngineer(e)} />
						</div>
					</div>
					{ticket.assigned !== keycloak.tokenParsed?.sub ?
						<Button label="Assign to me" className="p-button-text" onClick={handleAssign} />
					:
						<></>
					}
					</>
					}
					<div className="flex-row between wrap">
						<div className="tertiary-title">{ticket.description}</div>
					</div>
					<div className="mt30">
					{ticket.pos ?
						<TabView className="tabview-header-icon" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                            <TabPanel header="Line Items" leftIcon="pi pi-fw pi-money-bill">
                                {loading ?
                                    <ProgressSpinner />
                                :
                                    <>
										{ticket.status !== 'Invoiced' ?
                                        	<Button style={{ marginBottom: 10 }} className="p-button-outlined p-button-primary" label="Add Line Item" onClick={() => setLineItemCreateVisible(true)} />
										:
											<></>
										}
										<DataTable
											className="full-width"
											paginator
											rows={10}
											value={ticket.lineItems}
											responsiveLayout="stack"
											size="small"
											dataKey="lineItemId"
											emptyMessage="No line items created yet"
											filters={lineItemFilters}
											filterDisplay='row'
										>
											<Column field="charge.sku" header="SKU" sortable filter filterPlaceholder="Search by SKU"></Column>
											<Column field="charge.upc" header="UPC" sortable filter filterPlaceholder="Search by UPC"></Column>
											<Column field="charge.description" header="Description" sortable filter filterPlaceholder="Search by description"></Column>
											<Column field="charge.price" header={`Price (${settings.find(s => s.key === 'Currency')?.value})`} sortable></Column>
											<Column field="quantity" header="Quantity" sortable ></Column>
											<Column field="discount.code" header="Discount" sortable ></Column>
											<Column field="subtotal" header={`Subtotal (${settings.find(s => s.key === 'Currency')?.value})`} sortable></Column>
											<Column body={lineItemEditBody}></Column>
											<Column body={lineItemRemoveBody}></Column>
										</DataTable>
										<div className="flex-row end secondary-title">Subtotal: {settings.find(s => s.key === 'Currency')?.value}{ticket.subtotal.toFixed(2)}</div>
										<div className="flex-row end secondary-title">Tax ({settings.find(s => s.key === 'Tax')?.value}%): {settings.find(s => s.key === 'Currency')?.value}{ticket.tax.toFixed(2)}</div>
										<div className="flex-row end secondary-title mt30">Total: {settings.find(s => s.key === 'Currency')?.value}{ticket.total.toFixed(2)}</div>
                                    </>
                                }
                            </TabPanel>
							<TabPanel header="Barcode" leftIcon="pi pi-fw pi-tag">
								<ReactToPrint
									trigger={() => <Button className="p-button-text" label="Print barcode" />}
									content={() => barcodeRef.current}
								/>
								<Barcode ref={barcodeRef} barcode={ticket.barcode} height={settings.find(s => s.key === 'Barcode_Height')?.value} width={settings.find(s => s.key === 'Barcode_Width')?.value} />
							</TabPanel>
                        </TabView>
						:
                        <TabView className="tabview-header-icon" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
							<TabPanel header="Appointments" leftIcon="pi pi-fw pi-calendar">
								<Button style={{ marginBottom: 10 }} className="p-button-outlined" label="Create Appointment" onClick={toggleCreateAppointmentVisible} />
								{ticket.appointments.length > 0 ?
								<div className="flex-row start wrap">
									{ticket.appointments.map(a => (
										<AppointmentSummary key={a.appointmentId} appointment={a} edit={editAppointment} deleteAppointment={deleteAppointment}/>
									))}
								</div>
								:
								<>
								</>
								}
							</TabPanel>
                            <TabPanel header="Notes" leftIcon="pi pi-fw pi-comment">
								<Button style={{ marginBottom: 10 }} className="p-button-outlined p-button-primary" label="Create New Note" onClick={() => setCreateNoteVisible(true)}/>
								<div className="flex-row wrap">
									<div className="half-width pr20">
										{ticket.notes.length === 0 ?
											<div className="text-primary text-center">No notes yet</div>
										:
											<>
												<div className="text-primary text-center">Notes</div>
												{ticket.notes.map(n => (
													<Note key={n.noteId} note={n} flipFetchFlag={flipFetchFlag}/>
												))}
											</>
										}
									</div>
									<div className="half-width">
										{ticket.changes.length === 0 ?
											<div className="text-primary text-center">No updates yet</div>
										:
											<>
												<div className="text-primary text-center">Changes</div>
												{ticket.changes.map(c => (
													<div className="mt20" key={c.changeId}>
														<Card>
															<div className="flex-row between">
																<div>
																	<span className="audit-details">Changed from </span>
																	<span className={c.oldStatus}>{c.oldStatusDisplay}</span><span className="audit-details"> to </span>
																	<span className={c.newStatus}>{c.newStatusDisplay}</span><span className="audit-details"> by </span>
																	<span className="audit-details">{c.author}</span>
																	<div className="audit-details">on {format(parseISO(c.createTime), "MMMM dd, yyyy h:mm a")}</div>
																</div>
																<Button className="p-button-text p-button-danger" icon='pi pi-trash' onClick={() => deleteChange(c.changeId)} />
															</div>
														</Card>
													</div>
												))}
											</>
										}
									</div>
								</div>
                            </TabPanel>
                            <TabPanel header="Devices" leftIcon="pi pi-fw pi-server">
                                {loading ?
                                    <ProgressSpinner />
                                :
                                    <>
                                        <Button style={{ marginBottom: 10 }} className="p-button-outlined p-button-primary" label="Add Device" onClick={() => setSelectDeviceVisible(true)}/>
										<DataTable
											className="full-width"
											paginator
											rows={10}
											value={ticket.devices}
											responsiveLayout="stack"
											size="small"
											dataKey="deviceId"
										>
											<Column field="name" header="Name"></Column>
											<Column field="type" header="Type"></Column>
											<Column field="brand" header="Brand"></Column>
											<Column field="model" header="Model"></Column>
											<Column field="serialNumber" header="Serial Number"></Column>
											{/* <Column body={editDeviceBody}></Column> */}
											<Column body={removeDeviceBody}></Column>
										</DataTable>
                                    </>
                                }
                            </TabPanel>
                            <TabPanel header="Line Items" leftIcon="pi pi-fw pi-money-bill">
                                {loading ?
                                    <ProgressSpinner />
                                :
                                    <>
										{ticket.status !== 'Invoiced' ?
                                        	<Button style={{ marginBottom: 10 }} className="p-button-outlined p-button-primary" label="Add Line Item" onClick={() => setLineItemCreateVisible(true)} />
										:
											<></>
										}
										<DataTable
											className="full-width"
											paginator
											rows={10}
											value={ticket.lineItems}
											responsiveLayout="stack"
											size="small"
											dataKey="lineItemId"
											emptyMessage="No line items created yet"
											filters={lineItemFilters}
											filterDisplay='row'
										>
											<Column field="charge.sku" header="SKU" sortable filter filterPlaceholder="Search by SKU"></Column>
											<Column field="charge.upc" header="UPC" sortable filter filterPlaceholder="Search by UPC"></Column>
											<Column field="charge.description" header="Description" sortable filter filterPlaceholder="Search by description"></Column>
											<Column field="charge.price" header={`Price (${settings.find(s => s.key === 'Currency')?.value})`} sortable></Column>
											<Column field="quantity" header="Quantity" sortable ></Column>
											<Column field="discount.code" header="Discount" sortable ></Column>
											<Column field="subtotal" header={`Subtotal (${settings.find(s => s.key === 'Currency')?.value})`} sortable></Column>
											<Column body={lineItemEditBody}></Column>
											<Column body={lineItemRemoveBody}></Column>
										</DataTable>
										<div className="flex-row end secondary-title">Subtotal: {settings.find(s => s.key === 'Currency')?.value}{ticket.subtotal.toFixed(2)}</div>
										<div className="flex-row end secondary-title">Tax ({settings.find(s => s.key === 'Tax')?.value}%): {settings.find(s => s.key === 'Currency')?.value}{ticket.tax.toFixed(2)}</div>
										<div className="flex-row end secondary-title mt30">Total: {settings.find(s => s.key === 'Currency')?.value}{ticket.total.toFixed(2)}</div>
                                    </>
                                }
                            </TabPanel>
							<TabPanel header="Barcode" leftIcon="pi pi-fw pi-tag">
								<ReactToPrint
									trigger={() => <Button className="p-button-text" label="Print barcode" />}
									content={() => barcodeRef.current}
								/>
								<Barcode ref={barcodeRef} barcode={ticket.barcode} height={settings.find(s => s.key === 'Barcode_Height')?.value} width={settings.find(s => s.key === 'Barcode_Width')?.value} />
							</TabPanel>
                        </TabView>
					}
                    </div>
				</div>
			}
		</>
	)

}

export default TicketDetails;