import * as React from 'react';
import { addDays, differenceInCalendarDays, format, parseISO } from 'date-fns'; 
import { Chart } from 'primereact/chart';
import { SettingsContext } from '../../../settings-context';

export default function InvoiceChart({ fromDate, toDate, invoices }) {
    const [ chartData, setChartData ] = React.useState();
    const { settings } = React.useContext(SettingsContext);

    React.useMemo(() => {
        const numOfDays = differenceInCalendarDays(toDate, fromDate);
        let days = [];
        let invoicedAmounts = [];
        let payments = [];
        let dayInvoicedAmountMap = new Map();
        let dayPaymentMap = new Map();
        let dayPointer = fromDate;
        invoices.forEach(i => {
            let total = 0.00;
            const invoiceDate = format(parseISO(i.createTime), 'yyyy-MM-dd');
            let subtotal = 0.00;
            let tax = 0.00;
            i.tickets.forEach(t => {
                let ticketSubtotal = 0;
                t.lineItems.forEach(line => {
                    const amount = line.charge.price * line.quantity * (line.discount?.percentage ? 1 - (line.discount?.percentage / 100) : 1) - (line.discount?.amount ? line.discount?.amount : 0);
                    ticketSubtotal += amount;
                    if (!line.charge.exempt) {
                        tax += Math.round((amount * (settings.find(s => s.key === 'Tax')?.value / 100) * 100) + Number.EPSILON) / 100;
                    }
                })
                t.subtotal = ticketSubtotal.toFixed(2);
                subtotal += ticketSubtotal
            });
            i.payments.forEach(p => {
                const paymentDate = format(parseISO(p.createTime), 'yyyy-MM-dd');
                if (dayPaymentMap.get(paymentDate)) {
                    let amount = dayPaymentMap.get(paymentDate);
                    dayPaymentMap.set(paymentDate, amount + p.amount);
                } else {
                    dayPaymentMap.set(paymentDate, p.amount);
                }
            })
            total += subtotal + tax;
            if (dayInvoicedAmountMap.get(invoiceDate)) {
                let amount = dayInvoicedAmountMap.get(invoiceDate);
                dayInvoicedAmountMap.set(invoiceDate, amount += total);
            } else {
                dayInvoicedAmountMap.set(invoiceDate, total);
            }
        })
        for (let i = 1; i <= numOfDays; i++) {
            const day = format(dayPointer, 'yyyy-MM-dd');
            days = [...days, day];
            invoicedAmounts = [...invoicedAmounts, dayInvoicedAmountMap.get(day)];
            payments = [...payments, dayPaymentMap.get(day)];
            dayPointer = addDays(fromDate, i);
        }
        const chartData = {
            labels: days,
            datasets: [
                {
                    label: "Invoices",
                    backgroundColor: '#0d6efd',
                    data: invoicedAmounts
                },
                {
                    label: "Payments",
                    backgroundColor: '#fd7e14',
                    data: payments
                }
            ]
        }
        setChartData(chartData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoices]);

    return (
        <div>
            <Chart type="bar" data={chartData} />
        </div>
    )
}