import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../utils/hooks.ts';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { format, parseISO } from 'date-fns';
import { SettingsContext } from '../../../settings-context';
import { FilterMatchMode } from 'primereact/api';
import ProgressWrapper from '../../components/ProgressWrapper';

function Invoices() {
	const [ loading, setLoading ] = React.useState(false);
	const [ invoices, setInvoices ] = React.useState([]);
	const [ selectedInvoice, setSelectedInvoice ] = React.useState();
	const { settings } = React.useContext(SettingsContext);
    // eslint-disable-next-line
	const [ filters, setFilters ] = React.useState({
		'displayName': { value: null, matchMode: FilterMatchMode.CONTAINS},
		'customerName': {value: null, matchMode: FilterMatchMode.CONTAINS}
	})
	const axiosInstance = useAxios();
	const navigate = useNavigate();

	const selectInvoice = (e) => {
		setSelectedInvoice(e.value);
		navigate("/invoices/" + e.value.invoiceId);
	}

	const rowClass = (row) => {
		return {
			'pos-row': row.pos
		}
	}

	const sentBody = (rowData) => {
		return <div>{rowData.sent ? 'Yes' : 'No'}</div>
	}

	const paidBody = (rowData) => {
		return <div>{rowData.paid ? 'Yes' : 'No'}</div>
	}

	React.useEffect(() => {
		setLoading(true);
		axiosInstance.current.get("/invoices/office").then((response) => {
			if (response.data) {
				response.data.forEach(i => {
					i.displayName = i.invoiceId.substring(0, 8);
					i.createTimeDisplay = format(parseISO(i.createTime), "MMMM dd, yyyy h:mm a")
					let subtotal = 0;
					i.tickets.forEach(t => {
						t.lineItems.forEach(line => {
							subtotal += line.charge.price * line.quantity * (line.discount?.percentage ? 1 - (line.discount?.percentage / 100) : 1) - (line.discount?.amount ? line.discount?.amount : 0);
						})
					})
					i.subtotal = subtotal.toFixed(2);
				});
				setInvoices(response.data);
			}
		})
		.catch((err) => {
			if (err.response.status === 401) {
				navigate("/setup");
			}
		})
		.finally(() => {
			setLoading(false);
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	
	return (
		<div>
            <nav className="m20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item active">Invoices</li>
				</ol>
			</nav>
            <div className="title mt10">Invoices</div>
			<div className="mt10">
                <Button label="Start POS Invoice" className="p-button-outlined p-button-primary" icon='pi pi-plus' onClick={() => navigate("/point")} />
            </div>
			{loading ?
			<><ProgressWrapper /></>
			:
			<DataTable
				className="full-width"
				paginator
				rows={10}
				value={invoices}
				responsiveLayout="stack"
				size="small"
				selectionMode="single"
				selection={selectedInvoice}
				onSelectionChange={(e) => selectInvoice(e)}
				dataKey="invoiceId"
				filters={filters}
				filterDisplay='row'
				rowClassName={rowClass}
			>
				<Column field="displayName" header="ID" sortable filter filterPlaceholder="Search by ID"></Column>
				<Column field="customerName" header="Customer" filter filterPlaceholder="Search by customer name" showClear sortable></Column>
				<Column field="subtotal" header={`Subtotal (${settings.find(s => s.key === 'Currency')?.value})`} sortable></Column>
				<Column body={sentBody} header="Sent?" sortable></Column>
				<Column body={paidBody} header="Paid?" sortable></Column>
				<Column field="createTimeDisplay" header="Created At" sortable></Column>
			</DataTable>
			}
		</div>
	)
}

export default Invoices;