import * as React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import { useFormik } from 'formik';
import { useAxios } from '../../utils/hooks.ts';
import { Dialog } from 'primereact/dialog';

const paymentTypes = [
    "Cash",
    "Check",
    "Credit"
]

function CreatePayment({ invoice, visible, display, hide}) {
    // eslint-disable-next-line
    const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
	const axiosInstance = useAxios();

	const formik = useFormik({
        initialValues: {
            type: '',
            amount: '',
        },
        validate: (data) => {
            let errors = {};

            if (!data.amount) {
                errors.amount = "Amount is required."
            }

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            setSaving(true);
            data.invoiceId = invoice.invoiceId;
            axiosInstance.current.post("/payments", data).then((response) => {
                display(true);
            })
            .catch((err) => {
                console.error(err);
                display(false);
            })
            .finally(() => {
                formik.resetForm();
                setSaving(false);
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <Dialog header="Add Payment" visible={visible} onHide={hide}>        
            <div className="form">
                <div className="flex justify-content-center">
                    <div className="card">
                        <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
                            <div className="field">
                                <span className="p-float-label">
                                    <Dropdown id="type" name="type" value={formik.values.type} onChange={formik.handleChange} options={paymentTypes} filter showClear className={classNames({ 'p-invalid': isFormFieldValid('type') })} />
                                    <label htmlFor="type" className={classNames({ 'p-error': isFormFieldValid('type') })}>Type</label>
                                </span>
                                {getFormErrorMessage('type')}
                            </div>
                            <div className="field">
                                <span className="p-float-label">
                                    <InputNumber id="amount" name="amount" minFractionDigits={2} maxFractionDigits={2} value={formik.values.amount} onValueChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('amount') })} />
                                    <label htmlFor="amount" className={classNames({ 'p-error': isFormFieldValid('amount') })}>Amount *</label>
                                </span>
                                {getFormErrorMessage('amount')}
                            </div>
                            <div className="flex-row justify-content-center mt20">
                                <Button style={{ margin: 5 }} type="submit" label="Add Payment" icon="pi pi-save" loading={saving} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default CreatePayment;