import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';
import { TabPanel, TabView } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import InvoicesTab from '../../components/reports/InvoicesTab';
import CustomersTab from '../../components/reports/CustomersTab';
import ChargesTab from '../../components/reports/ChargesTab';
import IncomeTab from '../../components/reports/IncomeTab';

export default function Reports() {
    const navigate = useNavigate();
    const toast = React.useRef(null);
    

    return (
        <div>
            <Toast ref={toast} />
            <nav className="m20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item active">Reports</li>
				</ol>
			</nav>
            <div className="title mt10">Reports</div>
            <div>
                <TabView>
                    <TabPanel leftIcon="pi pi-fw pi-money-bill" header="Invoices">
                        <InvoicesTab toast={toast} />
                    </TabPanel>
                    <TabPanel leftIcon="pi pi-fw pi-users" header="Customers">
                        <CustomersTab toast={toast} />
                    </TabPanel>
                    <TabPanel leftIcon="pi pi-fw pi-list" header="Charges">
                        <ChargesTab toast={toast} />
                    </TabPanel>
                    <TabPanel leftIcon="pi pi-fw pi-calculator" header="Income Statement">
                        <IncomeTab toast={toast} />
                    </TabPanel>
                </TabView>
            </div>
        </div>
    )
}