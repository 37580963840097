import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useAxios } from '../../utils/hooks.ts';

function EditSetting({ setting, visible, hide }) {
	// eslint-disable-next-line
	const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
	const axiosInstance = useAxios();

	const formik = useFormik({
		enableReinitialize: true,
        initialValues: {
            value: (setting ? setting.value : ' '),
            enabled: (setting?.enabled)
        },
        validate: (data) => {
            let errors = {};

            if (setting?.value !== '' && !data.value) {
                errors.text = 'Value is required.';
            }
            if (!data.value || data.value === '') {data.value = ' '}

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            data.settingId = setting.settingId;
			data.key = setting.key;
            axiosInstance.current.post("/settings", data).then(() => {
                formik.resetForm();
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setSaving(false);
				hide();
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

	return (
		<Dialog header="Edit Setting" visible={visible} onHide={hide}>
			<div className="flex justify-content-center">
                <div className="card">
                    <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
                        {setting?.value === ' ' ?
                        <div className="field-checkbox m20">
                            <Checkbox inputId="enabled" name="enabled" checked={formik.values.enabled} onChange={formik.handleChange} />
                            <label style={{ marginLeft: 10 }} htmlFor="enabled">Enabled?</label>
                        </div>
                        :
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputText id="value" name="value" maxLength={300} value={formik.values.value} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('value') })} />
                                <label htmlFor="value" className={classNames({ 'p-error': isFormFieldValid('value') })}>Value *</label>
                            </span>
                            {getFormErrorMessage('value')}
                        </div>
                        }
                        <div className="flex-row justify-content-center mt20">
                            <Button style={{ margin: 10 }} type="submit" label="Save Setting" icon='pi pi-save' loading={saving}/>
                        </div>
                    </form>
                </div>
            </div>
		</Dialog>
	)

}

export default EditSetting;