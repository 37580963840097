import * as React from 'react';
import { useAxios } from '../../utils/hooks.ts';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { states } from '../../utils/states';

function OfficeCreateEdit({ office, visible, hide }) {
    // eslint-disable-next-line
    const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
    const axiosInstance = useAxios();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: office?.name || '',
            lastName: office?.lastName || '',
            addressOne: office?.addressOne || '',
            addressTwo: office?.addressTwo || '',
            city: office?.city || '',
            state: (office ? states.find(s => s.abbreviation === office.state) : ''),
            zip: office?.zip || '',
            email: office?.email || '',
            phone: office?.phone || '',
            phoneAlt: office?.phoneAlt || '',
            fax: office?.fax || ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'Name is required.';
            }

            // if (!data.email) {
            //     errors.email = 'Email is required.';
            // }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Invalid email address. E.g. example@email.com';
            }

            // if (!data.phone) {
            //     errors.phone = 'Phone is required.'
            // }

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            data.officeId = office?.officeId
            data.state = data.state?.abbreviation;
            axiosInstance.current.post("/offices", data).then(() => {
                formik.resetForm();
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                hide();
                setSaving(false);
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <Dialog header={office ? 'Edit Office' : 'Create Office'} visible={visible} onHide={hide}>
            <div className="card" style={{ minWidth: 500 }}>
                <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputText id="name" name="name" value={formik.values.name} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                            <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>First Name *</label>
                        </span>
                        {getFormErrorMessage('name')}
                    </div>
                    <div className="field m20">
                        <span className="p-float-label p-input-icon-right">
                            <i className="pi pi-envelope" />
                            <InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email') })} />
                            <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>Email</label>
                        </span>
                        {getFormErrorMessage('email')}
                    </div>
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputText id="addressOne" name="addressOne" value={formik.values.addressOne} onChange={formik.handleChange} />
                            <label htmlFor="addressOne">Address Line 1</label>
                        </span>
                    </div>
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputText id="addressTwo" name="addressTwo" value={formik.values.addressTwo} onChange={formik.handleChange} />
                            <label htmlFor="lastName">Address Line 2</label>
                        </span>
                    </div>
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputText id="city" name="city" value={formik.values.city} onChange={formik.handleChange} />
                            <label htmlFor="city">City</label>
                        </span>
                    </div>
                    <div className="field m20">
                        <span className="p-float-label">
                            <Dropdown id="state" name="state" value={formik.values.state} onChange={formik.handleChange} options={states} optionLabel="name" />
                            <label htmlFor="state">State</label>
                        </span>
                    </div>
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputText id="zip" name="zip" value={formik.values.zip} onChange={formik.handleChange} />
                            <label htmlFor="zip">Zip</label>
                        </span>
                    </div>
                    <div className="field m20">
                        <span className="p-float-label p-input-icon-right">
                            <i className="pi pi-phone" />
                            <InputMask mask="(999)-999-9999" id="phone" name="phone" value={formik.values.phone} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('phone') })} />
                            <label htmlFor="phone" className={classNames({ 'p-error': isFormFieldValid('phone') })}>Primary Phone</label>
                        </span>
                        {getFormErrorMessage('phone')}
                    </div>
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputMask mask="(999)-999-9999" id="phoneAlt" name="phoneAlt" value={formik.values.phoneAlt} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('phoneAlt') })} />
                            <label htmlFor="phoneAlt" className={classNames({ 'p-error': isFormFieldValid('phoneAlt') })}>Alternate Phone</label>
                        </span>
                        {getFormErrorMessage('phoneAlt')}
                    </div>
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputMask mask="(999)-999-9999" id="fax" name="fax" value={formik.values.fax} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('fax') })} />
                            <label htmlFor="fax"  className={classNames({ 'p-error': isFormFieldValid('fax') })}>Fax</label>
                        </span>
                        {getFormErrorMessage('fax')}
                    </div>

                    <div className="flex-row center mt20">
                        {office ?
                            <Button style={{ margin: 5, width: '50%' }} type="submit" label="Save Office" loading={saving} icon="pi pi-save" />
                        :
                            <Button style={{ margin: 5, width: '50%' }} type="submit" label="Create Office" loading={saving} icon="pi pi-save" />
                        }
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default OfficeCreateEdit;