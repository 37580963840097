import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../utils/hooks.ts';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { SettingsContext } from '../../../settings-context.js';
import InventoryCreateEdit from '../../components/inventory/InventoryCreateEdit.js';
import "./Inventory.css";
import { classNames } from 'primereact/utils';
import { Checkbox } from 'primereact/checkbox';
import OrderSidebar from '../../components/inventory/OrderSidebar.js';
import { Messages } from 'primereact/messages';
import ProgressWrapper from '../../components/ProgressWrapper';

export default function Inventory() {
    const [ loading, setLoading ] = React.useState(false);
    const [ sidebarVisible, setSidebarVisible ] = React.useState(false);
    const [ fetchFlag, setFetchFlag ] = React.useState(false);
    const [ charges, setCharges ] = React.useState([]);
    const [ filteredCharges, setFilteredCharges ] = React.useState(false);
    const [ showLowCounts, setShowLowCounts ] = React.useState(false);
    const [ selectedCharge, setSelectedCharge ] = React.useState(null);
    const [ createEditChargeVisible, setCreateEditChargeVisible ] = React.useState(false);
    const { settings } = React.useContext(SettingsContext);
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const msgs = React.useRef(null);

    const flipFetchFlag = () => {
        setFetchFlag(!fetchFlag);
        msgs.current.clear();
    }

    const toggleCreateEditChargeVisible = () => {
		flipFetchFlag();
		setCreateEditChargeVisible(!createEditChargeVisible);
		setSelectedCharge(null);
	}

    const viewOrderDetails = (charge) => {
        setSelectedCharge(charge);
        setSidebarVisible(true);
    }

    const toggleSidebar = () => {
        setSidebarVisible(false);
        setSelectedCharge(null);
        flipFetchFlag();
    }

    const onHandBody = (rowData) => {
		if (rowData.inventory != null) {
			return <div className={classNames({ 'low-count' : rowData.inventory.onHandCount < rowData.inventory.reorderThreshold})}>{rowData.inventory.onHandCount}</div>
		} else {
			return <div>N/A</div>
		}
	}

    const reorderThreshold = (rowData) => {
        if (rowData.inventory != null) {
            return <div>{rowData.inventory.reorderThreshold}</div>
        } else {
            return <div>NA</div>
        }
    }

	const editChargeBody = (rowData) => {
		return <Button className="p-button-text" label="View/Edit" icon="pi pi-pencil" onClick={() => {setSelectedCharge(rowData);setCreateEditChargeVisible(true);}} />
	}

    const viewOrderBody = (rowData) => {
        return <Button className="p-button-text" label="Order History" icon="pi pi-truck" onClick={() => viewOrderDetails(rowData)}/>
    }

    const toggleLowCounts = (e) => {
        setShowLowCounts(e.checked);
        if (e.checked) {
            setFilteredCharges(charges.filter(c => c.inventory.onHandCount < c.inventory.reorderThreshold));
        } else {
            setFilteredCharges(charges);
        }
    }

    React.useEffect(() => {
        setLoading(true);
        axiosInstance.current.get("/charges/inventory").then((response) => {
            setCharges(response.data);
            setFilteredCharges(response.data);
            response.data.forEach(c => {
                if (c.inventory.onHandCount < c.inventory.reorderThreshold) {
                    msgs.current.show({
                        severity: 'warn', sticky: true, content: (
                            <>
                                <div>Inventory for {c.description} ({c.sku}) is low and needs to be reordered!</div>
                                <Button className="p-button-text" label="Order History" onClick={() => viewOrderDetails(c)} />
                            </>
                        )
                    })
                }
            })
        }).finally(() => {
            setLoading(false);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFlag]);
    
    return (
        <>
        {loading ?
            <ProgressWrapper />
        :
        <div>
            <OrderSidebar charge={selectedCharge} visible={sidebarVisible} hide={toggleSidebar} flipFetchFlag={flipFetchFlag} />
            <InventoryCreateEdit charge={selectedCharge} visible={createEditChargeVisible} hide={toggleCreateEditChargeVisible} />
            <Messages ref={msgs} />
            <nav className="m20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item active">Inventory</li>
				</ol>
			</nav>
            <div className="flex-row between">
                <div className="title mt10">Inventory</div>
            </div>
            <div className="flex-row between mt10">
                <Button style={{ marginBottom: 10 }} label="Create New Charge With Inventory" className="p-button-outlined" icon='pi pi-plus' onClick={() => setCreateEditChargeVisible(true)}/>
            </div>
            <div className="flex0-row between mt10">
                <Checkbox inputId="lowcounts" name="lowcounts" checked={showLowCounts} onChange={toggleLowCounts} />
                <label style={{ marginLeft : 10 }} htmlFor="lowcounts">Show Low Counts Only</label>
            </div>
            <DataTable
                className="full-width"
                paginator
                rows={10}
                value={filteredCharges}
                responsiveLayout="stack"
                size="small"
                dataKey="chargeId"
                filterDisplay='row'
            >
                <Column field="sku" header="SKU" filter filterPlaceholder="Search by SKU" sortable></Column>
                <Column field="upc" header="UPC" filter filterPlaceholder="Search by UPC" sortable></Column>
                <Column field="price" header={`Price (${settings?.find(s => s.key === 'Currency')?.value})`} filter filterPlaceholder="Search by price" sortable></Column>
                <Column field="description" header="Description" filter filterPlaceholder="Search by description" sortable></Column>
                <Column body={onHandBody} header="On Hand"></Column>
                <Column body={reorderThreshold} header="Reorder Threshold"></Column>
                <Column body={editChargeBody}></Column>
                <Column body={viewOrderBody}></Column>
            </DataTable>
        </div>
        }
        </>
    )
}