import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useAxios } from '../../utils/hooks.ts';
import { Checkbox } from 'primereact/checkbox';

function InventoryCreateEdit({ charge, visible, hide }) {
    // eslint-disable-next-line
	const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
    const [ inventory, setInventory ] = React.useState(false);
	const axiosInstance = useAxios();

	const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            sku: charge ? charge.sku : '',
			upc: charge ? charge.upc : '',
			price: charge ? charge.price : 0.00,
			description: charge ? charge.description : '',
            exempt: charge ? charge.exempt : false,
            onHandCount: charge?.inventory ? charge.inventory.onHandCount : 0,
            onHandMeasure: charge?.inventory ? charge.inventory.onHandMeasurement : '',
            reorderThreshold: charge?.inventory ? charge.inventory.reorderThreshold : 0
        },
        validate: (data) => {
            let errors = {};

            if (!data.sku) {
                errors.sku = 'SKU is required.';
            }

			if (!data.price) {
				errors.price = 'Price is required.'
			}

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            data.chargeId = charge?.chargeId;
            data.active = charge?.active;
                const inventory = {
                    inventoryId: charge?.inventory.inventoryId,
                    onHandCount: data.onHandCount,
                    onHandMeasurement: data.onHandMeasure,
                    reorderThreshold: data.reorderThreshold
                }
                data.inventory = inventory;
            axiosInstance.current.post("/charges", data).then(() => {
                formik.resetForm();
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setSaving(false);
				hide();
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    React.useMemo(() => {
        setInventory(charge?.inventory != null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [charge]);

	return (
		<Dialog header={charge ? "View/Edit Charge With Inventory" : "Create Charge With Inventory"} visible={visible} onHide={hide}>
			<div className="flex justify-content-center">
                <div className="card">
                    <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputText maxLength={20} id="sku" name="sku" value={formik.values.sku} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('sku') })} />
                                <label htmlFor="sku" className={classNames({ 'p-error': isFormFieldValid('sku') })}>SKU *</label>
                            </span>
                            {getFormErrorMessage('sku')}
                        </div>
						<div className="field m20">
                            <span className="p-float-label">
                                <InputText maxLength={20} id="upc" name="upc" value={formik.values.upc} onChange={formik.handleChange} />
                                <label htmlFor="upc">UPC</label>
                            </span>
                        </div>
						<div className="field m20">
                            <span className="p-float-label">
                                <InputNumber mode="decimal" maxFractionDigits={2} id="price" name="price" value={formik.values.price} onValueChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('price') })} />
                                <label htmlFor="price" className={classNames({ 'p-error': isFormFieldValid('price') })}>Price *</label>
                            </span>
							{getFormErrorMessage('price')}
                        </div>
						<div className="field m20">
                            <span className="p-float-label">
                                <InputText maxLength={200} id="description" name="description" value={formik.values.description} onChange={formik.handleChange}/>
                                <label htmlFor="description">Description</label>
                            </span>
                        </div>
                        <div className="field-checkbox m20">
                            <Checkbox inputId="exempt" name="exempt" checked={formik.values.exempt} onChange={formik.handleChange} />
                            <label style={{ marginLeft: 10 }} htmlFor="exempt">Tax Exempt?</label>
                        </div>
                        <div className="field-checkbox m20">
                            <Checkbox inputId="inventory" name="inventory" checked={inventory} onChange={(e) => setInventory(e.checked)} />
                            <label style={{ marginLeft: 10 }} htmlFor="inventory">Inventoried Item?</label>
                        </div>
                        
                        {inventory ?
                            <>
                                <div className="field m20">
                                    <span className="p-float-label">
                                        <InputNumber min={0} id="onHandCount" name="onHandCount" value={formik.values.onHandCount} onValueChange={formik.handleChange} />
                                        <label htmlFor="onHandCount">On Hand Count</label>
                                    </span>
                                </div>
                                <div className="field m20">
                                    <span className="p-float-label">
                                        <InputNumber min={0} id="reorderThreshold" name="reorderThreshold" value={formik.values.reorderThreshold} onValueChange={formik.handleChange} />
                                        <label htmlFor="reorderThreshold">Reorder Threshold</label>
                                    </span>
                                </div>

                            </>
                        :
                            <></>
                        }

                        <div className="flex-row justify-content-center mt20">
                            {charge ? 
                                <Button style={{ margin: 10 }} type="submit" label="Save Charge" icon="pi pi-save" loading={saving} />
                            :
                                <Button style={{ margin: 10 }} type="submit" label="Create Charge" icon="pi pi-plus" loading={saving} />
                            }
                        </div>
                    </form>
                </div>
            </div>
		</Dialog>
	)
}

export default InventoryCreateEdit;