import * as React from 'react';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useAxios } from '../../utils/hooks.ts';
import ProgressWrapper from '../../components/ProgressWrapper';
import { states } from '../../utils/states';
import { useNavigate} from 'react-router-dom';

function Setup() {
    const [ loading, setLoading ] = React.useState(true);
    // eslint-disable-next-line
    const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
    const navigate = useNavigate();
	const axiosInstance = useAxios();

	const formik = useFormik({
        initialValues: {
            text: '',
        },
        validate: (data) => {
            let errors = {};

            if (!data.companyname) {
                errors.companyname = 'Company name is required.';
            }

            if (!data.name) {
                errors.name = 'Office name is required.';
            }

            if (!data.email) {
                errors.email = 'Email is required.';
            }

            if (!data.phone) {
                errors.phone = 'Primary phone is required.';
            }

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            data.state = data.state ? states.find(s => s.abbreviation === data.state) : "";
            const company = {
                name: data.companyname,
                offices: [
                    data
                ]
            }
            axiosInstance.current.post("/companies", company).then(() => {
                formik.resetForm();
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                navigate("/");
                setSaving(false);
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    React.useEffect(() => {
        setLoading(true);
        setLoading(false);
    }, [])

    return (
        <>
        {loading ?
            <ProgressWrapper />
        :
            <div>
                <div className="title">Setup New Company</div>
                <div className="form half-width">
                    <div className="flex justify-content-center">
                        <div className="card">
                            <form onSubmit={formik.handleSubmit} className="p-fluid">
                                <div className="field">
                                    <div className="secondary-title">Company Details</div>
                                </div>
                                <div className="field">
                                    <span className="p-float-label">
                                        <InputText id="companyname" name="companyname" value={formik.values.companyname} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('companyname') })} />
                                        <label htmlFor="companyname" className={classNames({ 'p-error': isFormFieldValid('name') })}>Company Name *</label>
                                    </span>
                                    {getFormErrorMessage('companyname')}
                                </div>
                                <div className="field">
                                    <div className="secondary-title">Office Details</div>
                                </div>
                                <div className="field">
                                    <span className="p-float-label">
                                        <InputText id="name" name="name" value={formik.values.name} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                                        <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>Office Name *</label>
                                    </span>
                                    {getFormErrorMessage('name')}
                                </div>
                                <div className="field m20">
                                    <span className="p-float-label p-input-icon-right">
                                        <i className="pi pi-envelope" />
                                        <InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email') })} />
                                        <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>Email *</label>
                                    </span>
                                    {getFormErrorMessage('email')}
                                </div>
                                <div className="field m20">
                                    <span className="p-float-label">
                                        <InputText id="addressOne" name="addressOne" value={formik.values.addressOne} onChange={formik.handleChange} />
                                        <label htmlFor="addressOne">Address Line 1</label>
                                    </span>
                                </div>
                                <div className="field m20">
                                    <span className="p-float-label">
                                        <InputText id="addressTwo" name="addressTwo" value={formik.values.addressTwo} onChange={formik.handleChange} />
                                        <label htmlFor="lastName">Address Line 2</label>
                                    </span>
                                </div>
                                <div className="field m20">
                                    <span className="p-float-label">
                                        <InputText id="city" name="city" value={formik.values.city} onChange={formik.handleChange} />
                                        <label htmlFor="city">City</label>
                                    </span>
                                </div>
                                <div className="field m20">
                                    <span className="p-float-label">
                                        <Dropdown id="state" name="state" value={formik.values.state} onChange={formik.handleChange} options={states} optionLabel="name" />
                                        <label htmlFor="state">State</label>
                                    </span>
                                </div>
                                <div className="field m20">
                                    <span className="p-float-label">
                                        <InputText id="zip" name="zip" value={formik.values.zip} onChange={formik.handleChange} />
                                        <label htmlFor="zip">Zip</label>
                                    </span>
                                </div>
                                <div className="field m20">
                                    <span className="p-float-label p-input-icon-right">
                                        <i className="pi pi-phone" />
                                        <InputMask mask="(999)-999-9999" id="phone" name="phone" value={formik.values.phone} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('phone') })} />
                                        <label htmlFor="phone" className={classNames({ 'p-error': isFormFieldValid('phone') })}>Primary Phone *</label>
                                    </span>
                                    {getFormErrorMessage('phone')}
                                </div>
                                <div className="field m20">
                                    <span className="p-float-label">
                                        <InputMask mask="(999)-999-9999" id="phoneAlt" name="phoneAlt" value={formik.values.phoneAlt} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('phoneAlt') })} />
                                        <label htmlFor="phoneAlt" className={classNames({ 'p-error': isFormFieldValid('phoneAlt') })}>Alternate Phone</label>
                                    </span>
                                    {getFormErrorMessage('phoneAlt')}
                                </div>
                                <div className="field m20">
                                    <span className="p-float-label">
                                        <InputMask mask="(999)-999-9999" id="fax" name="fax" value={formik.values.fax} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('fax') })} />
                                        <label htmlFor="fax"  className={classNames({ 'p-error': isFormFieldValid('fax') })}>Fax</label>
                                    </span>
                                    {getFormErrorMessage('fax')}
                                </div>
                                <div className="flex-row justify-content-center mt20">
                                    <Button style={{ width: '50%' }} type="submit" label="Create Company" loading={saving} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
        
    )
}

export default Setup;