import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { useAxios } from '../../utils/hooks.ts';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';

function TicketsCreate() {
    // eslint-disable-next-line
    const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
    const [ customers, setCustomers ] = React.useState([]);
    // eslint-disable-next-line
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const toast = React.useRef(null);

    const formik = useFormik({
        initialValues: {
            customer: '',
            name: '',
            description: '',
            estimate: false,
        },
        validate: (data) => {
            let errors = {};

            if (!data.customer) {
                errors.customer = 'Customer is required.';
            }
            if (!data.name) {
                errors.name = 'Name/Summary is required.';
            }
            if (!data.description) {
                errors.description = 'Description is required.';
            }

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            data.customerId = data.customer.customerId;
            axiosInstance.current.post("/tickets", data).then(() => {
                navigate("/tickets");
                formik.resetForm();
            })
            .catch((err) => {
                console.error(err);
                toast.current.show({severity: 'error', summary: 'Error!', detail: 'Unable to create ticket!'});
            })
            .finally(() => {
                setSaving(false);
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };


    React.useEffect(() => {
        axiosInstance.current.get("/customers").then((response) => {
            if (response.data) {
                response.data.filter(c => c.classification !== 'Blocked').forEach(c => c.fullName = c.firstName + ' ' + c.lastName);
                setCustomers(response.data);
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <Toast ref={toast} />
            <nav className="m20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item" onClick={() => navigate("/Tickets")}>Tickets</li>
                    <li className="breadcrumb-item active">Create Ticket</li>
				</ol>
			</nav>
            <div className="title mt10">Create New Ticket</div>
            <div className="form half-width">
                <div className="flex justify-content-center">
                    <div className="card">
                        <form onSubmit={formik.handleSubmit} className="p-fluid">
                            <div className="field">
                                <span className="p-float-label">
                                    <Dropdown id="customer" name="customer" value={formik.values.customer} onChange={formik.handleChange} options={customers} optionLabel="fullName" filter showClear filterBy="fullName" className={classNames({ 'p-invalid': isFormFieldValid('customer') })} />
                                    <label htmlFor="customer" className={classNames({ 'p-error': isFormFieldValid('customer') })}>Customer *</label>
                                </span>
                                {getFormErrorMessage('customer')}
                            </div>
                            <div className="field">
                                <span className="p-float-label">
                                    <InputText id="name" name="name" maxLength={45} value={formik.values.name} onChange={formik.handleChange} autoComplete="off" className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                                    <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>Name/Summary *</label>
                                </span>
                                {getFormErrorMessage('name')}
                            </div>
                            <div className="field">
                                <span className="p-float-label">
                                    <InputTextarea id="description" name="description" maxLength={500} rows={5} cols={30} value={formik.values.description} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('description') })} />
                                    <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') })}>Description *</label>
                                </span>
                                {getFormErrorMessage('description')}
                            </div>
                            <div className="field">
                                <Checkbox inputId="estimate" name="estimate" checked={formik.values.estimate} onChange={formik.handleChange} />
                                <label style={{ marginLeft: 10 }} htmlFor="estimate">Estimate?</label>
                            </div>
                            <div className="flex-row justify-content-center mt20">
                                <Button style={{ width: '50%'}} type="submit" label="Create Ticket" icon="pi pi-save" loading={saving}/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TicketsCreate;