import React from "react"

export const settings = {
	currency: '',
	tax: 0
}

export const SettingsContext = React.createContext({
	settings: settings,
	setSettings: () => {}
});