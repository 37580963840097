import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../utils/hooks.ts';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import ProgressWrapper from '../../components/ProgressWrapper';
import CustomersCreate from '../../components/customers/CustomerCreateEdit';
import { Toast } from 'primereact/toast';

function Customers() {
    const [ loading, setLoading ] = React.useState(true);
    const [ customers, setCustomers ] = React.useState([]);
    const [ selectedCustomer, setSelectedCustomer ] = React.useState();
    const [ createCustomerVisible, setCreateCustomerVisible ] = React.useState(false);
    const [ fetchFlag, setFetchFlag ] = React.useState(false);
    // eslint-disable-next-line
    const [ filters, setFilters ] = React.useState({
        'global': {value : null, matchMode: FilterMatchMode.CONTAINS},
        'shortID': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'firstName': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'lastName': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'email': {value: null, matchMode: FilterMatchMode.CONTAINS} 
    })
    const axiosInstance = useAxios();
    const navigate = useNavigate();
    const toast = React.useRef(null);

    const flipFetchFlag = () => {
        setFetchFlag(!fetchFlag);
    }

    const toggleCreateCustomerVisible = () => {
        setCreateCustomerVisible(!createCustomerVisible);
    }

    const selectCustomer = (e) => {
        setSelectedCustomer(e.value);
        navigate("/customers/" + e.value.customerId);
    }

    const displayError = () => {
        toast.current.show({severity: 'error', summary: 'Error!', detail: 'Unable to save customer!'});
    }

    const classificationBody = (rowData) => {
        if (rowData.classification !== 'None') {
            return <div className={rowData.classification}>{rowData.classification}</div>
        }
    }

    React.useEffect(() => {
        setLoading(true);
        axiosInstance.current.get("/customers").then((response) => {
            if (response.data) {
                response.data.forEach(c => c.shortID = c.customerId.substring(0,8));
                setCustomers(response.data);
            }
        })
        .catch((err) => {
            if (err.response.status === 401) {
                navigate("/setup");
            }
        })
        .finally(() => {
            setLoading(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFlag])

    return (
        <div>
            <CustomersCreate visible={createCustomerVisible} hide={toggleCreateCustomerVisible} flipFetchFlag={flipFetchFlag} displayError={displayError}/>
            <Toast ref={toast} />
            <nav className="m20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item active">Customers</li>
				</ol>
			</nav>
            <div className="flex-row between">
                <div className="title mt10">Customers</div>
            </div>
            <div className="flex-row between mt10">
                <Button label="Create Customer" className="p-button-outlined p-button-primary" icon='pi pi-plus' onClick={toggleCreateCustomerVisible} />
                {/* <Button className="p-button-outlined" label="Export" icon='pi pi-download' onClick={downloadCustomerList} /> */}
            </div>
            {loading ?
                <ProgressWrapper />
            :
                <>
                    <DataTable
                        className="full-width"
                        paginator
                        rows={10}
                        value={customers}
                        responsiveLayout="stack"
                        size="small"
                        selectionMode="single"
                        selection={selectedCustomer}
                        onSelectionChange={(e) => selectCustomer(e)}
                        dataKey="customerId"
                        filters={filters}
                        filterDisplay='row'
                        globalFilterFields={['firstName', 'lastName', 'email']}
                    >
                        <Column field="shortID" header="ID" filter filterPlaceholder="Search by ID" sortable></Column>
                        <Column body={classificationBody}></Column>
                        <Column field="firstName" header="First Name" filter filterPlaceholder="Search by first name" sortable></Column>
                        <Column field="lastName" header="Last Name" filter filterPlaceholder="Search by last name" sortable></Column>
                        <Column field="email" header="Email" filter filterPlaceholder="Search by email" sortable></Column>
                        <Column field="phone" header="Phone"></Column>
                        <Column field="phoneAlt" header="Alternate Phone"></Column>
                    </DataTable>
                </>
            }
        </div>
    )
}

export default Customers;