import { Link } from "react-router-dom";

function Footer() {
	return (
		<div className="flex-row around align-center p10 footer" style={{ backgroundColor: '#ecf0f1' }}>
			<div className="flex-col audit-details">
				
				<div>
					Need support? admin@new-labs.co
				</div>
				<div>
					<a className="no-link" href="https://newlabs.statuspage.io" target="_blank" rel="noreferrer">New Labs System Status</a>
				</div>
				<div>
					Discord: https://discord.gg/rDZSCJgMRC
				</div>
				<div>Version {window.VERSION} Build {window.BUILD}</div>
			</div>
			<div className="audit-details">
				<div>
					New Labs
				</div>
				<div>
					<Link className="no-link" to="/about">Dev Notes</Link>
				</div>
				<div>
					Copyright 2022
				</div>
			</div>
		</div>
	)
}

export default Footer;