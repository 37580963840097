import * as React from 'react';
import { useAxios } from '../../utils/hooks.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';
import ProgressWrapper from '../../components/ProgressWrapper';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

function Upgrade() {
    const [ loading, setLoading ] = React.useState(true);
    const [ company, setCompany ] = React.useState({});
    const navigate = useNavigate();
    const axiosInstance = useAxios();

    React.useEffect(() => {
        axiosInstance.current.get("/companies").then((response) => {
            if (response.data) {
                setCompany(response.data);
            }
        })
        .finally(() => {
            setLoading(false);
        })
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <>
        {loading ?
            <ProgressWrapper />
        :
        <>
            <nav className="m20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item" onClick={() => navigate("/company")}>Company</li>
                    <li className="breadcrumb-item active">Upgrade Subscription</li>
				</ol>
			</nav>
            <div className="title mt20">Upgrade Subscription</div>
            <div className="secondary-title">Your current subscription: {company.subscription}</div>
            <div className="flex-row around wrap">
                <div className={`${company.subscription === 'Basic' || company.subscription === 'Premium' || company.subscription === 'Enterprise' ? 'no-display' : ''}`}>
                    <Card>
                        <form action={`${window.API_URL}/api/upgrade/basic`} method="POST">
                            <Button type="submit">Upgrade to Basic</Button>
                        </form>
                    </Card>
                </div>
                <div className={`${company.subscription === 'Premium' || company.subscription === 'Enterprise' ? 'no-display' : ''}`}>
                    <Card>
                        <form action={`${window.API_URL}/api/upgrade/premium/${company.companyId}`} method="POST">
                            <Button type="submit">Upgrade to Premium</Button>
                        </form>
                    </Card>
                </div>
                <div className={`${company.subscription === 'Enterprise'? 'no-display' : ''}`}>
                    <Card>
                        <form action={`${window.API_URL}/api/upgrade/enterprise/${company.companyId}`} method="POST">
                            <Button type="submit">Upgrade to Enterprise</Button>
                        </form>
                    </Card>
                </div>
            </div>
        </>
        }
        </>
    )
}

export default Upgrade;