import * as React from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import "../../pages/inventory/Inventory.css";
import { Button } from 'primereact/button';
import OrderCreate from './OrderCreate';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { format, parseISO } from 'date-fns';
import OrderReceive from './OrderReceive';
import { useAxios } from '../../utils/hooks.ts';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';

export default function OrderSidebar({ charge, visible, hide}) {
    const [ createEditOrderVisible, setCreateEditOrderVisible ] = React.useState(false);
    const [ receiveVisible, setReceiveVisible ] = React.useState(false);
    const [ selectedOrder, setSelectedOrder ] = React.useState();
    const [ orders, setOrders ] = React.useState([]);
    const [ fetchFlag, setFetchFlag ] = React.useState(false);
    const [ onHand, setOnHand ] = React.useState(0);
    const axiosInstance = useAxios();

    const flipFetchFlag = () => {
        setFetchFlag(!fetchFlag);
    }

    const toggleCreateEditOrder = () => {
        setCreateEditOrderVisible(!createEditOrderVisible);
        setSelectedOrder(null);
        flipFetchFlag();
    }

    const toggleReceiveVisible = () => {
        setReceiveVisible(!receiveVisible);
        flipFetchFlag();
    }

    const selectOrder = (rowData) => {
        setSelectedOrder(rowData);
        setCreateEditOrderVisible(true);
    }

    const receiveOrder = (rowData) => {
        setSelectedOrder(rowData);
        setReceiveVisible(true);
    }

    const deleteOrder = (id) => {
        axiosInstance.current.delete("/inventory/order/" + id).catch((err) => {
            console.log(err);
        })
        .finally(() => {
            flipFetchFlag();
        })
    }

    const editOrderBody = (rowData) => {
        if (!rowData.received) {
            return <Button className="p-button-text" label="View/Edit" icon="pi pi-pencil" onClick={() => selectOrder(rowData)} />
        }
    }

    const receiveOrderBody = (rowData) => {
        if (!rowData.received) {
            return <Button className="p-button-text" label="Receive Order" icon="pi pi-check" onClick={() => receiveOrder(rowData)} />
        } else {
            return <div className={classNames({ 'p-error' : rowData.receivedCount < rowData.orderCount})}>{rowData.receivedCount}</div>
        }
    }

    const deleteOrderBody = (rowData) => {
        if (!rowData.received) {
            return <Button className="p-button-text p-button-danger" icon="pi pi-trash" onClick={(e) => confirmDeleteOrder(rowData, e)} />
        }
    }

    const confirmDeleteOrder = (order, e) => {
        confirmPopup({
			target: e.currentTarget,
			message: "Are you sure you want to delete this order?",
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: () => deleteOrder(order.inventoryOrderId),
			reject
		})
    }

    const reject = () => {}

    React.useEffect(() => {
        if (!charge) return;
        console.log('effecting');
        axiosInstance.current.get("/charges/" + charge.chargeId).then((response) => {
            console.log(response.data);
            response.data.inventory.orders.forEach(o => {
                const displayTime = format(parseISO(o.createTime), "MMMM dd, yyyy h:mm a");
                o.displayTime = displayTime;
            })
            setOrders([...response.data.inventory.orders]);
            setOnHand(response.data.inventory.onHandCount);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [charge, fetchFlag])

    return (
        <>
        <OrderCreate order={selectedOrder} inventoryId={charge?.inventory.inventoryId} visible={createEditOrderVisible} hide={toggleCreateEditOrder} />
        <OrderReceive order={selectedOrder} visible={receiveVisible} hide={toggleReceiveVisible} />
        <ConfirmPopup />
        <Sidebar visible={visible} onHide={hide} position="right" className="p-sidebar-lg">
            <div className="title">{charge?.description}</div>
            <div className="secondary-title">SKU: {charge?.sku}</div>
            <div className="secondary-title">UPC: {charge?.upc}</div>
            <Divider />
            <div className={classNames({"flex-row full-width": true, "low-count" : charge?.inventory.onHandCount < charge?.inventory.reorderThreshold})}>
                <div>On Hand:</div>
                <div className="ml10">{onHand}</div>
            </div>
            <div className="flex-row full-width">
                <div>Reorder Threshold:</div>
                <div className="ml10">{charge?.inventory.reorderThreshold}</div>
            </div>
            <Divider />
            <div>
                <Button label="Enter New Order" className="p-button-outlined" onClick={toggleCreateEditOrder}/>
            </div>
            <Divider />
            <div className="secondary-title mt10">Order History</div>
            {orders.length > 0 ?
            <>
                <DataTable
                    className="full-width"
                    paginator
                    rows={10}
                    value={orders}
                    responsiveLayout="stack"
                    size="small"
                    dataKey="inventoryOrderId"
                >
                    <Column field="orderCount" header="Count"></Column>
                    <Column field="orderPrice" header="Price"></Column>
                    <Column field="orderLink" header="Link"></Column>
                    <Column field="displayTime" header="Created At"></Column>
                    <Column body={receiveOrderBody} header="Received"></Column>
                    <Column body={editOrderBody}></Column>
                    <Column body={deleteOrderBody}></Column>
                </DataTable>
            </>
            :
            <>
                <div>No Orders Yet</div>
            </>
            }
        </Sidebar>
        </>
    )
}