import * as React from 'react';
import { Chart } from 'primereact/chart';
import { SettingsContext } from '../../../settings-context';
import { getInvoiceTotal } from '../../services/InvoiceService';

const backgroundColorSelection = ["#55efc4", "#ffeaa7", "#fab1a0", "#81ecec", "#ff7675", "#00cec9", "#74b9ff", "#fdcb6e", "#0984e3", "#d63031"]

export default function CustomerChart({ customers }) {
    const [ chartData, setChartData ] = React.useState({});
    const { settings } = React.useContext(SettingsContext);

    React.useMemo(() => {
        
        const backgroundColors = [];
        const customerList = [];
        const totals = [];
        let index = 0;
        customers.forEach(c => {
            backgroundColors.push(backgroundColorSelection[index % 10]);
            let total = 0.00;
            if (c.invoices) {
                c.invoices.forEach(i => {
                    total += getInvoiceTotal(i, settings.find(s => s.key === 'Tax')?.value);
                });
            }
            if (total > 0) {
                totals.push(total.toFixed(2));
                customerList.push(c.firstName + ' ' + c.lastName);
            }
            index++;
        })

        const data = {
            labels: customerList,
            datasets: [
                {
                    data: totals,
                    backgroundColor: backgroundColors
                }
            ]
        }

        setChartData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customers])

    return (
        <div>
            <div className="flex-row center">
                <div className="secondary-title">Customers by Total Invoices ({settings.find(s=> s.key === 'Currency')?.value})</div>
            </div>
            <div className="flex-row center">
                <Chart type="polarArea" data={chartData} style={{ width: '40%'}}/>
            </div>
        </div>
    )
}