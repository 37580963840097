import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';
import { TabPanel, TabView } from 'primereact/tabview';
import { Button } from 'primereact/button';

export default function Help() {
    const navigate = useNavigate();

    return (
        <div>
            <nav className="m20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item active">Help</li>
				</ol>
			</nav>
            <div className="title mt10">Help</div>
            <div className="mt20">
                <div className="audit-details">New Labs System Status</div>
                <statuspage-widget src="https://newlabs.statuspage.io"></statuspage-widget>
            </div>
            <div>
                <div className="secondary-title mt10">
                    Contact Support
                </div>
                <div className="mt10">
                    <div>Email:</div>
                    <div>admin@new-labs.co</div>
                </div>
                <div className="mt10">
                    <div>Discord:</div>
                    <div>https://discord.gg/rDZSCJgMR</div>
                </div>
            </div>
            <div>
                <div className="secondary-title mt20">Help Articles</div>
                <TabView>
                    <TabPanel header="Invoice/Estimate Templates">
                        <div style={{ width: '50%'}}>
                            <div className="secondary-title text-primary">Overview</div>
                            <p>You can customize the invoices and templates that are sent out via email or downloads by uploading an HTML document as an invoice or estimate template.
                                The templates allow you to use your own text sizes, colors, and placement of information on your invoices and estimates. You can use HTML and CSS
                                styles in order to build out your custom invoices. By using placeholders for data, the system will replace the placeholder text with the corresponding data
                                from the database; the style of the data is up to you.
                            </p>
                            <div className="secondary-title text-primary">Uploading Templates</div>
                            <p>
                                You can upload your html templates on the company details page. These templates will be stored in an S3 bucket in the cloud and retrieved by the system
                                when an invoice or estimate has been requested.
                            </p>
                            <Button className="p-button-text" label="Company Details" onClick={() => navigate("/company")} />
                            <div className="secondary-title text-primary">HTML and styling</div>
                            <p>
                                Invoices and estimates will generate full-fledged web pages so the HTML and CSS styling used by you will be rendered as intended given the browser support used
                                to view the invoices or estimates. The invoices and estimates can be printed from the browser or converted to PDF using extensions available for download.
                            </p>
                            <p>
                                There is no limit to the HTML tags or CSS styles for you to use in designing your invoices and estimates. Example templates are provided for your reference.
                            </p>
                            <div className="secondary-title text-primary">Placeholders</div>
                            <p>
                                Placeholders in your html template will allow the system to place data where you want. They are indentified by a pair of braces surrounding the
                                data you want to be displayed. For example, if you want the customer's first name to be displayed, the html template would require
                                &#123;&#123; customer_first_name &#125;&#125; in place of the desired destination. The following data points are supported:
                                <div className="audit-details">Customer</div>
                                <ul>
                                    <li>customer_first_name</li>
                                    <li>customer_last_name</li>
                                    <li>customer_address_one</li>
                                    <li>customer_address_two</li>
                                    <li>customer_city</li>
                                    <li>customer_state</li>
                                    <li>customer_zip</li>
                                    <li>customer_phone</li>
                                    <li>customer_email</li>
                                </ul>
                                <div className="audit-details">Office</div>
                                <ul>
                                    <li>office_name</li>
                                    <li>office_address_one</li>
                                    <li>office_address_two</li>
                                    <li>office_city</li>
                                    <li>office_state</li>
                                    <li>office_zip</li>
                                    <li>office_phone</li>
                                    <li>office_email</li>
                                    <li>logo_url <span className="audit-details">Note: Logos are stored in S3 bucket. This value will be replaced with the URL of the object in storage.</span></li>
                                </ul>
                                <div className="audit-details">Invoice</div>
                                <ul>
                                    <li>invoice_id</li>
                                    <li>invoice_date</li>
                                    <li>invoice_due_date</li>
                                    <li>invoice_subtotal</li>
                                    <li>invoice_tax</li>
                                    <li>invoice_total</li>
                                    <li>invoice_due <span className="audit-details">Note: This value is subtotal + tax - payments</span></li>
                                </ul>
                                <div className="audit-details">Estimates</div>
                                <ul>
                                    <li>ticket_id</li>
                                    <li>ticket_date</li>
                                    <li>ticket_subtotal</li>
                                    <li>ticket_tax</li>
                                    <li>ticket_total</li>
                                </ul>
                            </p>
                            <div className="secondary-title text-primary">Line Items</div>
                            <p>
                                Since an invoice or estimate will have multiple line items associated with them, the system will generate the HTML and styling that is needed to display the table of
                                line items and payments. Use the placeholder &#123;&#123; line_item_table &#125;&#125; to denote where you want the line item table to be displayed on the invoice or estimate.
                            </p>
                            <div className="secondary-title text-primary">Enable Templating</div>
                            <p>
                                By default, the templates will be disabled for invoices and estimates and the system will use the generic one that has already been designed. In order to opt-in and enable
                                the templates to be used, there is an office setting for both invoices and estimates. If you enable the "Invoice Templates" setting on the Office Settings page, then each invoice
                                generated by the system (View, Download, and Email) will use the template. Likewise, if you enable the "Estimate Templates" setting on the Office Settings page, then each estimate
                                generated by the system will use the template.
                            </p>
                            <Button className="p-button-text" label="Office Settings" onClick={() => navigate("/settings")} />
                            <div className="secondary-title text-primary">Tips</div>
                            <p>
                                Debugging the design process is as simple as creating an HTML file on your system and then viewing that file using your browser of choice. Start with a small table and then 
                                grow the design from there. Make sure that all of your tags have a matching closing tag; mismatched tags is the main reason for errors. If you continue to
                                have problems, contact support for help.
                            </p>
                            <div className="secondary-title text-primary">Example Template</div>
                            <p>
                                You can download and view an example HTML template <a href="https://newlabs.us-southeast-1.linodeobjects.com/example_template.html">here.</a>
                            </p>
                        </div>
                    </TabPanel>
                    <TabPanel header="Inventory System">
                        <div className="half-width">
                            <div className="secondary-title text-primary">Overview</div>
                            <p>Charges can be assigned an inventory on hand count and reorder threshold. These can be added through the Charges tab in the company settings or through the Inventory menu. The on
                                hand count is the count that is physically present and the reorder threshold is the limit at which a notification will be created that a new order should be submitted.
                            </p>
                            <div className="secondary-title text-primary">Orders</div>
                            <p>Inventory orders can be tracked through the individual charges in the Inventory menu. Orders can be recorded in the information panel when viewing Order History for a charge. Orders that
                                have been created but not closed out can be edited or deleted. Once an order has been received, it can no longer be adjusted. Receiving an order will adjust the on hand count for the inventory
                                item.
                            </p>
                            <div className="secondary-title text-primary">Order Price</div>
                            <p>The order price is used to track expenses for inventory. This should include the cost of the items and the shipping related to the order. On the Income Statement report, the expenses will be listed
                                from the orders and the revenue is listed from the line items from each invoice for the given date range.
                            </p>
                            <div className="secondary-title text-primary">Income Statement</div>
                            <p>A new report is now available: Income Statement. The Income Statement report lists Revenue as the combined invoiced line items for the given date range. The Costs of Goods Sold is the combined
                                orders for the given date range. <span className="audit-details">Note: Cost of Goods Sold should be the difference between the beginning inventory and ending inventory for the given period.
                                This will be a future enhancement to track inventory more closely.</span>
                            </p>
                            <div className="secondary-title text-primary">Non-inventory Items</div>
                            <p>Calculating the cost for non-inventory items is done by using the Margin setting in the Office Settings menu. For example, if the margin is 25% then a non-inventory item that has a price of $100
                                will have a cost of $75 and a profit margin of $25 for purpose of the Income Statement. This is done because the item that is billed on the invoice has some cost associated with it and can be removed from profit. Assuming the company
                                charges $100/hr for an engineer's time, the margin would be the cost of the engineer which in the example would be $75/hr. The remaining $25/hr is profit.
                            </p>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    )
}