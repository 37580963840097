import * as React from 'react';
import { useAxios } from '../../utils/hooks.ts';
import ProgressWrapper from '../ProgressWrapper';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { subMonths, addDays, format } from 'date-fns';
import ChargesChart from './ChargesChart.js';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SettingsContext } from '../../../settings-context.js';

export default function ChargesTab({ toast }) {
    const [ loading, setLoading ] = React.useState(false);
    const [ charges, setCharges ] = React.useState([]);
    const [ chargeList, setChargeList ] = React.useState([]);
    const [ fromDate, setFromDate ] = React.useState();
    const [ toDate, setToDate ] = React.useState();
    const { settings } = React.useContext(SettingsContext);
    const axiosInstance = useAxios();

    const chargeExemptBody = (rowData) => {
		return <div className="audit-details">{rowData.exempt ? 'Yes' : 'No'}</div>
	}

	const chargeLaborBody = (rowData) => {
		return <div className="audit-details">{rowData.labor ? 'Yes' : 'No'}</div>
	}

    const getCharges = () => {
        setLoading(true);
        axiosInstance.current.get("/charges/report", { params: { fromDate: format(fromDate, 'yyyy-MM-dd'), toDate: format(toDate, 'yyyy-MM-dd')}}).then((response) => {
            setCharges(response.data);
            let list = [];
            response.data.forEach(c => {
                c.charge.amount = settings.find(s => s.key === 'Currency')?.value + c.amount.toFixed(2);
                list.push(c.charge);
            })
            setChargeList(list);
        })
        .catch((err) => {
            toast.current.show({ severity: 'error', summary: 'Error!', detail: 'Unable to get charges. Please try again or contact support.'});
        })
        .finally(() => {
            setLoading(false);
        })
    }

    React.useEffect(() => {
        setFromDate(subMonths(new Date(), 1));
        setToDate(addDays(new Date(), 1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {loading ?
            <ProgressWrapper />
            :
            <>
            <div className="flex-row start">
                <div className="m20">
                    <div className="secondary-title">From</div>
                    <Calendar id="fromDate" name="fromDate" value={fromDate} onChange={(e) => setFromDate(e.value)}/>
                </div>
                <div className="m20">
                    <div className="secondary-title">To</div>
                    <Calendar id="toDate" name="toDate" value={toDate} onChange={(e) => setToDate(e.value)}/>
                </div>
            </div>
            <div className="flex-row start">
                <div className="m20">
                    <Button label="Get Charges" className="p-button-outlined" onClick={getCharges} />
                </div>
            </div>
            {charges.length > 0 ?
                <>
                <ChargesChart charges={charges} />
                <div className="mt30">
                    <div className="secondary-title">Charges and Invoice Totals</div>
                    <div>{fromDate && format(fromDate, 'MM/dd/yyyy')} to {toDate && format(toDate, 'MM/dd/yyyy')}</div>
                    <DataTable
                        className="full-width"
                        paginator
                        rows={10}
                        value={chargeList}
                        responsiveLayout="stack"
                        size="small"
                        dataKey="chargeId"
                    >
                        <Column field="sku" header="SKU" sortable></Column>
                        <Column field="upc" header="UPC" sortable></Column>
                        <Column field="description" header="Description" sortable></Column>
                        <Column header="Exempt" body={chargeExemptBody}></Column>
						<Column header="Labor" body={chargeLaborBody}></Column>
                        <Column field="amount" header="Invoice Totals" sortable></Column>
                    </DataTable>
                </div>
                </>
                :
                <></>
            }
            </>
            }
        </div>
    )
}