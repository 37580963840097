export function getInvoiceTotal(invoice, taxRate) {
    let total = 0.00;
    let subtotal = 0.00;
    let tax = 0.00;
    invoice.tickets.forEach(t => {
        let ticketSubtotal = 0;
        t.lineItems.forEach(line => {
            const amount = line.charge.price * line.quantity * (line.discount?.percentage ? 1 - (line.discount?.percentage / 100) : 1) - (line.discount?.amount ? line.discount?.amount : 0);
            ticketSubtotal += amount;
            if (!line.charge.exempt) {
                tax += Math.round((amount * (taxRate / 100) * 100) + Number.EPSILON) / 100;
            }
        })
        t.subtotal = ticketSubtotal.toFixed(2);
        subtotal += ticketSubtotal
    });
    total += subtotal + tax;
    return total;
}

export function getInvoiceSubtotal(invoice) {
    let total = 0.00;
    let subtotal = 0.00;
    invoice.tickets.forEach(t => {
        let ticketSubtotal = 0;
        t.lineItems.forEach(line => {
            const amount = line.charge.price * line.quantity * (line.discount?.percentage ? 1 - (line.discount?.percentage / 100) : 1) - (line.discount?.amount ? line.discount?.amount : 0);
            ticketSubtotal += amount;
        })
        subtotal += ticketSubtotal
    });
    total += subtotal;
    return total;
}