import * as React from 'react';
import InvoiceChart from '../../components/reports/InvoiceChart.js';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SettingsContext } from '../../../settings-context.js';
import { Calendar } from 'primereact/calendar';
import { subMonths, format, addDays, parseISO} from 'date-fns';
import { Button } from 'primereact/button';
import ProgressWrapper from "../ProgressWrapper";
import { useAxios } from '../../utils/hooks.ts';
import { getInvoiceTotal } from '../../services/InvoiceService';

export default function InvoicesTab({ toast }) {
    const [ loading, setLoading ] = React.useState(false);
    const [ invoices, setInvoices ] = React.useState([]);
    const [ fromDate, setFromDate ] = React.useState();
    const [ toDate, setToDate ] = React.useState();
    const { settings } = React.useContext(SettingsContext);
    const [ total, setTotal ] = React.useState(0.00);
    const [ payments, setPayments ] = React.useState(0.00);
    const axiosInstance = useAxios();

    const invoiceIdBody = (rowData) => {
        return (<div>{rowData.invoiceId.substring(0,8)}</div>)
    }

    const invoiceTotalBody = (rowData) => {
        let total = 0.00;
        let subtotal = 0.00;
        let tax = 0.00;
        rowData.tickets.forEach(t => {
            let ticketSubtotal = 0;
            t.lineItems.forEach(line => {
                const amount = line.charge.price * line.quantity * (line.discount?.percentage ? 1 - (line.discount?.percentage / 100) : 1) - (line.discount?.amount ? line.discount?.amount : 0);
                ticketSubtotal += amount;
                if (!line.charge.exempt) {
                    tax += Math.round((amount * (settings.find(s => s.key === 'Tax')?.value / 100) * 100) + Number.EPSILON) / 100;
                }
            })
            t.subtotal = ticketSubtotal.toFixed(2);
            subtotal += ticketSubtotal
        });
        total += subtotal + tax;
        return (<div>{settings.find(s => s.key === 'Currency')?.value + total.toFixed(2)}</div>)
    }

    const invoicePaymentsBody = (rowData) => {
        let total = 0.00;
        rowData.payments.forEach(p => {
            total += p.amount;
        })
        return (<div>{settings.find(s => s.key === 'Currency')?.value + total.toFixed(2)}</div>);
    }

    const invoiceCreateTime = (rowData) => {
        return <div>{format(parseISO(rowData.createTime), "MMMM dd, yyyy")}</div>
    }
    
    const getInvoices = () => {
        setLoading(true);
        axiosInstance.current.get("/invoices/range", { params: { fromDate: format(fromDate, 'yyyy-MM-dd'), toDate: format(toDate, 'yyyy-MM-dd')}}).then((response) => {
            setInvoices(response.data);
            let total = 0.00;
            let payments = 0.00;
            response.data.forEach(i => {
                total += getInvoiceTotal(i, settings.find(s => s.key === 'Tax')?.value);
                i.payments.forEach(p => payments += p.amount);
            })
            setTotal(total);
            setPayments(payments);
        })
        .catch(() => {
            toast.current.show({ severity: 'error', summary: 'Error!', detail: 'Unable to get invoices at this time. Please try again or contact support.'});
        })
        .finally(() => {
            setLoading(false);
        });
    }

    React.useEffect(() => {
        setFromDate(subMonths(new Date(), 1));
        setToDate(addDays(new Date(), 1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {loading ?
                <ProgressWrapper />
            :
            <>
                <div className="flex-row start">
                    <div className="m20">
                        <div className="secondary-title">From</div>
                        <Calendar id="fromDate" name="fromDate" value={fromDate} onChange={(e) => setFromDate(e.value)}/>
                    </div>
                    <div className="m20">
                        <div className="secondary-title">To</div>
                        <Calendar id="toDate" name="toDate" value={toDate} onChange={(e) => setToDate(e.value)}/>
                    </div>
                </div>
                <div className="flex-row start">
                    <div className="m20">
                        <Button label="Get Invoices" className="p-button-outlined" onClick={getInvoices} />
                    </div>
                </div>
                {invoices.length > 0 ?
                    <>
                        <InvoiceChart invoices={invoices} fromDate={fromDate} toDate={toDate} />
                        <div className="mt30">
                            <div className="secondary-title">Invoices and Payments</div>
                            <div>{format(fromDate, 'MM/dd/yyyy')} to {format(toDate, 'MM/dd/yyyy')}</div>
                            <DataTable
                                className="full-width"
                                paginator
                                rows={10}
                                value={invoices}
                                responsiveLayout="stack"
                                size="small"
                                dataKey="invoiceId"
                            >
                                <Column body={invoiceIdBody} header="Invoice ID"></Column>
                                <Column field="customerName" header="Customer Name" sortable></Column>
                                <Column body={invoiceTotalBody} header="Invoice Total"></Column>
                                <Column body={invoicePaymentsBody} header="Payments"></Column>
                                <Column body={invoiceCreateTime} header="Invoice Date"></Column>
                            </DataTable>
                            <div className="flex-row between">
                                <div></div>
                                <div>
                                    <div className="secondary-title">Total invoices for range: {settings.find(s => s.key === 'Currency')?.value + total.toFixed(2)}</div>
                                    <div className="secondary-title">Total payments for range: {settings.find(s => s.key === 'Currency')?.value + payments.toFixed(2)}</div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <></>
                }
            </>
            }
        </>
    )
}