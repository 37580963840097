import * as React from 'react';
import { useAxios } from '../../utils/hooks.ts';
import { useNavigate } from 'react-router-dom';
import { format, parseISO, parse, startOfWeek, getDay, addMinutes } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { SettingsContext } from '../../../settings-context';
import ProgressWrapper from '../../components/ProgressWrapper';
import { FilterMatchMode } from 'primereact/api';
import MessageDisplay from '../../components/messages/MessageDisplay';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const locales = {
    'en-US': enUS,
}

const localizer = dateFnsLocalizer({
    format, parse, startOfWeek, getDay, locales
})

function MainPage() {
    const [ tickets, setTickets ] = React.useState([]);
    const [ events, setEvents ] = React.useState([]);
    const [ loading, setLoading ] = React.useState();
    const [ selectedTicket, setSelectedTicket ] = React.useState();
    // eslint-disable-next-line
    const { settings, setSettings } = React.useContext(SettingsContext);
    // eslint-disable-next-line
    const [ filters, setFilters ] = React.useState({
        'name': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'customerName': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'status': {value: null, matchMode: FilterMatchMode.CONTAINS}
    })
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const toast = React.useRef(null);

    const selectTicket = (e) => {
        setSelectedTicket(e.value);
        navigate("/tickets/" + e.value.ticketId);
    }

    const selectEvent = (e) => {
        navigate("/tickets/" + e.ticketId);
    }

    React.useEffect(() => {
        setLoading(true);
        axiosInstance.current.get("/tickets/engineers").then((response) => {
            const events = [];
            if (response.data) {
                response.data.forEach(t => {
                    t.displayCreateTime = format(parseISO(t.createTime), "MMMM dd, yyyy h:mm a")
                    const appointments = t.appointments;
                    if (appointments.length > 0) {
                        appointments.forEach(a => {
                            const newEvent = {
                                title: a.title,
                                start: new Date(parseISO(a.scheduledTime)),
                                end: addMinutes(parseISO(a.scheduledTime), a.duration),
                                allDay: false,
                                ticketId: t.ticketId
                            }
                            events.push(newEvent);
                        })
                    }
                })
                setTickets(response.data.filter(t => t.statusType !== 'INVOICED'));
                setEvents(events);
            }
        })
        .catch((err) => {
            console.error(err);
            if (err.response?.status === 401) {
                navigate("/setup");
            }
        })
        .finally(() => {
            setLoading(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <Toast ref={toast} />
            <MessageDisplay />
            
            {loading ?
            <ProgressWrapper />
            :
            <>
            <div className="mt30">
                <div className="title mt10">Schedule</div>
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    onSelectEvent={selectEvent}
                />
            </div>
            <div className="title mt10">My Assigned Tickets</div>
            <div className="mt10">
                <Button label="Create Ticket" className="p-button-outlined p-button-primary" onClick={() => navigate("/tickets/create")} />
            </div>
            <DataTable
                className="full-width"
                paginator
                rows={10}
                value={tickets}
                responsiveLayout="stack"
                size="small"
                selectionMode="single"
                selection={selectedTicket}
                onSelectionChange={(e) => selectTicket(e)}
                dataKey="ticketId"
                filters={filters}
                filterDisplay='row'
            >
                <Column field="name" header="Name" filter filterPlaceholder="Search by ticket name" sortable></Column>
                <Column field="customerName" header="Customer" filter filterPlaceholder="Search by customer" sortable></Column>
                <Column field="status" header="Status" filter filterPlaceholder="Search by status" sortable></Column>
                <Column field="assignedFullname" header="Assigned" sortable></Column>
                <Column field="displayCreateTime" header="Create Time" sortable></Column>
            </DataTable>
            </>
            }
        </div>
    )
}

export default MainPage;