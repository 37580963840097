import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useAxios } from '../../utils/hooks.ts';
import { SettingsContext } from '../../../settings-context';

function DiscountCreateEdit({ discount, visible, hide }) {
    // eslint-disable-next-line
	const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
    const { settings } = React.useContext(SettingsContext);
	const axiosInstance = useAxios();

	const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            code: discount ? discount.code : '',
			description: discount ? discount.description : '',
			percentage: discount ? discount.percentage : 0,
			amount: discount ? discount.amount : 0.00
        },
        validate: (data) => {
            let errors = {};

            if (!data.code) {
                errors.code = 'Code is required.';
            }

			if (!data.description) {
				errors.description= 'Description is required.'
			}

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            data.discountId = discount?.discountId;
            data.active = discount?.active;
            axiosInstance.current.post("/discounts", data).then(() => {
                formik.resetForm();
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setSaving(false);
				hide();
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

	return (
		<Dialog header={discount ? 'View/Edit Discount' : 'Create Discount'} visible={visible} onHide={hide}>
			<div className="flex justify-content-center">
                <div className="card">
                    <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputText id="code" name="code" maxLength={45} value={formik.values.code} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('code') })} />
                                <label htmlFor="code" className={classNames({ 'p-error': isFormFieldValid('code') })}>Code *</label>
                            </span>
                            {getFormErrorMessage('code')}
                        </div>
						<div className="field m20">
                            <span className="p-float-label">
                                <InputText id="description" name="description" maxLength={200} value={formik.values.description} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('description') })}/>
                                <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') })}>Description</label>
                            </span>
                            {getFormErrorMessage('description')}
                        </div>
						<div className="field m20">
                            <span className="p-float-label">
                                <InputNumber id="percentage" name="percentage" value={formik.values.percentage} onValueChange={formik.handleChange} />
                                <label htmlFor="percentage">Percentage (%)</label>
                            </span>
                        </div>
						<div className="field m20">
                            <span className="p-float-label">
                                <InputNumber mode="decimal" maxFractionDigits={2} id="amount" name="amount" value={formik.values.amount} onValueChange={formik.handleChange} />
                                <label htmlFor="amount">Amount ({settings.find(s => s.key === 'Currency')?.value})</label>
                            </span>
                        </div>

                        <div className="flex-row justify-content-center mt20">
                            {discount ?
                            <Button style={{ margin: 10 }} type="submit" label="Save Discount" loading={saving} icon='pi pi-save'/>
                            :
                            <Button style={{ margin: 10 }} type="submit" label="Create Discount" loading={saving} icon='pi pi-plus'/>
                            }
                        </div>
                    </form>
                </div>
            </div>
		</Dialog>
	)
}

export default DiscountCreateEdit;