import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from '../public/keycloak';
import { BrowserRouter } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import Container from "react-bootstrap/Container";
import * as React from 'react';
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import { SettingsContext } from "../settings-context";
import { CompanyContext } from "../company-context";

const defaultSettings = [
  {key: 'Currency', value: '$'},
  {key: 'Tax', value: 10}
]

const defaultSubscription = {
  subscription: 'Demo'
}

function App() {
  const [ settings, setSettings ] = React.useState(defaultSettings);
  const value = { settings, setSettings };
  const [ companyDetails, setCompanyDetails ] = React.useState(defaultSubscription);
  const company = { companyDetails, setCompanyDetails };

  return (
    <ReactKeycloakProvider authClient={keycloak} initOptions={{onLoad: 'login-required'}} >
      <SettingsContext.Provider value={value}>
        <CompanyContext.Provider value={company}>
        <BrowserRouter>
          <Container>
            <HomePage />
          </Container>
        </BrowserRouter>
        </CompanyContext.Provider>
      </SettingsContext.Provider>
    </ReactKeycloakProvider>
  )
}

export default App;
