import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProgressWrapper from '../../components/ProgressWrapper';
import { useAxios } from '../../utils/hooks.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { Button } from 'primereact/button';

function Success() {
    const [ loading, setLoading ] = React.useState(true);
    const { checkoutId } = useParams();
    const navigate = useNavigate();
    const axiosInstance = useAxios();

    React.useEffect(() => {
        axiosInstance.current.put("/companies/checkout/" + checkoutId).then((response) => {
            setLoading(false);
        })
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
        {loading ?
            <ProgressWrapper />
        :
        <>
            <nav className="m20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item" onClick={() => navigate("/company")}>Company</li>
                    <li className="breadcrumb-item active">Upgrade Subscription</li>
				</ol>
			</nav>
            <div className="title mt20">Upgrade Successful</div>
            <div>
                <a target="_blank" rel="noreferrer" href={`${window.API_URL}/api/upgrade/portal/${checkoutId}`}>Stripe Portal</a>
            </div>
            <Button style={{ marginTop: 10 }} label="Return to Company Page" onClick={() => navigate("/company")} />
        </>
        }
        </>
    )

}

export default Success;