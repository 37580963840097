import * as React from 'react';
import { Chart } from 'primereact/chart';
import { SettingsContext } from '../../../settings-context';

const backgroundColorSelection = ["#55efc4", "#ffeaa7", "#fab1a0", "#81ecec", "#ff7675", "#00cec9", "#74b9ff", "#fdcb6e", "#0984e3", "#d63031"]

export default function ChargesChart({ charges }) {
    const [ chartData, setChartData ] = React.useState({});
    const { settings } = React.useContext(SettingsContext);

    React.useMemo(() => {
        const backgroundColors = [];
        const chargeList = [];
        const totals = [];
        let index = 0;
        charges.forEach(c => {
            if (c.amount > 0) {
                chargeList.push(c.charge.description);
                totals.push(c.amount);
                backgroundColors.push(backgroundColorSelection[index % 10]);
            }

            index++;
        });

        const chartData = {
            labels: chargeList,
            datasets: [
                {
                    data: totals,
                    backgroundColor: backgroundColors
                }
            ]
        }

        setChartData(chartData);
    }, [charges])

    return (
        <div>
            <div className="flex-row center">
                <div className="secondary-title">Charges by Invoice Totals ({settings.find(s=> s.key === 'Currency')?.value})</div>
            </div>
            <div className="flex-row center">
                <Chart type="polarArea" data={chartData} style={{ width: '40%'}}/>
            </div>
        </div>
    )
}