import * as React from 'react';
import { useAxios } from '../../utils/hooks.ts';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';

function EditEngineer({ engineer, visible, hide }) {
    // eslint-disable-next-line
    const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
    const [ offices, setOffices ] = React.useState([]);
    const axiosInstance = useAxios();
    const toast = React.useRef(null);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: engineer?.email,
            phone: engineer?.phone,
            office: offices?.find(o => o.officeId === engineer?.officeId),
            addressOne: engineer?.addressOne || '',
            addressTwo: engineer?.addressTwo || '',
            city: engineer?.city || '',
            state: engineer?.state || '',
            zip: engineer?.zip || '',
            active: engineer?.active
        },
        validate: (data) => {
            let errors = {};

            if (!data.email) {
                errors.email = "Email is required.";
            }

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            data.engineerId = engineer.engineerId;
            data.companyId = engineer.companyId;
            data.fullName = engineer.fullName;
            data.officeId = data.office.officeId;
            axiosInstance.current.post("/engineers", data).then(() => {
                formik.resetForm();
                engineer = data;
            })
            .catch((err) => {
                console.error(err);
                toast.current.show({severity: 'error', summary: 'Error!', detail: 'Uanble to save engineer. Please try again or contact support.'});
            })
            .finally(() => {
                setSaving(false);
                hide();
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    React.useEffect(() => {
        axiosInstance.current.get("/offices/all").then((response) => {
            setOffices(response.data);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Dialog header={'View / Edit Engineer'} visible={visible} onHide={hide}>
            <Toast ref={toast} />
            <div className="flex justify-content-center dialog">
                <div className="card">
                    <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
                        <div className="field secondary-title m30">
                                {engineer?.fullName}
                        </div>
                        
                        <div className="field-checkbox m30">
                            <Checkbox inputId="active" name="active" checked={formik.values.active} onChange={formik.handleChange} />
                            <label style={{ marginLeft: 10 }} htmlFor="active">Active?</label>
                        </div>
                        <div className="field m30">
                            <span className="p-float-label">
                                <Dropdown id="office" name="office" value={formik.values.office} onChange={formik.handleChange} options={offices} optionLabel="name" />
                                <label htmlFor="office">Assigned Office</label>
                            </span>
                        </div>
                        <div className="field m30">
                            <span className="p-float-label">
                                <InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email') })} />
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>Email *</label>
                            </span>
                            {getFormErrorMessage('email')}
                        </div>
                        <div className="field m30">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-phone" />
                                <InputMask mask="(999)-999-9999" id="phone" name="phone" value={formik.values.phone} onChange={formik.handleChange} />
                                <label htmlFor="phone">Phone</label>
                            </span>
                        </div>
                        <div className="field m30">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-phone" />
                                <InputMask mask="(999)-999-9999" id="phoneAlt" name="phoneAlt" value={formik.values.phoneAlt} onChange={formik.handleChange} />
                                <label htmlFor="phoneAlt">Alternate Phone</label>
                            </span>
                        </div>
                        <div className="field m30">
                            <span className="p-float-label">
                                <InputText id="addressOne" name="addressOne" value={formik.values.addressOne} onChange={formik.handleChange} />
                                <label htmlFor="addressOne">Address Line 1</label>
                            </span>
                        </div>
                        <div className="field m30">
                            <span className="p-float-label">
                                <InputText id="addressTwo" name="addressTwo" value={formik.values.addressTwo} onChange={formik.handleChange} />
                                <label htmlFor="lastName">Address Line 2</label>
                            </span>
                        </div>
                        <div className="field m30">
                            <span className="p-float-label">
                                <InputText id="city" name="city" value={formik.values.city} onChange={formik.handleChange} />
                                <label htmlFor="city">City</label>
                            </span>
                        </div>
                        <div className="field m30">
                            <span className="p-float-label">
                            <InputText id="state" name="state" maxLength={30} value={formik.values.state} onChange={formik.handleChange} />
                                <label htmlFor="state">State</label>
                            </span>
                        </div>
                        <div className="field m30">
                            <span className="p-float-label">
                                <InputText id="zip" name="zip" value={formik.values.zip} onChange={formik.handleChange} />
                                <label htmlFor="zip">Zip</label>
                            </span>
                        </div>

                        <div className="flex-row justify-content-center mt20">
                            <Button style={{ margin: 5 }} type="submit" label="Save Engineer" loading={saving} icon='pi pi-save'/>
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    )
}

export default EditEngineer;