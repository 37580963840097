import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../utils/hooks.ts';
import ProgressWrapper from '../../components/ProgressWrapper';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabView } from 'primereact/tabview';
import { TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { SettingsContext } from '../../../settings-context';
import EditSetting from '../../components/settings/EditSetting';
import Invite from '../../components/settings/Invite';
import EditEngineer from '../../components/engineers/EditEngineer';
import OfficeCreateEdit from '../../components/offices/OfficeCreateEdit';
import { InputSwitch } from 'primereact/inputswitch';

function Settings() {
	const [ loading, setLoading ] = React.useState(false);
	const [ office, setOffice ] = React.useState();
	const [ company, setCompany ] = React.useState({});
	const [ activeIndex, setActiveIndex ] = React.useState();
	const [ fetchFlag, setFetchFlag ] = React.useState(false);
	const [ engineers, setEngineers ] = React.useState([]);
	const [ selectedEngineer, setSelectedEngineer ] = React.useState();
	const [ editEngineerVisible, setEditEngineerVisible ] = React.useState(false);
	const [ inviteVisible, setInviteVisible ] = React.useState();
	const [ editSettingVisible, setEditSettingVisible ] = React.useState(false);
	const [ selectedSetting, setSelectedSetting ] = React.useState();
	const [ editOfficeVisible, setEditOfficeVisible ] = React.useState(false);
	const { settings, setSettings } = React.useContext(SettingsContext);
	const navigate = useNavigate();
	const axiosInstance = useAxios();

	const flipFetchFlag = () => {
		setFetchFlag(!fetchFlag);
	}

	const toggleEditSettingVisible = () => {
		setEditSettingVisible(!editSettingVisible);
		flipFetchFlag();
	}

	const toggleInviteVisible = () => {
		setInviteVisible(!inviteVisible);
	}

	const toggleEditEngineerVisible = () => {
		setEditEngineerVisible(!editEngineerVisible);
		flipFetchFlag();
	}

	const toggleEditOfficeVisible = () => {
		setEditOfficeVisible(!editOfficeVisible);
		flipFetchFlag();
	}

	const selectEngineer = (e) => {
		setSelectedEngineer(e);
		setEditEngineerVisible(true);
	}

	const editSettingBody = (setting) => {
		return <Button className="p-button-text" label="Edit" icon='pi pi-pencil' onClick={() => {
			setSelectedSetting(setting);
			setEditSettingVisible(true);
		}} />
	}

	const booleanSettingBody = (setting) => {
		if (setting.settingType) {
			return <InputSwitch checked={setting.enabled} />
		} else {
			return <></>
		}
	}

	const editEngineerBody = (rowData) => {
		return <Button className="p-button-text" label="View / Edit" icon='pi pi-pencil' onClick={() => selectEngineer(rowData)} />
	}

	React.useEffect(() => {
		axiosInstance.current.get("/companies").then((response) => {
			setCompany(response.data);
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	React.useEffect(() => {
		setLoading(true);
		axiosInstance.current.get("/offices").then((response) => {
			if (response.data) {
				setSettings(response.data.settings);
				setOffice(response.data);
				response.data.engineers.forEach(e => e.activeDisplay = e.active ? 'Active' : 'Inactive');
				setEngineers(response.data.engineers);
			}
		})
		.catch((err) => {
			if (err.response.status === 401) {
				navigate("/setup");
			}
		})
		.finally(() => {
			setLoading(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchFlag])

	return (
		<>
		{loading ?
		<ProgressWrapper />
		:
		<div>
			<EditSetting setting={selectedSetting} visible={editSettingVisible} hide={toggleEditSettingVisible} />
			<EditEngineer engineer={selectedEngineer} visible={editEngineerVisible} hide={toggleEditEngineerVisible} />
			<OfficeCreateEdit office={office} visible={editOfficeVisible} hide={toggleEditOfficeVisible} />
			<Invite visible={inviteVisible} hide={toggleInviteVisible} />
            <nav className="m20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item active">Settings</li>
				</ol>
			</nav>
            <div className="title mt10">{office?.name}</div>
			<Button style={{ marginBottom: 10 }} label="Edit Office Details" icon="pi pi-pencil" className="p-button-outlined" onClick={() => setEditOfficeVisible(true)} />
			<div className="flex-row wrap">
				<div className="flex-col half-width">
					<div className="secondary-title">Name:</div>
					<div className="tertiary-title">{office?.name}</div>
					<div className="secondary-title">Email:</div>
					<div className="tertiary-title">{office?.email}</div>
					<div className="secondary-title">Phone:</div>
					<div className="tertiary=title">{office?.phone}</div>
					{office?.phoneAlt ?
						<>
							<div className="secondary-title">Alternate Phone:</div>
							<div className="tertiary-title">{office?.phoneAlt}</div>
						</>
					:
						<></>
					}
					{office?.fax ?
						<>
							<div className="secondary-title">Fax:</div>
							<div className="tertiary-title">{office?.fax}</div>
						</>
					:
						<></>
					}
				</div>
				<div className="flex-col half-width">
					<div className="secondary-title">Address:</div>
					<div className="tertiary-title">{office?.addressOne}</div>
					<div className="tertiary-title">{office?.addressTwo}</div>
					<div className="tertiary-title">{office?.city}, {office?.state} {office?.zip}</div>
				</div>
			</div>
			{loading ?
                <ProgressWrapper />
            :
                <div className="mt20">
					<TabView className="tabview-header-icon" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
						<TabPanel header="Engineers" leftIcon="pi pi-fw pi-users">
							{company.subscription === 'Enterprise' ?
								<Button style={{ marginBottom: 10 }} label="Add Engineer" className="p-button-outlined" icon='pi pi-plus' onClick={toggleInviteVisible}/>
							:
								<></>
							}
							<DataTable
								className="full-width"
								paginator
								rows={10}
								value={engineers}
								responsiveLayout="stack"
								size="small"
								dataKey="engineerId"
							>
								<Column field="fullName" header="Name" sortable></Column>
								<Column field="email" header="Email" sortable></Column>
								<Column field="phone" header="Phone" sortable></Column>
								<Column body={editEngineerBody}></Column>
							</DataTable>
						</TabPanel>
						<TabPanel header="Office Settings" leftIcon="pi pi-fw pi-cog">
							
							<DataTable
								className="full-width mt30"
								paginator
								rows={10}
								value={settings}
								responsiveLayout="stack"
								size="small"
								dataKey="settingId"
							>
								<Column field="key" header="Name" sortable></Column>
								<Column field="description" header="Setting" sortable></Column>
								<Column field="value" header="Value" sortable></Column>
								<Column body={booleanSettingBody} header="Enabled"></Column>
								<Column body={editSettingBody}></Column>
							</DataTable>
						</TabPanel>
					</TabView>
                </div>
            }
		</div>
		}
		</>
	)
}

export default Settings;

