import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useAxios } from '../../utils/hooks.ts';

export default function OrderCreate({ order, inventoryId, visible, hide }) {
    // eslint-disable-next-line
	const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
	const axiosInstance = useAxios();

	const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            orderCount: order ? order.orderCount : 0,
            orderPrice: order ? order.orderPrice : 0.00,
            orderLink: order ? order.orderLink : ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.orderCount) {
                errors.orderCount = 'Count is required.';
            }

            if (!data.orderPrice) {
                errors.orderPrice = 'Price is required.';
            }

            return errors;
        },
        onSubmit: (data) => {
            data.inventoryId = inventoryId;
            data.inventoryOrderId = order?.inventoryOrderId;
            setFormData(data);
            axiosInstance.current.post("/inventory/order", data).then(() => {
                if (order) {
                    order.orderCount = data.orderCount;
                    order.orderPrice = data.orderPrice;
                    order.orderLink = data.orderLink;
                }
                formik.resetForm();
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setSaving(false);
				hide();
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

	return (
		<Dialog header={order ? "Edit Order" : "Create Order"} visible={visible} onHide={hide}>
			<div className="flex justify-content-center">
                <div className="card">
                    <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputNumber min={0} id="orderCount" name="orderCount" value={formik.values.orderCount} onValueChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('orderCount')})} />
                                <label htmlFor="orderCount" className={classNames({ 'p-error': isFormFieldValid('orderCount')})}>Count *</label>
                            </span>
                            {getFormErrorMessage('orderCount')}
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputNumber min={0} maxFractionDigits={2} mode="decimal" id="orderPrice" name="orderPrice" value={formik.values.orderPrice} onValueChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('orderPrice')})} />
                                <label htmlFor="orderPrice" className={classNames({ 'p-error': isFormFieldValid('orderPrice')})}>Price *</label>
                            </span>
                            {getFormErrorMessage('orderPrice')}
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputText maxLength={200} id="orderLink" name="orderLink" value={formik.values.orderLink} onChange={formik.handleChange}/>
                                <label htmlFor="orderLink">Link</label>
                            </span>
                        </div>
                        <div className="flex-row justify-content-center mt20">
                            {order ? 
                                <Button style={{ margin: 10 }} type="submit" label="Save Order" icon="pi pi-save" loading={saving} />
                            :
                                <Button style={{ margin: 10 }} type="submit" label="Create Order" icon="pi pi-plus" loading={saving} />
                            }
                        </div>
                    </form>
                </div>
            </div>
		</Dialog>
	)
}