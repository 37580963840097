import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { TabView, TabPanel } from 'primereact/tabview';

function About() {
    const navigate = useNavigate();
    return (
        <>
            <nav className="m20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item active">About</li>
				</ol>
			</nav>
            <div className="title mt20">
                About Apogee
            </div>
            <div style={{ marginTop: 20 }}>
                <div className="audit-details">New Labs System Status</div>
                <statuspage-widget src="https://newlabs.statuspage.io"></statuspage-widget>
            </div>
            <TabView className="tabview-header-icon">
                <TabPanel header="Dev Notes" leftIcon="pi pi-fw pi-file">
                    <div className="flex-col wrap">
                        <div>
                            Version 1.0.16
                        </div>
                        <div>
                            <span className="audit-details">(11/7/2023)</span>
                        </div>
                        <ul>
                            <li>Adding customer classifications</li>
                            <li>Adding customer note</li>
                            <li>Blocked customers are not available for ticket creation</li>
                        </ul>
                        <div>
                            Version 1.0.15
                        </div>
                        <div>
                            <span className="audit-details">(9/7/2023)</span>
                        </div>
                        <ul>
                            <li>Adding inventory tracking</li>
                            <li>Clicking on appointment navigates to ticket details page</li>
                        </ul>
                        <div>
                            Version 1.0.14
                        </div>
                        <div>
                            <span className="audit-details">(8/24/2023)</span>
                        </div>
                        <ul>
                            <li>User can override default pricing for a charge</li>
                        </ul>
                        <div>
                            Version 1.0.13
                        </div>
                        <div>
                            <span className="audit-details">(7/17/2023)</span>
                        </div>
                        <ul>
                            <li>Invoice and estimate templates</li>
                            <li>Help page for contact info and help articles</li>
                        </ul>
                        <div>
                            Version 1.0.12
                        </div>
                        <div>
                            <span className="audit-details">(7/14/2023)</span>
                        </div>
                        <ul>
                            <li>Additional statuses</li>
                        </ul>
                        <div>
                            Version 1.0.11
                        </div>
                        <div>
                            <span className="audit-details">(7/5/2023)</span>
                        </div>
                        <ul>
                            <li>Reports page now available</li>
                            <li>Invoices report by date range</li>
                            <li>Customer report and total invoices</li>
                        </ul>
                        <div>
                            Version 1.0.10
                        </div>
                        <div>
                            <span className="audit-details">(6/22/2023)</span>
                        </div>
                        <ul>
                            <li>Appointments can be added to tickets</li>
                            <li>Emails are sent to attendees of appointments on creation</li>
                            <li>Reminder emails are sent on the morning of the day before and the day of each appointment</li>
                            <li>Notes on tickets now use HTML for formatting</li>
                        </ul>
                        <div>
                            Version 1.0.9
                        </div>
                        <div>
                            <span className="audit-details">(6/8/2023)</span>
                        </div>
                        <ul>
                            <li>Additional lines for invoice tags for customization</li>
                        </ul>
                        <div>
                            Version 1.0.8
                        </div>
                        <div>
                            <span className="audit-details">(5/30/2023)</span>
                        </div>
                        <ul>
                            <li>Create invoices from the ticket details screen without changing status to 'Invoiced'</li>
                        </ul>
                        <div>
                            Version 1.0.7
                        </div>
                        <div>
                            <span className="audit-details">(5/30/2023)</span>
                        </div>
                        <ul>
                            <li>Tickets can have one time charges that won't create a SKU/UPC in the system</li>
                            <li>One time charges can be marked as tax exempt</li>
                            <li>Bug fix with invoices not considering tax exempt status for line items</li>
                            <li>Completed and Closed Without Repair will unassign engineer from ticket</li>
                        </ul>
                        <div>
                            Version 1.0.6
                        </div>
                        <div>
                            <span className="audit-details">(5/17/2023)</span>
                        </div>
                        <ul>
                            <li>Ticket description length extended to 500 characters</li>
                            <li>Note text length extended to 500 characters</li>
                            <li>Tickets are now able to marked as estimates</li>
                            <li>Point of sale invoices can be deleted</li>
                        </ul>
                        <div>
                            Version 1.0.5
                        </div>
                        <div>
                            <span className="audit-details">(4/1/2023)</span>
                        </div>
                        <ul>
                            <li>Customer state is now free text field</li>
                        </ul>
                        <div>
                            Version 1.0.4
                        </div>
                        <div>
                            <span className="audit-details">(3/18/2023)</span>
                        </div>
                        <ul>
                            <li>Removing required on fields</li>
                            <li>Office edit bug</li>
                        </ul>
                        <div>
                            Version 1.0.3
                        </div>
                        <div>
                            <span className="audit-details">(3/10/2023)</span>
                        </div>
                        <ul>
                            <li>Removing autocomplete on fields</li>
                            <li>Tech that creates a ticket is automatically assigned</li>
                        </ul>
                        <div>
                            Version 1.0.2
                        </div>
                        <div>
                            <span className="audit-details">(10/18/2022)</span>
                        </div>
                        <ul>
                            <li>Displaying customer ID on customer details page</li>
                            <li>Displaying ticket ID on ticket details page</li>
                        </ul>
                        <div>
                            Version 1.0.1
                        </div>
                        <div>
                            <span className="audit-details">(10/18/2022)</span>
                        </div>
                        <ul>
                            <li>Fix URL link for viewing tickets and invoices</li>
                        </ul>
                        <div>
                            Version 1.0.0
                        </div>
                        <div>
                            <span className="audit-details">(10/17/2022)</span>
                        </div>
                        <ul>
                            <li>Initial Release</li>
                            <li>Ticket Tracking</li>
                            <li>Customer Tracking</li>
                            <li>Invoice Tracking</li>
                            <li>Point of Sale Invoicing</li>
                            <li>Customer Engagement w/ Email and Portal <span className="audit-details">(Premium)</span></li>
                        </ul>
                    </div>
                </TabPanel>
                <TabPanel header="Future Features" leftIcon="pi pi-fw pi-file">
                    <div className="flex-row wrap">
                        <ul>
                            <li>Additional reports</li>
                            <li>Additional details to devices (OS, hard drive, etc)</li>
                            <li>Add filtering to more tables</li>
                            <li>Additional filtering on tables with filtering</li>
                        </ul>
                    </div>
                </TabPanel>
            </TabView>
        </>
    )

}

export default About;