import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useAxios } from '../../utils/hooks.ts';

function SelectDevice({ ticket, visible, hide }) {
	const [ devices, setDevices ] = React.useState([]);
	const [ selectedDevice, setSelectedDevice ] = React.useState();
	const [ saving, setSaving ] = React.useState(false);
	const axiosInstance = useAxios();

	const handleAdd = () => {
		setSaving(true);
		const data = {
			ticketId: ticket.ticketId,
			deviceId: selectedDevice.deviceId
		}
		axiosInstance.current.put("/devices/tickets", data)
		.catch((err) => {
			console.error(err);
		})
		.finally(() => {
			setSaving(false);
			hide();
		})
	}

	React.useEffect(() => {
		if (!ticket) return;
		axiosInstance.current.get("/devices/customers/" + ticket.customerId).then((response) => {
			if (response.data) {
				setDevices(response.data);
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ticket])

	return (
		<Dialog header="Add Device to Ticket" visible={visible} onHide={hide}>
			<div className="m20" style={{ minWidth: 300 }}>
				<Dropdown options={devices} optionLabel="name" value={selectedDevice} onChange={(e) => setSelectedDevice(e.value)} style={{ minWidth: 300 }}/>
			</div>
			<div className="m20">
				<Button className="p-button-primary" label="Add Device" onClick={handleAdd} loading={saving} />
			</div>
		</Dialog>
	)
}

export default SelectDevice;