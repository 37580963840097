import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';
import { useAxios } from '../../utils/hooks.ts';

function TicketEdit({ ticket, visible, hide, flipFetchFlag }) {
    // eslint-disable-next-line
    const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
    const axiosInstance = useAxios();

    const formik = useFormik({
        initialValues: {
            name: ticket.name,
            description: ticket.description,
            estimate: ticket.estimate
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'Name/Summary is required.';
            }
            if (!data.description) {
                errors.description = 'Description is required.';
            }

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            ticket.name = data.name;
            ticket.description = data.description;
            ticket.estimate = data.estimate;
            axiosInstance.current.put("/tickets", ticket).then(() => {
                formik.resetForm();
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                flipFetchFlag();
                hide();
                setSaving(false);
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <Dialog header="Edit Ticket" visible={visible} onHide={hide}>
            <div className="card">
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputText id="name" name="name" maxLength={45} value={formik.values.name} onChange={formik.handleChange} autoComplete="off" className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                            <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>Name/Summary *</label>
                        </span>
                        {getFormErrorMessage('name')}
                    </div>
                    <div className="field m20">
                        <span className="p-float-label">
                            <InputTextarea id="description" maxLength={500} name="description" rows={5} cols={30} value={formik.values.description} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('description') })} />
                            <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') })}>Description *</label>
                        </span>
                        {getFormErrorMessage('description')}
                    </div>
                    <div className="field m20">
                        <Checkbox inputId="estimate" name="estimate" checked={formik.values.estimate} onChange={formik.handleChange} />
                        <label style={{ marginLeft: 10 }} htmlFor="estimate">Estimate?</label>
                    </div>

                    <div className="flex-row center mt20">
                        <Button type="submit" style={{ margin: 5, width: '50%' }} label="Save" loading={saving}/>
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default TicketEdit;