import * as React from 'react';
import { useAxios } from '../../utils/hooks.ts';
import ProgressWrapper from '../ProgressWrapper';
import { getInvoiceTotal } from '../../services/InvoiceService.js';
import CustomerChart from './CustomerChart.js';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SettingsContext } from '../../../settings-context.js';

export default function CustomersTab({ toast }) {
    const [ loading, setLoading ] = React.useState(false);
    const [ customers, setCustomers ] = React.useState([]);
    const { settings } = React.useContext(SettingsContext);
    const axiosInstance = useAxios();

    const getCustomers = () => {
        setLoading(true);
        axiosInstance.current.get("/customers/report").then((response) => {
            response.data.forEach(c => {
                let total = 0.00;
                c.shortId = c.customerId.substring(0,8);
                c.fullName = c.firstName + ' ' + c.lastName;
                if (c.invoices) {
                    c.invoices.forEach(i => {
                        total += getInvoiceTotal(i, settings.find(s => s.key === 'Tax')?.value);
                    });
                }
                c.total = settings.find(s => s.key === 'Currency')?.value + total.toFixed(2);
            });
            setCustomers(response.data);
        })
        .catch(() => {
            toast.current.show({ severity: 'error', summary: 'Error!', detail: 'Error retrieving customer information. Please try again or contact support.'})
        })
        .finally(() => {
            setLoading(false);
        })
    }

    React.useEffect(() => {
        getCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
        {loading ?
        <ProgressWrapper />
        :
        <>
            <CustomerChart customers={customers} />
            <div className="mt30">
                <div className="secondary-title">Customers and Total Invoices</div>
                <DataTable
                    className="full-width"
                    paginator
                    rows={10}
                    value={customers}
                    responsiveLayout="stack"
                    size="small"
                    dataKey="invoiceId"
                >
                    <Column field="shortId" header="Invoice ID" sortable></Column>
                    <Column field="fullName" header="Customer Name" sortable></Column>
                    <Column field="total" header="Total Invoices" sortable></Column>
                </DataTable>
            </div>
        </>
        }
        </>
    )
}