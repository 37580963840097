import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { useAxios } from '../../utils/hooks.ts';
import { Editor } from 'primereact/editor';

function NoteCreate(props) {
    // eslint-disable-next-line
	const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
    const [ text, setText ] = React.useState('');
	const axiosInstance = useAxios();

	const formik = useFormik({
        initialValues: {
            text: '',
            internal: true
        },
        validate: (data) => {
            let errors = {};

            if (!text) {
                errors.text = 'Text is required.';
            }

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
			data.ticketId = props.ticket.ticketId;
            data.text = text;
            axiosInstance.current.post("/tickets/notes", data).then(() => {
                formik.resetForm();
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setSaving(false);
				props.hide();
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

	return (
		<Dialog header="Create New Note" visible={props?.visible} onHide={props?.hide}>
			<div className="flex justify-content-center" style={{ width: 700}}>
                <div className="card">
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="field m20">
                            <span className="p-float-label">
                                <Editor name="text" id="text" style={{ height: '320px'}} value={text} onTextChange={(e) => setText(e.htmlValue)} />
                            </span>
                            {getFormErrorMessage('text')}
                        </div>
                        <div className="field-checkbox m20">
                            <Checkbox inputId="internal" name="internal" checked={formik.values.internal} onChange={formik.handleChange} />
                            <label style={{ marginLeft: 10 }} htmlFor="internal">Internal Note</label>
                        </div>

                        <div className="flex-row justify-content-center mt20">
                            <Button style={{ width: '50%' }} type="submit" label="Create Note" icon='pi pi-save' loading={saving} />
                        </div>
                    </form>
                </div>
            </div>
		</Dialog>
	)
}

export default NoteCreate;