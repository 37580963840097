import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { SettingsContext } from '../../../settings-context';
import { useAxios } from '../../utils/hooks.ts';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import POSLineItemCreateEdit from '../../components/pos/POSLineItemCreateEdit';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

function POSInvoice() {
    const [ saving, setSaving ] = React.useState(false);
    const [ selectedCustomer, setSelectedCustomer ] = React.useState();
    const [ customers, setCustomers ] = React.useState([]);
    const [ lineItems, setLineItems ] = React.useState([]);
    const [ lineItemIndex, setLineItemIndex ] = React.useState(0);
    const [ subtotal, setSubtotal ] = React.useState(0);
    const [ tax, setTax ] = React.useState(0);
    const [ total, setTotal ] = React.useState(0);
    const [ selectedLineItem, setSelectedLineItem ] = React.useState();
	const [ lineItemCreateEditVisible, setLineItemCreateEditVisible ] = React.useState(false);
    const { settings } = React.useContext(SettingsContext);
    const axiosInstance = useAxios();
    const navigate = useNavigate();
    const toast = React.useRef(null);

    const toggleLineItemCreateEditVisible = () => {
        setSelectedLineItem(null);
		setLineItemCreateEditVisible(!lineItemCreateEditVisible);
	}

    const selectLineItem = (rowData) => {
		setSelectedLineItem(rowData);
        setLineItemCreateEditVisible(true);
	}

    const deleteLineItem = (rowData) => {
        setLineItems(lineItems.filter(l => l.index !== rowData.index));
    }

    const add = (lineItem) => {
        setLineItemIndex(lineItemIndex + 1);
        setLineItems([lineItem, ...lineItems]);
        toggleLineItemCreateEditVisible();
    }

    const lineItemEditBody = (rowData) => {
		return <Button className="p-button-outlined" icon='pi pi-pencil' onClick={() => selectLineItem(rowData)} />
	}

	const lineItemRemoveBody = (rowData) => {
		return <Button className="p-button-outlined p-button-danger" icon='pi pi-trash' onClick={() => deleteLineItem(rowData)} />
	}

    const itemTemplate = (option) => {
        return <div>{option.firstName} {option.lastName}</div>
    }

    const saveInvoice = () => {
        setSaving(true);
        if (!selectedCustomer) {
            toast.current.show({ severity: 'error', summary: 'No customer selected!', detail: 'Please select a customer.'});
            setSaving(false);
            return;
        }
        const invoice = {
            customerId: selectedCustomer.customerId,
            tickets: [{
                name: selectedCustomer.customerId ? selectedCustomer.firstName + " " + selectedCustomer.lastName : selectedCustomer,
                customerId: selectedCustomer.customerId || "POS",
                lineItems: lineItems
            }],
            payments: [{
                amount: total
            }]
        }
        axiosInstance.current.post("/invoices", invoice).then((response) => {
            if (response.data) {
                toast.current.show({severity: 'success', summary: 'Saved!', detail: 'Invoice saved as ' + response.data.invoiceId.substring(0, 8)});
            }
        })
        .catch((err) => {
            toast.current.show({severity: 'error', summary: 'Error!', detail: 'Unable to create invoice. Please try again or contact support.'});
        })
        .finally(() => {
            setSaving(false);
            navigate("/invoices");
        });
    }

    React.useEffect(() => {
        let subtotal = 0.00;
        let tax = 0.00;
        lineItems.forEach(line => {
            const amount = line.charge.price * line.quantity * (line.discount?.percentage ? 1 - (line.discount?.percentage / 100) : 1) - (line.discount?.amount ? line.discount?.amount : 0);
            subtotal += amount;
            if (!line.charge.exempt) {
                tax += Math.round((amount * (settings.find(s => s.key === 'Tax')?.value / 100) * 100) + Number.EPSILON) / 100;
            }
        })
        setSubtotal(subtotal);
        setTax(tax);
        setTotal(subtotal + tax);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineItems])

    React.useEffect(() => {
        axiosInstance.current.get("/customers").then((response) => {
            if (response.data) {
                setCustomers(response.data);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <Toast ref={toast} />
            <POSLineItemCreateEdit index={lineItemIndex} lineItem={selectedLineItem} visible={lineItemCreateEditVisible} hide={toggleLineItemCreateEditVisible} add={add}/>
            <nav className="m20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item active">Point of Sale</li>
				</ol>
			</nav>
            <div className="title mt10">Point of Sale</div>
            <div>
                <Dropdown
                    style={{ marginBottom: 10 }}
                    id="customer"
                    options={customers}
                    optionLabel="lastName"
                    value={selectedCustomer}
                    onChange={(e) => setSelectedCustomer(e.value)}
                    placeholder="Customer Name"
                    editable 
                    itemTemplate={itemTemplate}
                    />
            </div>
            <Button style={{ marginBottom: 10 }} className="p-button-outlined" label="Add Line Item" icon="pi pi-plus" onClick={toggleLineItemCreateEditVisible} />
            <DataTable
                className="full-width"
                paginator
                rows={10}
                value={lineItems}
                responsiveLayout="stack"
                size="small"
                dataKey="lineItemId"
                emptyMessage="No line items created yet"
            >
                <Column field="charge.sku" header="SKU" sortable filter filterPlaceholder="Search by SKU"></Column>
                <Column field="charge.upc" header="UPC" sortable filter filterPlaceholder="Search by UPC"></Column>
                <Column field="charge.description" header="Description" sortable filter filterPlaceholder="Search by description"></Column>
                <Column field="charge.price" header={`Price (${settings.find(s => s.key === 'Currency')?.value})`} sortable></Column>
                <Column field="quantity" header="Quantity" sortable ></Column>
                <Column field="discount.code" header="Discount" sortable ></Column>
                <Column field="subtotal" header={`Subtotal (${settings.find(s => s.key === 'Currency')?.value})`} sortable></Column>
                <Column body={lineItemEditBody}></Column>
                <Column body={lineItemRemoveBody}></Column>
            </DataTable>
            <div className="flex-row end secondary-title">Subtotal: {settings.find(s => s.key === 'Currency')?.value}{subtotal.toFixed(2)}</div>
            <div className="flex-row end secondary-title">Tax ({settings.find(s => s.key === 'Tax')?.value}%): {settings.find(s => s.key === 'Currency')?.value}{tax.toFixed(2)}</div>
            <div className="flex-row end secondary-title mt30">Total: {settings.find(s => s.key === 'Currency')?.value}{total.toFixed(2)}</div>
            <div className="flex-row end">
                <Button className="p-button-primary" label="Save Invoice" icon='pi pi-save' loading={saving} onClick={saveInvoice} />
            </div>
        </div>
    )
}

export default POSInvoice;