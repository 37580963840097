import * as React from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { useKeycloak } from "@react-keycloak/web";
import { Route, Routes } from 'react-router-dom';
import MainPage from '../main/MainPage';
import Tickets from '../tickets/Tickets';
import { useNavigate } from 'react-router-dom';
import Customers from '../customers/Customers';
import CustomerDetails from '../customers/CustomerDetails';
import TicketsCreate from '../tickets/TicketsCreate';
import TicketDetails from '../tickets/TicketDetails';
import ProgressWrapper from '../../components/ProgressWrapper';
import Settings from '../settings/Settings';
import Invoices from '../invoices/Invoices';
import InvoiceDetail from '../invoices/InvoiceDetail';
import { useAxios } from '../../utils/hooks.ts';
import { SettingsContext } from '../../../settings-context';
import { CompanyContext } from '../../../company-context';
import Join from '../join/JoinPage';
import Company from '../company/Company';
import Setup from '../setup/SetupPage';
import { Divider } from 'primereact/divider';
import { Image } from 'primereact/image';
import POSInvoice from '../invoices/POSInvoice';
import TitleBar from '../../components/home/TitleBar';
import Upgrade from '../company/Upgrade';
import Success from '../company/Success';
import Footer from '../../components/home/Footer';
import About from '../about/About';
import Reports from '../reports/Reports';
import { Badge } from 'primereact/badge';
import Help from '../help/Help';
import Inventory from '../inventory/Inventory';

const SidebarMenu = ({ setMenuVisible, company, keycloak }) => {
    const navigate = useNavigate();

    return (
        <div className="flex-col">
            <div className="flex-col align-center m30">
                <Image src="/logo.png" width='100'/>
                <div className="title ml20">Apogee</div>
                <div className="audit-details mt10">by New Labs</div>
            </div>
            <div>
                <Button style={{ color: '#2c3e50' }} className="p-button-outlined p-button-text p-button-lg" label="Home" icon='pi pi-home' onClick={() => {navigate("/"); setMenuVisible(false);}}/>
            </div>
            <div>
                <Button style={{ color: '#2c3e50' }} className="p-button-outlined p-button-text p-button-lg" label="Tickets" icon='pi pi-file' onClick={() => {navigate("/tickets"); setMenuVisible(false);}}/>
            </div>
            <div>
                <Button style={{ color: '#2c3e50' }} className="p-button-outlined p-button-text p-button-lg" label="Customers" icon='pi pi-users' onClick={() => {navigate("/customers"); setMenuVisible(false);}}/>
            </div>
            <div>
                <Button style={{ color: '#2c3e50' }} className="p-button-outlined p-button-text p-button-lg" label="Invoices" icon='pi pi-money-bill' onClick={() => {navigate("/invoices"); setMenuVisible(false);}}/>
            </div>
            <div className="flex-row">
                <Button style={{ color: '#2c3e50' }} className="p-button-outlined p-button-text p-button-lg" label="Inventory" icon='pi pi-truck' onClick={() => {navigate("/inventory"); setMenuVisible(false);}}/>
                <Badge value="New" />
            </div>
            <div>
                <Button style={{ color: '#2c3e50' }} className="p-button-outlined p-button-text p-button-lg" label="Reports" icon='pi pi-chart-bar' onClick={() => {navigate("/reports"); setMenuVisible(false);}}/>
            </div>
            <div>
                <Button style={{ color: '#2c3e50' }} className="p-button-outlined p-button-text p-button-lg" label="Point of Sale" icon='pi pi-credit-card' onClick={() => {navigate("/point"); setMenuVisible(false);}}/>
            </div>
            <div>
                <Button style={{ color: '#2c3e50' }} className="p-button-outlined p-button-text p-button-lg" label="Office Settings" icon='pi pi-cog' onClick={() => {navigate("/settings"); setMenuVisible(false);}}/>
            </div>
            <div>
                <Button style={{ color: '#2c3e50' }} className="p-button-outlined p-button-text p-button-lg" label="Company Details" icon='pi pi-building' onClick={() => {navigate("/company"); setMenuVisible(false);}}/>
            </div>
            <div>
                <Button style={{ color: '#2c3e50' }} className="p-button-outlined p-button-text p-button-lg" label="Help" icon='pi pi-question' onClick={() => {navigate("/help"); setMenuVisible(false);}}/>
            </div>
            <Divider />
            <div>
                <div className="secondary-title">{company.name}</div>
            </div>
            <div>
                <div className="secondary-title">{keycloak.tokenParsed?.email}</div>
            </div>
            <div style={{ marginTop: 20 }}>
                <div className="audit-details">New Labs System Status</div>
                <statuspage-widget src="https://newlabs.statuspage.io"></statuspage-widget>
            </div>
            <div className="mt30">
                <Button className="p-button-warning" icon="pi pi-sign-out" label="Logout" onClick={() => keycloak.logout()} />
            </div>
        </div>
    )
}

function HomePage() {
    const { setSettings } = React.useContext(SettingsContext);
    const { setCompanyDetails } = React.useContext(CompanyContext);
    const [ company, setCompany ] = React.useState({});
    const [ menuVisible, setMenuVisible ] = React.useState(false);
    const { keycloak, initialized} = useKeycloak();
    const navigate = useNavigate();
    const axiosInstance = useAxios();

    React.useEffect(() => {
        if (!initialized) {
            return;
        }
        axiosInstance.current.get("/offices").then((response) => {
            if (response.data) {
                setSettings(response.data.settings);
            }
        })
        .catch((err) => {
            if (err.response.status === 401) {
                navigate("/setup");
            }
        })
        axiosInstance.current.get("/companies").then((response) => {
            if (response.data) {
                setCompanyDetails({subscription: response.data.subscription});
                setCompany(response.data);
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized])

    return (
        <>
            {initialized ?
                <div>
                    <Sidebar visible={menuVisible} onHide={() => setMenuVisible(false)}>
                        <SidebarMenu setMenuVisible={setMenuVisible} company={company} keycloak={keycloak}/>
                    </Sidebar>
                    <TitleBar setMenuVisible={setMenuVisible} />
                    <div style={{ paddingBottom: 100, zIndex: 100 }}>
                    <Routes>
                        <Route exact path="/" element={<MainPage />} />
                        <Route path="/tickets" element={<Tickets />} />
                        <Route path="/tickets/create" element={<TicketsCreate />} />
                        <Route path="/tickets/:id" element={<TicketDetails />} />
                        <Route path="/customers" element={<Customers />} />
                        <Route path="/customers/:id" element={<CustomerDetails />} />
                        <Route path="/invoices" element={<Invoices />} />
                        <Route path="/invoices/:id" element={<InvoiceDetail />} />
                        <Route path="/inventory" element={<Inventory />} />
                        <Route path="/reports" element={<Reports />} />
                        <Route path="/point" element={<POSInvoice />} />
                        <Route path="/company" element={<Company />} />
                        <Route path="/upgrade" element={<Upgrade />} />
                        <Route path="/success/:checkoutId" element={<Success />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/join/:id" element={<Join />} />
                        <Route path="/setup" element={<Setup />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/help" element={<Help />} />
                    </Routes>
                    </div>
                    <Footer />
                </div>
            :
                <ProgressWrapper />
            }
        </>
    )
}

export default HomePage;