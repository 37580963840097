import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAxios } from '../../utils/hooks.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faArrowCircleRight, faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { parseISO, format } from 'date-fns';
import ProgressWrapper from '../../components/ProgressWrapper';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Toast } from 'primereact/toast';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { SettingsContext } from '../../../settings-context';
import { CompanyContext } from '../../../company-context';
import { TabView } from 'primereact/tabview';
import { TabPanel } from 'primereact/tabview';
import CreatePayment from '../../components/payments/CreatePayment';

function InvoiceDetail() {
    const { id } = useParams();
    const [ loading, setLoading ] = React.useState(true);
    const [ fetchFlag, setFetchFlag ] = React.useState(false);
    const [ invoice, setInvoice ] = React.useState();
    const [ tax, setTax ] = React.useState(0);
    const [ subtotal, setSubtotal ] = React.useState(0);
    const [ invoiceTotal, setInvoiceTotal ] = React.useState(0);
    const [ paymentTotal, setPaymentTotal ] = React.useState(0);
    const [ tickets, setTickets ] = React.useState([]);
    const [ payments, setPayments ] = React.useState([]);
    const [ activeIndex, setActiveIndex ] = React.useState();
    const [ paymentVisible, setPaymentVisible ] = React.useState(false);
    const { settings } = React.useContext(SettingsContext);
    const { companyDetails } = React.useContext(CompanyContext);
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const op = React.useRef(null);
    const toast = React.useRef(null);

    const togglePaymentVisible = (success) => {
        if (success) {
            toast.current.show({severity: 'success', summary: 'Added!', detail: 'Payment added successfully!'});
        } else {
            toast.current.show({severity: 'error', summary: 'Error!', detail: 'Unable to add payment. Please try again or contact support.'});
        }
        setPaymentVisible(!paymentVisible);
        flipFetchFlag();
    }

    const selectTicket = (e) => {
        navigate("/tickets/" + e.value.ticketId);
    }

    const removeTicket = (id) => {
        axiosInstance.current.delete("/invoices/" + invoice.invoiceId + "/tickets/" + id).then(() => {
            console.log('done');
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            flipFetchFlag();
        })
    }

    const removeBody = (rowData) => {
        return <Button label="Remove" icon="pi pi-trash" className="p-button-text p-button-danger" onClick={() => removeTicket(rowData.ticketId)} />;
    }

    const flipFetchFlag = () => {
        setFetchFlag(!fetchFlag);
    }

    const reject = () => {
    };

	const confirmDeleteInvoice = (e) => {
		confirmPopup({
			target: e.currentTarget,
			message: "Are you sure you want to delete this invoice?",
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: deleteInvoice,
			reject
		})
	}

    const deleteInvoice = () => {
		axiosInstance.current.delete("/invoices/" + invoice.invoiceId).then((response) => {
			navigate("/invoices");
		})
		.catch((err) => {
			toast.current.show({
				severity: 'error',
				summary: 'Unable to delete invoice!',
				detail: err.response ? err.response.data : ''
			});
		})
	}

    const viewInvoice = () => {
        const templateEnabled = settings.find(s => s.key === 'Invoice_Template')?.enabled;
        if (templateEnabled) {
            axiosInstance.current.get("/invoices/" + id + "/web").then((response) => {
                window.open(response.data);
            })
        } else {
            window.open(window.API_URL + "/api/pdf/invoice/" + id + ".pdf");
        }
    }

    const changeInvoiceStatus = (status) => {
        setLoading(true);
        axiosInstance.current.put("/invoices/" + id + "/" + status).then((response) => {
            toast.current.show({severity: 'success', summary: 'Updated!', detail: 'Invoice updated successfully!'});
        })
        .catch((err) => {
            console.error(err);
            toast.current.show({severity: 'error', summary: 'Error!', detail: 'Unable to update invoice. Please try again or contact support.'});
        })
        .finally(() => {
            flipFetchFlag();
            setLoading(false);
        });
    }

    const downloadInvoice = () => {
        axiosInstance.current.get("/invoices/"+id+"/download", {responseType: 'blob'}).then((response) => {
            // this is the definition of hacky
            const fileUrl = window.URL.createObjectURL(response.data);
            let alink = document.createElement('a');
            alink.href = fileUrl;
            alink.download = 'invoice.pdf';
            alink.click();
        })
        .catch((err) => {
            console.error(err);
        })
    }

    const resendEmail = () => {
        axiosInstance.current.put("/invoices/" + id + "/approve").then((response) => {
            toast.current.show({severity: 'success', summary: 'Sent!', detail: 'Invoice sent to customer!'});
        })
        .catch((err) => {
            toast.current.show({severity: 'error', summary: 'Error!', detail: 'Unable to resend invoice. Please try again or contact support.'});
        })
    }

    const confirmSendEmail = (e) => {
        confirmPopup({
			target: e.currentTarget,
			message: "Are you sure you want to email this invoice?",
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-success',
			accept: resendEmail,
			reject
		})
    }

    const amountDisplay = (rowData) => {
        return <div>{rowData.amount.toFixed(2)}</div>
    }

    React.useEffect(() => {
        setLoading(true);
        axiosInstance.current.get("/invoices/" + id).then((response) => {
            if (response.data) {
                setInvoice(response.data);
                let paymentSubtotal = 0.0;
                response.data.payments.forEach(p => paymentSubtotal += p.amount);
                setPayments(response.data.payments);
                setPaymentTotal(paymentSubtotal);
            }
        })
        .catch((err) => {
            console.error(err);
        })
        .finally(() => {
            setLoading(false);
        });
        axiosInstance.current.get("tickets/invoices/" + id).then((response) => {
            if (response.data) {
                let subtotal = 0.00;
                let tax = 0.00;
                response.data.forEach(t => {
                    t.createTimeDisplay = format(parseISO(t.createTime), "MMMM dd, yyyy h:mm a");
                    if (t.endTime !== null) {
                        t.endTimeDisplay = format(parseISO(t.endTime), "MMMM dd, yyyy h:mm a");
                    }
                    let ticketSubtotal = 0;
                    t.lineItems.forEach(line => {
                        const amount = line.charge.price * line.quantity * (line.discount?.percentage ? 1 - (line.discount?.percentage / 100) : 1) - (line.discount?.amount ? line.discount?.amount : 0);
                        ticketSubtotal += amount;
                        if (!line.charge.exempt) {
                            tax += Math.round((amount * (settings.find(s => s.key === 'Tax')?.value / 100) * 100) + Number.EPSILON) / 100;
                        }
                    })
                    t.subtotal = ticketSubtotal.toFixed(2);
                    subtotal += ticketSubtotal
                });
                setTickets(response.data);
                setSubtotal(subtotal);
                setTax(tax);
                setInvoiceTotal((subtotal + tax).toFixed(2));
            }
        })
        .catch((err) => {
            console.error(err);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFlag]);

    return (
        <>
            <Toast ref={toast} />
            <ConfirmPopup />
            <CreatePayment invoice={invoice} visible={paymentVisible} display={togglePaymentVisible} hide={() => setPaymentVisible(false)} />
            {loading ?
                <ProgressWrapper />
            :
                <div>
                    <nav className="m20">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
                            <li className="breadcrumb-item" onClick={() => navigate("/invoices")}>Invoices</li>
                            <li className="breadcrumb-item active">{invoice.invoiceId.substring(0, 8)}</li>
                        </ol>
                    </nav>
                    <div className="title">{invoice.invoiceId.substring(0, 8)}<span className="ml10" onClick={(e) => op.current.toggle(e)}><FontAwesomeIcon icon={faEllipsisH} /></span></div>
                    <OverlayPanel ref={op} id="options-overlay" className="options-overlaypane">
                        <div className="flex-col start">
                            {invoice.pos ?
                                <>
                                    <Button label="View" className="p-button-text p-button-plain" onClick={viewInvoice} />
                                    {!settings.find(s => s.key === 'Invoice_Template')?.enabled &&
                                    <Button label="Download" className="p-button-text p-button-plain" onClick={downloadInvoice}/>}
                                    <Button label="Delete" className="p-button-text p-button-plain" onClick={confirmDeleteInvoice} />
                                </> 
                            :
                                <>
                                {/* <Button label="Edit" className="p-button-text p-button-plain" onClick={toggleEditVisible}/> */}
                                {companyDetails.subscription !== 'Basic' ?
                                    <Button label="Send Email" className="p-button-text p-button-plain" onClick={confirmSendEmail} />
                                :
                                    <></>
                                }
                                <Button label="View" className="p-button-text p-button-plain" onClick={viewInvoice} />
                                {!settings.find(s => s.key === 'Invoice_Template')?.enabled &&
                                    <Button label="Download" className="p-button-text p-button-plain" onClick={downloadInvoice}/>}
                                {!invoice.sent ?
                                    <Button label="Delete" className="p-button-text p-button-plain" onClick={confirmDeleteInvoice} />
                                :
                                    <></>
                                }
                                </>
                            }
                            
                        </div>
                    </OverlayPanel>
                    <div className="flex-row between">
                        {invoice.pos ?
                            <></>
                        :
                        <>
                            <div className="secondary-title pointer" onClick={() => navigate("/customers/" + invoice.customerId)}>{invoice.customerName} <FontAwesomeIcon icon={faArrowCircleRight} /></div>
                            {invoice.sent ?
                                <div>
                                    {invoice.paid ?
                                        <></>
                                    :
                                        <Button style={{ marginLeft: 5}} className="p-button-outlined" label="Mark as Paid" icon='pi pi-check-square' onClick={() => changeInvoiceStatus("paid")} />
                                    }
                                    <Button style={{ marginLeft: 5 }} className="p-button-outlined p-button-warning" label="Reset Invoice" icon='pi pi-history' onClick={() => changeInvoiceStatus("reset")} />
                                </div>
                            :
                                <>
                                    {tickets.length > 0 ?
                                        <Button className="p-button-outlined" label="Approve Invoice" icon='pi pi-send' onClick={() => changeInvoiceStatus("approve")} />
                                    :
                                        <></>
                                    }
                                </>
                            }
                        </>
                        }
                    </div>
                    <div className="secondary-title">Created on: {format(parseISO(invoice.createTime), "MMMM dd, yyyy h:mm a")}</div>
                    {!invoice.pos && invoice.sent ?
                        <>
                            <div className="secondary-title">Sent on: {format(parseISO(invoice.sentTime), "MMMM dd, yyyy h:mm a")}</div>
                        </>
                    :
                        <></>
                    }
                    {!invoice.pos && invoice.paid ?
                        <div className="secondary-title">Paid on: {format(parseISO(invoice.paidTime), "MMMM dd, yyyy h:mm a")}</div>
                    :
                        <></>
                    }
                    <div className="secondary-title mt30">Total: {settings.find(s => s.key === 'Currency')?.value}{invoiceTotal}</div>
                    <TabView className="tabview-header-icon" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="Invoiced Tickets" leftIcon="pi pi-fw pi-money-bill">
                            <DataTable
                                className="full-width"
                                paginator
                                rows={10}
                                value={tickets}
                                responsiveLayout="stack"
                                size="small"
                                selectionMode="single"
                                onSelectionChange={(e) => selectTicket(e)}
                                dataKey="lineItemId"
                            >
                                <Column field="name" header="Name"></Column>
                                <Column field="description" header="Description"></Column>
                                <Column field="subtotal" header={`Subtotal (${settings.find(s => s.key === 'Currency')?.value})`}></Column>
                                <Column field="assignedFullname" header="Assigned"></Column>
                                <Column field="endTimeDisplay" header="End Time"></Column>
                                {!invoice.sent ?
                                    <Column field="ticketId" body={removeBody}></Column>
                                : 
                                    <></>
                                }
                            </DataTable>
                        </TabPanel>
                        <TabPanel header="Payments" leftIcon="pi pi-fw pi-credit-card">
                            <Button style={{ marginBottom: 10 }} className="p-button-outlined" icon="pi pi-plus" label="Add Payment" onClick={() => setPaymentVisible(true)} />
                            <DataTable
                                className="full-width"
                                paginator
                                rows={10}
                                value={payments}
                                responsiveLayout="stack"
                                size="small"
                                dataKey="paymentId"
                                emptyMessage="No payments"
                            >
                                <Column field="type" header="Type"></Column>
                                <Column body={amountDisplay} header={`Amount (${settings.find(s => s.key === 'Currency')?.value})`}></Column>
                            </DataTable>
                        </TabPanel>
                    </TabView>
                    <div className="flex-row end secondary-title">Subtotal: {settings.find(s => s.key === 'Currency')?.value}{subtotal.toFixed(2)}</div>
				    <div className="flex-row end secondary-title">Tax ({settings.find(s => s.key === 'Tax')?.value}%): {settings.find(s => s.key === 'Currency')?.value}{tax.toFixed(2)}</div>
					<div className="flex-row end secondary-title mt10">Total: {settings.find(s => s.key === 'Currency')?.value}{invoiceTotal}</div>
                    <div className="flex-row end secondary-title">Payments: {settings.find(s => s.key === 'Currency')?.value}{paymentTotal.toFixed(2)}</div>
                    <div className="flex-row end secondary-title">Due: {settings.find(s => s.key === 'Currency')?.value}{(invoiceTotal - paymentTotal).toFixed(2)}</div>
                </div>
            }
        </>
    )
}

export default InvoiceDetail;